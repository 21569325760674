<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Origination'" [help]="true" [op]="'edit'">
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>Enter origination points as a percentage omitting the percent sign (for example, 1.75).</li>
        <li>Enter origination dollar amount as a decimal number.</li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row border-bottom hover-gray pb-1 pt-1">
      <div class="col-lg-6 fw-bold">Origination points</div>
      <div class="col-lg-6">{{item.origination_points ? (+item.origination_points / 100.00 | percent:'1.3-3') : ''}}</div>
    </div>

    <div class="row border-bottom hover-gray pb-1 pt-1">
      <div class="col-lg-6 fw-bold">Origination amount</div>
      <div class="col-lg-6">{{item.origination_amount | currency}}</div>
    </div>
  </mat-card-content>
</mat-card>
