// todo: go over this service
import {Injectable} from '@angular/core';
import {AnalyzerService} from './analyzer.service';

@Injectable()
export class LoanAppStructureService {

  constructor(private analyzerService: AnalyzerService) {
  }

  getSectionName(code) {
    switch (code) {
      case 'loan_information':
        return 'Shared Data';
      case 'borrower_1':
        return 'Borrower';
      case 'borrower_2':
        return 'Co-Borrower';
      case 'borrower_3':
        return 'Borrower 3';
      case 'borrower_4':
        return 'Borrower 4';
      case 'lo':
        return 'Loan Officer';
      default:
        return code;
    }
  }

  getSegmentName(code) {
    switch (code) {
      case 'form_top':
        return 'Borrower Setup';
      case 'parties':
        return 'Title';
      case 'military':
        return 'Military';
      case 'hmda':
        return 'HMDA';
      case 'property_information':
        return 'Property Information';
      case 'loan_purpose':
        return 'Loan Purpose';
      case 'down_payments':
        return 'Down Payments';
      case 'borrower_1':
        return 'Borrower';
      case 'borrower_2':
        return 'Co-Borrower';
      case 'cash_deposit':
        return 'Cash Deposit';
      case 'dependents':
        return 'Dependents';
      case 'addresses':
        return 'Addresses';
      case 'employment':
        return 'Employment';
      case 'secondary_employments':
        return 'Previous Employments';
      case 'incomes':
        return 'Income';
      case 'la_expenses':
        return 'Housing Expenses';
      case 'la_assets':
        return 'Assets';
      case 'la_real_estates':
        return 'Real Estate Owned';
      case 'liabilities':
        return 'Liabilities';
      case 'aliases':
        return 'Aliases';
      case 'supports':
        return 'Expenses';
      case 'declaration':
        return 'Declarations';
      case 'declaration_explanations':
        return 'Explanations';
      case 'information':
        return 'Ethnicity & Sex';
      case 'race_informations':
        return 'Race';
      case 'agreement':
        return 'Agreement';
      case 'envelope_header':
        return 'FNMA ID';
      case 'loan_information':
        return 'Loan Information';
      case 'loan_data':
        return 'Loan data';
      case 'credits':
        return 'Credits';
      case 'details':
        return 'Details';
      case 'product_identification':
        return 'Product Identification';
      case 'product_characteristics':
        return 'Product Characteristics';
      case 'loan_characteristics':
        return 'Loan Characteristics';
      case 'arm_data':
        return 'ARM Data';
      case 'transmittal_data':
        return 'Appraisal';
      case 'lo_information':
        return 'Loan Originator Info';
      case 'origination':
        return 'Origination';
      default:
        return code;
    }
  }

  // used internally
  getSegmentsHash(section, loanApp) {
    const applicantObj = {
      'hmda': {value: 'HMDA', advanced: true, include: true},
      'military': {value: 'Military', advanced: false, include: true},
      'dependents': {value: 'Dependents', advanced: false, include: true},
      'addresses': {value: 'Addresses', advanced: false, include: true},
      'employment': {value: 'Employment', advanced: false, include: true},
      'secondary_employments': {value: 'Previous employments', advanced: false, include: true},
      'incomes': {value: 'Income', advanced: false, include: true},
      'liabilities': {value: 'Liabilities', advanced: false, include: true},
      'supports': {value: 'Other expenses', advanced: false, include: true},
      'aliases': {value: 'Aliases', advanced: false, include: true},
      'declaration': {value: 'Declarations', advanced: false, include: true},
      'declaration_explanations': {value: 'Explanations', advanced: false, include: true},
      'information': {value: 'Ethnicity & sex', advanced: false, include: true},
      'race_informations': {value: 'Race', advanced: false, include: true},
      'agreement': {value: 'Agreement', advanced: true, include: true}
    };

    // todo: HMDA is missing for borrower and co-borrower
    if (section === 'lo') {
      return {
        'envelope_header': {value: 'Envelope data', advanced: false, include: true},
        'loan_information': {value: 'Loan information', advanced: false, include: true},
        'loan_data': {value: 'Loan data', advanced: false, include: (this.analyzerService.isConstructionLoan(loanApp) || this.analyzerService.isRefinanceLoan(loanApp))},
        'credits': {value: 'Credits', advanced: false, include: true},
        'details': {value: 'Details', advanced: false, include: true},
        'product_identification': {value: 'Product identification', advanced: false, include: true},
        'product_characteristics': {value: 'Product characteristics', advanced: false, include: true},
        'loan_characteristics': {value: 'Loan characteristics', advanced: false, include: true},
        'arm_data': {value: 'ARM data', advanced: false, include: this.analyzerService.isARMLoan(loanApp)},
        'transmittal_data': {value: 'Appraisal', advanced: false, include: true},
        'lo_information': {value: 'Loan originator info', advanced: false, include: true},
        'origination': {value: 'Origination', advanced: false, include: true}
      };
    } else if (section === 'loan_information') {
      return {
        'form_top': {value: 'Credit type', advanced: false, include: true},
        'parties': {value: 'Title', advanced: false, include: true},
        'property_information': {value: 'Property information', advanced: false, include: true},
        'loan_purpose': {value: 'Loan purpose', advanced: false, include: true},
        'down_payments': {value: 'Down payments', advanced: false, include: true},
        'la_expenses': {value: 'Housing expenses', advanced: false, include: true},
        'la_assets': {value: 'Assets', advanced: false, include: true},
        'la_real_estates': {value: 'Real estate owned', advanced: false, include: true},
      };
    } else if (section === 'borrower_1') {
      const obj = {'borrower_1': {value: 'Borrower', advanced: false, include: true}};
      return Object.assign(obj, applicantObj);
    } else if (section === 'borrower_2') {
      const obj = {'borrower_2': {value: 'Co-Borrower', advanced: false, include: true}};
      return Object.assign(obj, applicantObj);
    } else if (section === 'borrower_3') {
      const obj = {'borrower_3': {value: 'Borrower 3', advanced: false, include: true}};
      return Object.assign(obj, applicantObj);
    } else if (section === 'borrower_4') {
      const obj = {'borrower_4': {value: 'Borrower 4', advanced: false, include: true}};
      return Object.assign(obj, applicantObj);
    } else if (section === 'general') {
      return {
        'primary_residence': {value: 'Primary residence', advanced: false, include: true},
        'two_year_employment': {value: 'Two year employment', advanced: false, include: true},
      };
    }
  }

  getSegmentsArray(section, loanApp, isLoanProfessional) {
    const hash = this.getSegmentsHash(section, loanApp);
    return Object.keys(hash).reduce((memo, key) => {
      if (hash[key].include === true && (isLoanProfessional || (hash[key].advanced === false))) {
        memo.push(key);
      }
      return memo;
    }, []);
  }
}
