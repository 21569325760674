<div class="p-3">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="false" [header]="'Documents/Images Received'" [help]="true">
      <ul>
        <li>
          The ezUploads uploaded files are displayed below.
        </li>
        <li>
          Please delete the uploaded files after downloading them.
        </li>
        <li>
          The files will be deleted after 72 hours.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>
</div>

<app-box *ngIf="reloaded&&(!anonDocs||anonDocs.length===0)" [type]="'warning'" [close]="false">
  No uploaded files!
</app-box>

<div *ngFor="let anonDoc of anonDocs" class="mb-3">
  <app-view-secured-document [anonDoc]="anonDoc" (eventEmitted)="onEvent($event)"></app-view-secured-document>
</div>
