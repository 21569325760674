<mat-card class="mt-2 mb-1">
  <mat-card-title>Loan Product</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Origination Points</mat-label>
            <input type="text" appDecimalThreeOnly appAutoFocus formControlName="origination_points" matInput placeholder="Origination points">
            <mat-error *ngIf="origination_points.hasError('required')">
              Origination points is required
            </mat-error>
            <mat-hint>Origination points as a percentage omitting the percent sign</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Origination Amount</mat-label>
            <input type="text" appDecimalOnly appAutoFocus formControlName="origination_amount" matInput placeholder="Origination amount">
            <mat-error *ngIf="origination_amount.hasError('required')">
              Origination amount is required
            </mat-error>
            <mat-hint>Origination dollar amount</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
