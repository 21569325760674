<div *ngIf="reloaded">
  <button (click)="back()" class="mb-3 btn btn-secondary" type="button">
    <i class="icon fa fa-chevron-left"></i>&nbsp;Back
  </button>

  <ul class="nav nav-tabs" id="dossier_tabs">
    <li class="nav-item">
      <a (click)="setTabKey($event,tabKeys.general)" [ngClass]="{active:tabKey===tabKeys.general}"
        [attr.aria-selected]="tabKey===tabKeys.general" class="nav-link" data-toggle="tab" id="general_tab">
        Borrower Information
      </a>
    </li>
    <li class="nav-item">
      <a (click)="setTabKey($event,tabKeys.individual)" [ngClass]="{active:tabKey===tabKeys.individual}"
        [attr.aria-selected]="tabKey===tabKeys.individual" class="nav-link" data-toggle="tab" id="individual_tab">
        Individual
      </a>
    </li>
    <li class="nav-item">
      <a (click)="setTabKey($event,tabKeys.partnership)" [ngClass]="{active:tabKey===tabKeys.partnership}"
        [attr.aria-selected]="tabKey===tabKeys.partnership" class="nav-link" data-toggle="tab" id="partnership_tab">
        Partnership Income
      </a>
    </li>
    <li class="nav-item">
      <a (click)="setTabKey($event,tabKeys.s_corp)" [ngClass]="{active:tabKey===tabKeys.s_corp}"
         [attr.aria-selected]="tabKey===tabKeys.s_corp" class="nav-link" data-toggle="tab" id="s_corp_tab">
        S Corporation
      </a>
    </li>
    <li class="nav-item">
      <a (click)="setTabKey($event,tabKeys.c_corp)" [ngClass]="{active:tabKey===tabKeys.c_corp}"
        [attr.aria-selected]="tabKey===tabKeys.c_corp" class="nav-link" data-toggle="tab" id="c_corp_tab">
        C Corporation
      </a>
    </li>
  </ul>

  <div class="tab-content" id="tabs">
    <div [ngClass]="{'show active':tabKey===tabKeys.general}" aria-labelledby="general_tab" class="tab-pane fade" id="general" role="tabpanel">
      <app-view-cfa (eventEmitted)="onEvent($event)" [section]="tabKeys.general" [tran]="tran"></app-view-cfa>
    </div>
    <div [ngClass]="{'show active':tabKey===tabKeys.individual}" aria-labelledby="individual_tab" class="tab-pane fade" id="individual" role="tabpanel">
      <app-view-cfa (eventEmitted)="onEvent($event)" [section]="tabKeys.individual" [tran]="tran"></app-view-cfa>
    </div>
    <div [ngClass]="{'show active':tabKey===tabKeys.partnership}" aria-labelledby="partnership_tab" class="tab-pane fade" id="partnership" role="tabpanel">
      <app-view-cfa (eventEmitted)="onEvent($event)" [section]="tabKeys.partnership" [tran]="tran"></app-view-cfa>
    </div>
    <div [ngClass]="{'show active':tabKey===tabKeys.s_corp}" aria-labelledby="s_corp_tab" class="tab-pane fade" id="s_corp" role="tabpanel">
      <app-view-cfa (eventEmitted)="onEvent($event)" [section]="tabKeys.s_corp" [tran]="tran"></app-view-cfa>
    </div>
    <div [ngClass]="{'show active':tabKey===tabKeys.c_corp}" aria-labelledby="c_corp_tab" class="tab-pane fade" id="c_corp" role="tabpanel">
      <app-view-cfa (eventEmitted)="onEvent($event)" [section]="tabKeys.c_corp" [tran]="tran"></app-view-cfa>
    </div>
  </div>
</div>
