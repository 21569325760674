import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {LoanAppService} from '../services/loan-app.service';
import {Document} from '../models/document';
import {PermissionService} from '../services/permission.service';
import {UserService} from '../services/user.service';
import {TranService} from '../services/tran.service';
import {WorkflowService} from '../services/workflow.service';
import {TraceService} from '../services/trace.service';
import {SharedService} from '../layouts/shared-service';
import {DocumentService} from '../services/document.service';
import {AnalyzerService} from '../services/analyzer.service';
import {Tran} from '../models/tran';
import {ComplianceService} from '../services/compliance.service';
import {DeviceService} from '../services/device.service';
import moment from 'moment';
import {globals} from '../shared/globals/globals';
import {FormUtil} from './form-util';
import {LoanAppStructureService} from '../services/loan-app-structure.service';
import {McrService} from '../services/mcr.service';
import {FormGroup, ValidationErrors} from '@angular/forms';
import {EventService} from '../services/event.service';
import {CacheService} from '../services/cache.service';
import {MAX_LEN} from '../shared/consts';
import $ from 'jquery';

@Component({
  selector: 'app-comp-base',
  template: ''
})
// tslint:disable-next-line:directive-class-suffix
export class CompBaseComponent implements OnInit {
  public static fromDocId: number;
  public static fromFolderId: string;
  public static currentLoanAppSection = globals.section.terms;
  public static isOpen: boolean;
  public MAX_LEN = MAX_LEN;
  public seg = globals.segment; // access to read-only segment names from template
  public isOpen: boolean;
  public injector: Injector;
  @Input() public isTitle = true;
  @Input() public items: Array<any>;
  @Input() public tran: Tran;
  @Input() public applicant: string;
  @Input() public docId: number;
  @Input() public doc: Document;
  @Input() public backLink = '';
  @Output() public eventEmitted = new EventEmitter();
  public isMobile: boolean;
  public canUpdateLoanRecord: boolean;
  public canUpload: boolean;
  public canUpdateDocument: boolean;
  public canUpdateTransaction: boolean;
  public hasNoService: boolean;
  public hasMCRService: boolean;
  public hasBorrowerService: boolean;
  public hasAncillaryService: boolean;
  public hasEzUploadsService: boolean;
  public hasPOLOSService: boolean;
  public canReadDashboard: boolean;
  public canDeleteTransaction: boolean;
  public canCreateTransaction: boolean;
  public canReadLoanApp: boolean;
  public canReadTimeline: boolean;
  public canReadLoanOfficer: boolean;
  public canReadZip: boolean;
  public canCopyDocs: boolean;
  public canGenDocs: boolean;
  public canReadDocs: boolean;
  public canUpdateLoanApps: boolean;
  public loan_app: any;
  public segment: string;
  public section: string;
  public sharedService: SharedService;
  public loanAppService: LoanAppService;
  public loanAppStructureService: LoanAppStructureService;
  public workflowService: WorkflowService;
  public permissionService: PermissionService;
  public analyzerService: AnalyzerService;
  public userService: UserService;
  public tranService: TranService;
  public traceService: TraceService;
  public compService: ComplianceService;
  public docService: DocumentService;
  public mcrService: McrService;
  public eventService: EventService;
  public cacheService: CacheService;
  public mode: string;
  public reloaded: boolean;
  public orientationError: boolean;
  public subscription: any;
  public isDebug: boolean;
  public isAuth: boolean;

  public static getFormValidationErrors(form: FormGroup) {
    const result = [];
    Object.keys(form.controls).forEach(key => {

      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          result.push({
            'control': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });

    return result;
  }

  static getIntArray(beg, count): Array<number> {
    return [...Array(count).keys()].map(x => x + beg);
  }

  constructor(injector: Injector) {
    this.injector = injector;
    if (injector) {
      this.sharedService = this.injector.get(SharedService);
      this.docService = this.injector.get(DocumentService);
      this.loanAppService = this.injector.get(LoanAppService);
      this.permissionService = this.injector.get(PermissionService);
      this.userService = this.injector.get(UserService);
      this.tranService = this.injector.get(TranService);
      this.compService = this.injector.get(ComplianceService);
      this.workflowService = this.injector.get(WorkflowService);
      this.traceService = this.injector.get(TraceService);
      this.analyzerService = this.injector.get(AnalyzerService);
      this.loanAppStructureService = this.injector.get(LoanAppStructureService);
      this.mcrService = this.injector.get(McrService);
      this.eventService = this.injector.get(EventService);
      this.cacheService = this.injector.get(CacheService);
    }
    DeviceService.init();
    const user = this.userService.getUserSync();
    this.isAuth = !!user;
    this.isMobile = DeviceService.isMobile;
    this.hasNoService = user && user.hasNoService();
    this.hasMCRService = user && user.hasMCRService();
    this.hasEzUploadsService = user && user.hasUploadsService();
    this.hasAncillaryService = user && user.hasAncillaryService();
    this.hasBorrowerService = user && user.hasBorrowerService();
    this.hasPOLOSService = user && user.hasPOLOSService();
    this.isDebug = globals.isDebug();
  }

  ngOnInit(loadCurrentLoanApp?: boolean) {
    const loadLoanApp = typeof loadCurrentLoanApp !== 'undefined' ? loadCurrentLoanApp : true;
    this.loadPermissions(loadLoanApp);
  }

  reloadOnSync(): void {
    this.subscription = this.eventService.eventEmitter.subscribe((event) => {
      if (event.type === 'Reload') {
        this['reload'](false);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  loadPermissions(loadLoanApp: boolean): void {
    if (loadLoanApp || !this.doc) {
      const tran = this.tranService.getCurrentTranSync();
      this.doc = tran ? tran.getDefaultLoanApp() : null;
    }
    if (this.doc) {
      this.canUpdateDocument = this.permissionService.execCan('CanUpdateDocument', this.doc);
    }
    this.canReadLoanApp = this.permissionService.execCan('CanReadLoanApp');
    this.canReadTimeline = this.permissionService.execCan('CanReadTimeline');
    this.canDeleteTransaction = this.permissionService.execCan('CanDeleteTransaction');
    this.canReadDashboard = this.permissionService.execCan('CanReadDashboard');
    this.canUpdateTransaction = this.permissionService.execCan('CanUpdateTransaction');
    this.canCreateTransaction = this.permissionService.execCan('CanCreateTransaction');
    this.canReadZip = this.permissionService.execCan('CanReadZip');
    this.canCopyDocs = this.permissionService.execCan('CanCopyDocs');
    this.canGenDocs = this.permissionService.execCan('CanGenDocs');
    this.canReadDocs = this.permissionService.execCan('CanReadDocs');
    this.canUpdateLoanApps = this.permissionService.execCan('CanUpdateLoanApps');
    this.canUpload = this.permissionService.execCan('CanUpload');
    this.canUpdateLoanRecord = this.permissionService.execCan('CanUpdateLoanRecord');
    this.canReadLoanOfficer = this.permissionService.execCan('CanReadLoanSeg', this.doc);
  }

  public setMenuOpen(isOpen: boolean) {
    this.isOpen = CompBaseComponent.isOpen = isOpen;
  }

  empty(): void {
    const data = {section: this.section, segment: this.segment};
    this.loanAppService.emptyLoanAppObj(this.doc.id, data)
      .then(() => {
        const segmentName = this.loanAppStructureService.getSegmentName(this.segment);
        const message = `Data segment: ${segmentName} successfully cleared`;
        FormUtil.genSuccessMessage(this.sharedService, message);
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  onEvent(event: any): void {
    if (event.type === 'Delete') {
      this['delete']();
      return;
    }

    if (event.type === 'ChangeMode') {
      this.mode = event['mode'];
    }

    this.eventEmitted.emit(event);
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }

  edit(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'Edit'});
  }

  getJSTime(date: Date, time: string) {
    if (!date) {
      return null;
    }
    const timePortion = Tran.getTimeString(time);
    const datePortion = moment(date).format('YYYY-MM-DD');
    return moment(`${datePortion} ${timePortion}`).toDate();
  }

  onOrientationError(event: any): void {
    this.orientationError = event.error;
  }

  setFocus(elt) {
    setTimeout(() => {
      if (elt) {
        elt.nativeElement.focus();
      }
    }, 0);
  }

  // structure of the segment object:
  // errorLevel: 0
  // id: 1
  // section: "loan_information"
  // sectionName: "Loan Data"
  // segment: "form_top"
  // segmentName: "Applicants"
  // text: "This is a borrower only loan application (no co-borrower)."
  navigateToSegment(segment: object): void {
    let counter = 0;
    // the purpose of segment parent is to find the root parent of either borrower or co-borrower
    let $segmentParentElt: any;
    if (segment['section'] === 'borrower_1') {
      $segmentParentElt = $($('app-view-applicant')[0]);
    } else if (segment['section'] === 'borrower_2') {
      $segmentParentElt = $($('app-view-applicant')[1]);
    } else if (segment['section'] === 'borrower_3') {
      $segmentParentElt = $($('app-view-applicant')[2]);
    } else if (segment['section'] === 'borrower_4') {
      $segmentParentElt = $($('app-view-applicant')[3]);
    } else {
      $segmentParentElt = null;
    }
    const sectionSearchTerm = 'a.nav-link:contains("' + segment['sectionName'] + '")';
    let segmentSearchTerm = null;
    const section = segment['section'];
    CompBaseComponent.getIntArray(1, 4).some((n) => {
      if (section.endsWith(`borrower_${n}`)) {
        segmentSearchTerm = `#${section}_${segment['segment']}`;
        return true;
      }
    });
    if (!segmentSearchTerm) {
      segmentSearchTerm = `#${segment['segment']}`;
    }

    // click section label in the tab control
    $(sectionSearchTerm)[0].click();

    // click the appropriate segment link (e.g., dependents)
    // loop for 300 milli-seconds at the most to find the right segment
    const timer = setInterval(() => {
      if (counter >= 3) {
        clearInterval(timer);
        return;
      }
      if ($segmentParentElt) {
        const $segment = $segmentParentElt.find(segmentSearchTerm);
        if ($segment.length > 0) {
          $segment[0].click();
          clearInterval(timer);
          return;
        }
      } else {
        const $segment = $(segmentSearchTerm);
        if ($segment.length > 0) {
          clearInterval(timer);
          $segment[0].click();
        }
      }
      counter++;
    }, 100);
  }
}
