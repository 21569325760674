import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {HomeComponent} from '../home.component';
import {Help} from '../../../models/help';
import {ProfileService} from "../../../services/profile.service";
import {User} from "../../../models/user";
import {IProfileStatusReport} from "../../../models/profile-status-report";
import {Organization} from "../../../models/organization";
import {Router} from "@angular/router";

@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
})
export class HighlightsComponent extends HomeComponent implements OnInit, OnDestroy {
  profileStatus: IProfileStatusReport;
  user: User;
  userOrganization: Organization;
  currentLoanUrl: string;

  constructor(public injector: Injector, public profileService: ProfileService, public router: Router) {
    super(injector);
  }

  ngOnInit() {
    this.pageTitle = 'Account Highlights';
    super.ngOnInit();
    this.reload();
    this.reloadOnSync();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.reloadHome()
      .then(() => {
        return this.userService.getDefaultOrganization(false);
      })
      .then((userOrganization: Organization) => {
        this.userOrganization = userOrganization;
        this.user = this.userService.getUserSync();
        this.profileStatus = this.profileService.getProfileStatus(this.user, this.userOrganization);
        this.sharedService.emitChange({
          type: 'ChangePage',
          title: this.pageTitle,
          help: Help.helpKeys.homeHighlights
        });
        this.currentLoanUrl = this.canReadLoanApp ? '/loan/view-loan-app' : '/loan/view-timeline';
        this.reloaded = true;
      })
  }

  navigateToCurrentLoan(event: any): void {
    event.preventDefault();
    this.router.navigate([this.currentLoanUrl]);
  }
}
