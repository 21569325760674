import {ModelBase} from './model-base';
import {Contact} from './contact';
import {Document} from './document';
import {Permission} from './permission';
import {Workflow} from './workflow';
import {Folder} from './folder';
import {ProfessionalProfile} from './professional-profile';

export class Tran extends ModelBase {
  public static STATUS = {
    ACTIVE: 0,
    INACTIVE: 1,
    COMPLETED: 2
  };
  public static TTYPE = {
    NONE: 0,
    LOAN_OFFICER: 1,
    BORROWER: 2
  };
  public static statuses: Array<any> = [
    {key: Tran.STATUS.ACTIVE, value: 'Active'},
    {key: Tran.STATUS.INACTIVE, value: 'Inactive'},
    {key: Tran.STATUS.COMPLETED, value: 'Completed'},
  ];

  // the next four properties are all we get from getTrans()
  id: number;
  index: number;
  ttype: number;
  name: string;
  description: string;
  status: number;
  owner_id: number;
  organization_id: number;
  organization_name: string;
  owner_full_name: string;
  owner_thumbnail_path: string;
  owner_professional_profile: any;
  gcns: Array<any>;
  workflow: Workflow;
  permissions: Array<Permission>;
  contacts: Array<Contact>;
  documents: Array<Document>;
  timeline: any;
  slide_number: number;
  user_permission: any;
  created_at: Date;
  updated_at: Date;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'created_at' || prop === 'updated_at') {
        this[prop] = this.toJSDate(object[prop]);
      } else if (prop === 'contacts') {
        this[prop] = Contact.deserializeArray(object[prop]);
      } else if (prop === 'documents') {
        this[prop] = Document.deserializeArray(object[prop]);
      } else if (prop === 'permissions') {
        this[prop] = Permission.deserializeArray(object[prop]);
      } else if (prop === 'workflow' && !ModelBase.isEmpty(object[prop])) {
        this[prop] = new Workflow(object[prop]);
      } else if (prop === 'owner_professional_profile' && !ModelBase.isEmpty(object[prop])) {
        this[prop] = new ProfessionalProfile(object[prop]);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Tran> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new Tran(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  getStatusString(): string {
    const eltFound = Tran.statuses.find((elt) => elt.key === this.status);
    return eltFound ? eltFound.value : 'N/A';
  }

  getSelectedFolders(): Array<Folder> {
    if (!this.gcns) {
      return [];
    }
    return this.gcns.reduce((mem, elt) => {
      elt.items.forEach((item) => {
        if (item.selected) {
          item.groupName = elt.name;
          item.groupId = elt.id;
          mem.push(new Folder(item));
        }
      });
      return mem;
    }, []);
  }

  getBorrowerDocuments(): Array<Document> {
    return this.documents.filter(elt => elt.isPDF() || elt.isImage());
  }

  isActive(): boolean {
    return this.status === Tran.STATUS.ACTIVE;
  }

  isTerminated(): boolean {
    return this.status === Tran.STATUS.COMPLETED;
  }

  isSandboxTran(): boolean {
    return this.ttype === Tran.TTYPE.BORROWER;
  }

  isLoanOfficerTran(): boolean {
    return this.ttype === Tran.TTYPE.LOAN_OFFICER;
  }

  getLoanApps(): Array<any> {
    const loanApps = this.documents.filter((elt) => elt.dtype === 64);
    return loanApps && loanApps.length ? loanApps : null;
  }

  getDefaultLoanApp(): Document {
    const loanApps = this.documents.filter((elt) => elt.dtype === 64);
    return (loanApps && loanApps.length) ? loanApps.find((elt) => elt.category === 0) : null;
  }
}
