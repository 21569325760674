<mat-card class="mt-2 mb-1">
  <mat-card-title>Agreement &amp; Signature</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Signature date</mat-label>
            <input [matDatepicker]="$any(datePicker)" [max]="maxDate" [min]="minDate" formControlName="signature_date" matInput placeholder="Signature date">
            <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-error *ngIf="signature_date.hasError('matDatepickerMin') || signature_date.hasError('matDatepickerMax')">
              Signature date is unrealistic
            </mat-error>
            <mat-error *ngIf="signature_date.hasError('matDatepickerParse')">
              Signature date is invalid
            </mat-error>
            <mat-error *ngIf="signature_date.hasError('required')">
              Signature date is required
            </mat-error>
            <mat-hint>
              The date the borrower signs the loan application
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
      <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
      <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
    </form>
  </mat-card-content>
</mat-card>
