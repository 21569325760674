import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormTop} from '../../../../models/form-top';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-form-top',
  templateUrl: './view-segment-form-top.component.html',
})
export class ViewSegmentFormTopComponent extends CompBaseComponent implements OnInit {
  @Input() item: FormTop;

  constructor(public injector: Injector) {
    super(injector);
    this.segment = 'form_top';
    this.section = 'loan_information';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getGroupingSummary(): string {
    const count = this.item.borrower_groups.length;
    switch (count) {
      case 1:
        if (+this.item.borrower_count === 1) {
          return 'N/A (single borrower)'
        }
        return 'One group'
      case 2:
        return 'Two groups'
      case 3:
        return 'Three groups'
      case 4:
        return 'Four groups'
    }
  }
}
