import {Component, Injector, Input, OnInit} from '@angular/core';
import {Document} from '../../../models/document';
import {CompBaseComponent} from '../../comp-base.component';
import {FormUtil} from "../../form-util";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {Router} from "@angular/router";

@Component({
  selector: 'app-view-loan-app-record',
  templateUrl: './view-loan-app-record.component.html',
})
export class ViewLoanAppRecordComponent extends CompBaseComponent implements OnInit {
  @Input() loanApp: Document;
  public downloading: boolean;
  public urlSafe: SafeResourceUrl;
  private url: string;

  constructor(public injector: Injector,
              private router: Router,
              public sanitizer: DomSanitizer) {
    super(injector);
    this.downloading = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.url = `api/documents/export_mismo/${this.loanApp.id}`;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

  }

  edit(): void {
    if (this.canUpdateTransaction) {
      this.eventEmitted.emit({
        type: 'ChangeMode',
        mode: 'Edit'
      });
    }
  }

  navigateToLoanApp(): void {
    this.router.navigate(['/loan/view-loan-app']);
  }

  upload(): void {
    this.mode = 'Upload';
  }

  export(): void {
    this.downloading = false;
    this.downloading = true;
    setTimeout(() => {
      this.onUploadDownloadComplete();
    }, 1000);
  }

  duplicate(): void {
    this.docService.copyDocument(this.loanApp.id)
      .then((doc) => {
        FormUtil.genSuccessMessage(this.sharedService, 'Loan application successfully duplicated');
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to duplicate loan application', data);
        this.eventEmitted.emit({
          type: 'ChangeMode',
          mode: 'View'
        });
      });
  }

  onUploadDownloadComplete(): void {
    this.tranService.getCurrentTran(true)
      .then(() => {
        this.mode = 'View';
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'tran'});
        this.eventEmitted.emit({type: 'Reload'});
      });
  }
}
