<app-box *ngIf="user.isLoanOfficer()" [type]="'info'" [close]="true">
  This is your branding profile, which is visible to any user with access to your loan.
  Empty fields do not display!
  You can update your branding at
  <a class="pr-blue-bold" routerLink="/account/branding">Account/Branding</a>
  and upload your photo at
  <a class="pr-blue-bold" routerLink="/account/settings">Account/Settings</a>.
</app-box>

<mat-card *ngIf="reloaded&&profile" [ngClass]="{'w-75':!isMobile}">
  <mat-card-title>Loan Owner's Info</mat-card-title>
  <mat-card-content>
    <div class="row pt-2 pb-2 border-bottom hover-gray" *ngIf="profile.full_name">
      <div class="col-lg-2 fw-bold">
        Name
      </div>
      <div class="col-lg-10">
        {{profile.full_name}}
      </div>
    </div>

    <div class="row pt-2 pb-2 border-bottom hover-gray" *ngIf="profile.company">
      <div class="col-lg-2 fw-bold">
        Company
      </div>
      <div class="col-lg-10">
        {{profile.company}}
      </div>
    </div>

    <div class="row pt-2 pb-2 border-bottom hover-gray" *ngIf="profile.office">
      <div class="col-lg-2 fw-bold">
        Office
      </div>
      <div class="col-lg-10">
        {{profile.office}}
      </div>
    </div>

    <div class="row pt-2 pb-2 border-bottom hover-gray" *ngIf="profile.phone">
      <div class="col-lg-2 fw-bold">
        Phone
      </div>
      <div class="col-lg-10">
        {{profile.phone | phone}} {{profile.phone_ex}}
      </div>
    </div>

    <div class="row pt-2 pb-2 border-bottom hover-gray" *ngIf="profile.company_url">
      <div class="col-lg-2 fw-bold">
        Company URL
      </div>
      <div class="col-lg-10">
        <a class="pr-blue-bold" href="{{profile.company_url}}" target="_blank" rel="noopener">{{profile.company_url}}</a>
      </div>
    </div>

    <div class="row pt-2 pb-2 border-bottom hover-gray" *ngIf="profile.about_me || profile.personal_url">
      <div class="col-lg-12 fw-bold mb-2">
        About {{profile.full_name}}
      </div>
      <div class="col-lg-12">
        <img *ngIf="profile.is_image" [src]="profile.image_url" alt="Loan Officer Image" class="float-left me-5"
          style="max-height: 128px;">
        <div class="ms-4" [innerHTML]="profile.about_me | nl2br"></div>

        <div class="mt-2" [hidden]="!profile.personal_url">
          <span class="fw-bold">Personal Page:</span>&nbsp;
          <a class="pr-blue-bold" href="{{profile.personal_url}}" target="_blank" rel="noopener">{{profile.personal_url}}</a>
        </div>

        <div class="mt-2" [hidden]="!profile.hobbies">
          <span class="fw-bold">My Hobbies:</span>&nbsp;<span [innerHTML]="profile.hobbies | nl2br"></span>
        </div>
      </div>
    </div>

    <div class="row pt-2 pb-2 border-bottom hover-gray" *ngIf="profile.about_my_company">
      <div class="col-lg-12 fw-bold">
        About {{profile.company}}
      </div>
      <div class="col-lg-12" [innerHTML]="profile.about_my_company | nl2br">
      </div>
    </div>
  </mat-card-content>
</mat-card>

<app-box *ngIf="!profile" [close]="false" [type]="'warning'" class="pt-2">
  <b>No loan selected!</b>
  Please select a loan from <a class="pr-blue-bold" routerLink="/loans/list">My Loans</a>.
  <span *ngIf="user.isBorrower()">
  If you don't see your loan in <a class="pr-blue-bold" routerLink="/loans/list">My Loans</a>, contact your loan officer to request access to it.
  </span>
</app-box>
