import {ModelBase} from './model-base';
import {globals} from '../shared/globals/globals';

export class CreditReport extends ModelBase {
  public static credit_parties = [
    {key: 'borrower_1', value: 'Borrower'},
    {key: 'borrower_2', value: 'Co-Borrower'},
    {key: 'borrower_1,borrower_2', value: 'Borrower1,Borrower2'},
    {key: 'borrower_2,borrower_3', value: 'Borrower2,Borrower3'},
    {key: 'borrower_3', value: 'Borrower 3'},
    {key: 'borrower_4', value: 'Borrower 4'},
  ];

  public static credit_report_types = [
    {key: 'new', value: 'New Credit Report'},
    {key: 'refresh', value: 'Refresh Credit Report'},
  ];

  // additional agreement with Credit Plus need to be signed before using the interface
  public static credit_providers = [
    {value: 'Advantage Credit', key: 'BE'},
    {value: 'Advantage Credit Bureau', key: 'F9'},
    {value: 'Advantage Plus Credit', key: 'BA'},
    {value: 'American Reporting Compan', key: 'CE'},
    {value: 'Birchwood Credit Service', key: 'B0'},
    {value: 'CBFS Business Solutions', key: 'CP'},
    {value: 'Certified Credit Reporting', key: 'B2'},
    {value: 'CIC Credit', key: 'CL'},
    {value: 'CIS Credit Solutions', key: 'BP'},
    {value: 'CISCO Credit', key: 'B1'},
    {value: 'Credit Bureau Services', key: 'A1'},
    {value: 'Service 1st', key: 'A9'},
    {value: 'Credit Link', key: 'AW'},
    {value: 'Credit Plus', key: 'AV'},
    {value: 'Credit Technologies', key: 'AR'},
    {value: 'Credit Technology', key: 'AS'},
    {value: 'ISC', key: 'BW'},
    {value: 'KCB Credit', key: 'AJ'},
    {value: 'Merchants Credit Bureau', key: 'BL'},
    {value: 'Merchants Credit Bureau (Savannah)', key: 'BV'},
    {value: 'MeridianLink, Inc.', key: 'A4'},
    {value: 'Premium Credit Bureau', key: 'AE'},
    {value: 'Premium Credit Bureau Data', key: 'F3'},
    {value: 'Sarma', key: 'F6'},
    {value: 'Data Facts', key: 'B6'},
    {value: 'Settlementone Credit Corp.', key: 'D2'},
    {value: 'TheCreditBureau.com', key: 'AH'},
    {value: 'United One Resources', key: 'A7'},
    {value: 'Universal Credit Services', key: 'CY'}
  ];
  public static demo_credit_providers = [
    {value: 'PrudentCO Demo', key: 'DM'}
  ];

  constructor() {
    super();
  }

  static getCreditProviders() {
    if (globals.getEnv() === 'production') {
      return CreditReport.credit_providers;
    }
    return CreditReport.demo_credit_providers;
  }

  static creditProviderName(key): string {
    const provider = CreditReport.getCreditProviders().find((elt) => {
      return elt.key === key;
    });

    return provider ? provider.value : null;
  }
}
