<div
  [ngClass]="{
    'success-card': bgColor === 'success',
    'info-card': bgColor === 'info',
    'warning-card': bgColor === 'warning',
    'danger-card': bgColor === 'danger',
    'success-color-card': color === 'success',
    'info-color-card': color === 'info',
    'warning-color-card': color === 'warning',
    'danger-color-card': color === 'danger',
    'bg-image-card': bgImage,
    'outline-card': outline
  }"
  [ngStyle]="{
   'background-color': customBgColor,
   'background-image': 'url(' + bgImage + ')',
   'border-color': customBgColor,
   'color': customColor
  }"
  class="card-wrap"
>
  <h3
    *ngIf="title"
    [ngClass]="{
      'text-end': alignTitle === 'right',
      'text-center': alignTitle === 'center'
    }"
    class="card-header h5"
  >
    <span class="d-inline" *ngIf="logo">
      <img alt="POLOS Logo" class="hand-pointer doc-image" src="assets/img/polo.png">&nbsp;&nbsp;
    </span>

    {{title}}
  </h3>

  <div
    [ngClass]="{
      'text-end': align === 'right',
      'text-center': align === 'center'
    }"
    [ngStyle]="{'padding': indents}"
    class="card-content"
  >
    <ng-content></ng-content>
  </div>
</div>
