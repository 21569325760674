<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <app-view-sticky-note (eventEmitted)="onEvent($event)" [section]="section" [segment]="segment"></app-view-sticky-note>

    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="isMobile ? 'Expenses' : 'Alimony, Child Support / Separate Maintenance, Job-Related Expenses'" [help]="true" [op]="'add'">
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>Each entry in this segment reflects an expense paid to support other people, job-related expense, or another expense type.</li>
          <li>You can edit or delete entries.</li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <app-add-support (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'&&canUpdateDocument" [applicant]="applicant" [doc]="doc"></app-add-support>

    <div *ngFor="let item of items">
      <app-view-support (eventEmitted)="onEvent($event)" [applicant]="applicant" [item]="item"></app-view-support>
    </div>
  </mat-card>
</div>
