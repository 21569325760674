<div *ngIf="tab == 'tab1'">
  <h5>Shared Data</h5>

  <p>
    The Shared Data tab provides general information about the loan and the assets owned by the borrowers.
    Please note that POLOS supports up to four borrowers.
  </p>

  <ul class="help">
    <li>
      <b>IMPORTANT</b>:
      Please provide the information for <b>"Borrower Setup"</b> and <b>"Loan Purpose"</b> before proceeding.
      This will ensure that the user is presented only with the relevant data entry forms.
    </li>
    <li>
      The tabs for Co-Borrower, Borrower 3, and Borrower 4 will only be displayed if there are multiple borrowers.
    </li>
  </ul>
</div>

<div *ngIf="tab == 'tab2'">
  <h5>Borrower/Co-Borrower</h5>
  <p>
    The borrower tab is where you provide details specific to the borrower.
  </p>

  <ul>
    <li>
      Your best strategy is to move from top to bottom.
    </li>
    <li>
      In most cases, the loan officer populates the borrower's liabilities from the borrower's credit report.
      Errors and/or omissions in the credit report are handled by the loan officer.
    </li>
    <li>
      Provide honest answers to Declarations.
    </li>
  </ul>
</div>

<div *ngIf="tab == 'tab3'">
  <h5>Loan Officer</h5>
  <p>
    In this tab, you enter loan-specific data.
    If you proceeded from left to right and top to bottom,
    you would be presented only with relevant fields and data segments.
    For example, ARM-related data will not display for fixed-rate loans.
  </p>

  <ul>
    <li>
      The list of data segments under the Loan Officer tab is complete. Irrelevant fields are hidden.
    </li>
    <li>
      Fannie Mae Institution ID is applicable only if you submit loans directly to Fannie Mae.
      Leave this data segment empty, if it is not applicable to you.
    </li>
  </ul>

</div>

<div *ngIf="tab == 'tab4'">
  <h5>Error Checking</h5>
  <p>
    The error-checking facility produces error, warning, and information messages.
    If you click the segment column of any entry, it will take you directly to the applicable data segment.
  </p>
  <ul>
    <li>
      Errors displayed in red are show-stoppers. Make sure to correct these errors before submitting your loan to the lender.
    </li>
    <li>
      <span class="fw-bold">Finalize Loan</span>.
      Run Finalize Loan when you are completely done with the Loan Application.
      The purpose of this function is to prune irrelevant data segments.
      For instance, you may have initially assumed that there was a co-borrower and provided data for them.
      If later on, the loan was to be submitted without a co-borrower,
      then their data will be removed forever after finalizing the loan.
    </li>
    <li>
      <span class="fw-bold">Financial Ratios</span>.
      The Financial Ratios functions compute important ratios such as debt-to-income.
      The data is drawn from the loan application. But, you can also run what-if scenarios with your own data.
      Running this function does not affect the loan application.
  </ul>
</div>
