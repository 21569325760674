import {Component, Injector, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import moment from 'moment';
import {globals} from '../../../../../shared/globals/globals';
import {CFABaseComponent} from "../cfa-base.component";
import {FormUtil} from "../../../../form-util";

@Component({
  selector: 'app-edit-segment-cfa',
  templateUrl: './edit-segment-cfa.component.html',
})
export class EditSegmentCFAComponent extends CFABaseComponent implements OnInit {
  public txSummary: any;
  public minDate = globals.date.minTranStartDate;
  public maxDate = globals.date.maxTranEndDate;
  public inputArr: Array<any>;
  public form: FormGroup;
  ccArray: FormArray;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(): void {
    this.reloaded = false;
    this.createFormControls();
    this.createForm();
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  createFormControls(): void {
    this.ccArray = new FormArray<any>([]);
    this.sections.forEach((section) => {
      this.savedDoc[section] ||= [];
    })
    this.inputArr = this.amendObjectProperties(this.savedDoc[this.section], this.cfaStock[this.section]);
    let not_initialized = [null,""];
    let yrs_fields = [ "year1", "year2", "c_corp_year1", "c_corp_year2", "partner_year1", "partner_year2", "s_corp_year1", "s_corp_year2" ];
    let pct_fields = [ "partnership_percentage_year1", "partnership_percentage_year2", "s_corp_percentage_year1", "s_corp_percentage_year2" ];
    let currentDate = new Date();
    for (let i = 0; i < this.inputArr.length; i++) {
      let val = this.inputArr[i]['value']
      if (yrs_fields.includes(this.inputArr[i]["key"]) && not_initialized.includes(val)) {
        switch (this.inputArr[i]["key"]) {
          case "year1":
          case "c_corp_year1":
          case "partner_year1":
          case "s_corp_year1":
            val = (currentDate.getFullYear() - 2).toString();
            break;
          case "year2":
          case "c_corp_year2":
          case "partner_year2":
          case "s_corp_year2":
            val = (currentDate.getFullYear() - 1).toString();
            break;
        }
      }
      else if (pct_fields.includes(this.inputArr[i]["key"]) && not_initialized.includes(val)) {
        val = 100;
      }
      this.ccArray.push(new FormControl(val));
    }
  }

  createForm(): void {
    this.form = new FormGroup({
      ccArray: this.ccArray
    });
  }

  amendObjectProperties(target: Array<any>, source: Array<any>): Array<any> {
    if (!target) {
      target = source;
      return target;
    }
    source.forEach((elt) => {
      const found = target.find(elt2 => elt2 && (elt2.key === elt.key));
      if (!found) {
        target.push(elt);
      }
    })
    return target;
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }

  async genCFA(): Promise<true> {
    if (!this.form.valid) {
      return;
    }
    const formData = this.savedDoc;
    this.ccArray.controls.forEach((ctl, index: number) => {
      if (this.inputArr[index]["type"] === 'date') {
        this.inputArr[index]['value'] = ctl.value ? moment(ctl.value).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY');
      } else {
        this.inputArr[index]['value'] = ctl.value;
      }
    })
    try {
      await this.saveForm(formData);
      this.eventService.eventEmitter.emit({type: 'Reload'});
      //FormUtil.genSuccessMessage(this.sharedService, `Cash Flow Analysis ${JSON.stringify(formData)} successfully saved`);
      FormUtil.genSuccessMessage(this.sharedService, `Cash Flow Analysis successfully saved`);
      return Promise.resolve(true);
    } catch (data) {
      FormUtil.genErrorMessage(this.sharedService, 'Failed to save form', data);
      return Promise.reject(false);
    }
  }
}
