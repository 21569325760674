import {Component, Injector, OnInit} from '@angular/core';
import {globals} from "../../../../../shared/globals/globals";
import {FormControl, FormGroup} from "@angular/forms";
import {CreditReportComponent} from "../credit-report.component";

@Component({
  selector: 'app-upload-credit-report',
  templateUrl: './upload-credit-report.component.html',
})
export class UploadCreditReportComponent extends CreditReportComponent implements OnInit {
  public actionUrl: string;
  public form: FormGroup;
  public applicants: Array<any>;
  public who: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload();
      return;
    }
    if (event.type === 'ChangeMode') {
      this.mode = event.mode;
      return;
    }
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.mode = 'Upload';
    const count = this.analyzerService.borrowerCount(this.doc.recursive_attributes);
    this.applicants = this.get_credit_parties();
    this.createFormControls();
    this.createForm();
    this.tran = this.tranService.getCurrentTranSync();
  }

  createFormControls(): void {
    this.who = new FormControl();
  }

  createForm(): void {
    this.form = new FormGroup({
      who: this.who,
    });
  }

  onUploadComplete(): void {
    this.userService.refreshUser()
      .then(() => {
        return this.tranService.getCurrentTran(true);
      }).then(() => {
        this.navigateToSegment({
          section: globals.section.terms,
          segment: globals.segment.creditReports,
          sectionName: 'Tools'
        });
      }
    );
  }

  getActionUrl(): string {
    return `credit_reports/credit_file_upload?tran_id=${this.tran.id}&section=${this.who.value}`;
  }

  onError(event: any): void {
    this.sharedService.emitChange(event);
  }
}
