<mat-card class="mt-2 mb-1">
  <mat-card-title>Loan Characteristics</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Lien type</mat-label>
            <mat-select formControlName="lien_type_code_ex" placeholder="Select lien type" required>
              <mat-option *ngFor="let lien_type_code of lien_type_codes_ex" [value]="lien_type_code.key">{{lien_type_code.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="lien_type_code_ex.hasError('required')">
              Lien type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row hover-gray mb-3">
        <div class="col-lg-4 my-auto">
          Is this a balloon loan?
        </div>
        <div class="col-lg-8">
          <mat-radio-group aria-label="Balloon indicator" formControlName="balloon_indicator">
            <mat-radio-button [checked]="balloon_indicator.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="balloon_indicator.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div *ngIf="balloon_indicator.value===true">
        <div class="row mb-1">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Balloon payment amount</mat-label>
              <input appNumberOnly formControlName="balloon_payment_amount" matInput placeholder="Balloon payment amount" type="text" maxlength="{{MAX_LEN.CURR}}">
              <mat-error *ngIf="balloon_payment_amount.hasError('required')">
                Balloon payment amount is required
              </mat-error>
              <mat-error *ngIf="balloon_payment_amount.hasError('invalid')">
                Balloon payment amount is invalid
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Maturity type (balloon only)</mat-label>
              <mat-select formControlName="maturity_type" placeholder="Select maturity type (balloon only)">
                <mat-option *ngFor="let maturity_type of maturity_types" [value]="maturity_type.key">{{maturity_type.value}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="maturity_type.hasError('required')">
                Maturity type is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Maturity period count (balloon only)</mat-label>
              <input appNumberOnly formControlName="maturity_periods_no" matInput placeholder="Maturity period count (balloon only)" type="text" maxlength="3">
              <mat-error *ngIf="maturity_periods_no.hasError('required')">
                Maturity period count is required
              </mat-error>
              <mat-error *ngIf="maturity_periods_no.hasError('invalid')">
                Maturity period count is invalid
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row hover-gray mb-3">
        <div class="col-lg-4 my-auto">
          Is homebuyer education completed?
        </div>
        <div class="col-lg-8">
          <mat-radio-group aria-label="Homebuyer education completion indicator" formControlName="homebuyer_education_completion_indicator">
            <mat-radio-button [checked]="homebuyer_education_completion_indicator.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="homebuyer_education_completion_indicator.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <!-- show maximum_lifetime_rate_increase only for non-fixed rate loans -->
      <div class="row mb-5" [hidden]="isFixedRateLoan">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Maximum life rate increase</mat-label>
            <input appDecimalThreeOnly formControlName="maximum_lifetime_rate_increase" matInput placeholder="Maximum life rate increase" type="text" maxlength="7">
            <mat-error *ngIf="maximum_lifetime_rate_increase.hasError('required')">
              Maximum life rate increase is required
            </mat-error>
            <mat-error *ngIf="maximum_lifetime_rate_increase.hasError('pattern')">
              Maximum life rate increase is invalid
            </mat-error>
            <mat-hint>Applicable to loans where the rate could fluctuate over time (e.g., ARM)</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>MI coverage percent</mat-label>
            <input appDecimalThreeOnly formControlName="mi_coverage_percent" matInput placeholder="MI coverage percent" type="text" maxlength="7">
            <mat-error *ngIf="mi_coverage_percent.hasError('required')">
              MI coverage percent is required
            </mat-error>
            <mat-error *ngIf="mi_coverage_percent.hasError('pattern')">
              MI coverage percent is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>APR spread (percent)</mat-label>
            <input appDecimalOnly formControlName="apr_spread" matInput placeholder="APR spread (percent)" type="text" maxlength="5">
            <mat-error *ngIf="apr_spread.hasError('required')">
              APR spread is required
            </mat-error>
            <mat-error *ngIf="apr_spread.hasError('invalid')">
              APR spread is invalid
            </mat-error>
            <mat-hint>
              Percent: xx.xx
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row hover-gray mb-3">
        <div class="col-lg-4 my-auto">
          Is this loan covered by HOEPA?
        </div>
        <div class="col-lg-8">
          <mat-radio-group aria-label="HOEPA indicator" formControlName="hoepa_indicator">
            <mat-radio-button [checked]="hoepa_indicator.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="hoepa_indicator.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row hover-gray mb-3">
        <div class="col-lg-4 my-auto">
          Is this a reverse loan?
        </div>
        <div class="col-lg-8">
          <mat-radio-group aria-label="Reverse mortgage indicator" formControlName="is_reverse">
            <mat-radio-button [checked]="is_reverse.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="is_reverse.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>QM type</mat-label>
            <mat-select formControlName="qm_type" placeholder="Select QM type" required>
              <mat-option *ngFor="let code of qm_types" [value]="code.key">{{code.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="qm_type.hasError('required')">
              QM type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
