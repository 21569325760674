import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {CustomerComponent} from "../customer/customer.component";

@Component({
  selector: 'app-add-customer',
  templateUrl: '../customer/edit-segment-customer.component.html',
})
export class AddCustomerComponent extends CustomerComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
