import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {globals} from "../../../../../shared/globals/globals";
import {Help} from "../../../../../models/help";
import {CFABaseComponent} from "../cfa-base.component";

@Component({
  selector: 'app-view-cfa-tabs',
  templateUrl: './view-cfa-tabs.component.html',
})
export class ViewCFATabsComponent extends CFABaseComponent implements OnInit, OnDestroy {
  public pageTitle: string = 'Cash Flow Analysis / Form 1084';
  public tabKey = globals.section.general;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.directory
      });
    }, 0);
    this.reload();
    this.reloadOnSync();
  }

  reload(): void {
    this.reloaded = false;
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  setTabKey(event: any, tabKey: string): void {
    if (event) {
      event.preventDefault();
    }
    this.tabKey = tabKey;
  }

  async back(): Promise<boolean> {
    await this.router.navigate(['loan/view-directory']);
    return Promise.resolve(true);
  }
}
