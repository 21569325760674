<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <app-view-sticky-note (eventEmitted)="onEvent($event)" [section]="section" [segment]="segment"></app-view-sticky-note>

    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Monthly Housing Expenses'" [help]="true" [op]="'add'">
        <div class="fw-bold">Housing expenses:</div>
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>All fields refer to
            <span class="fw-bold">monthly</span> expenses.
          </li>
          <li>All fields are required.</li>
          <li>You can edit or delete expenses.</li>
        </ul>

        <div class="fw-bold">For purchase loan add entries for:</div>
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>Proposed homeowners insurance.</li>
          <li>Proposed real estate tax.</li>
          <li>Mortgage insurance, if applicable.</li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <app-add-expense (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'&&canUpdateDocument" [doc]="doc"></app-add-expense>

    <div *ngFor="let item of items">
      <app-view-expense (eventEmitted)="onEvent($event)" [item]="item"></app-view-expense>
    </div>
  </mat-card>
</div>
