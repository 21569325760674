import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormTop} from "../../../../models/form-top";

@Component({
  selector: 'app-credit-report',
  template: '',
})
export class CreditReportComponent extends CompBaseComponent implements OnInit {
  public borrowerGroups: Array<Array<string>>;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit()
    const formTop: FormTop = new FormTop(this.doc.recursive_attributes['form_top']);
    this.borrowerGroups = formTop.borrower_groups;
  }

  public get_credit_parties(): Array<any> {
    let result = [];
    this.borrowerGroups.forEach(group => {
      const groupAsBorrowers = group.map(elt => {
        switch(elt) {
          case 'b1':
            return 'Borrower';
          case 'b2':
            return 'Co-Borrower';
          case 'b3':
            return 'Borrower 3';
          case 'b4':
            return 'Borrower 4';
        }
      }).join(', ');
      const groupAsString = group.map(elt => elt.replace('b', 'borrower_')).join(',');
      result.push({key: groupAsString, value: groupAsBorrowers});
    })
    return result;
  }

  public getCreditPreparedFor(section: string) {
    const found = this.get_credit_parties().find((elt) => {
      return elt.key === section;
    });
    return found || {
      key: 'N/A',
      value: 'N/A'
    };
  }
}
