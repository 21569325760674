<mat-card>
  <app-segment-header [header]="'Update Properties'" [help]="false" [isCard]="false" class="mb-3"></app-segment-header>

  <mat-card-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Loan application name</mat-label>
            <input formControlName="name" matInput placeholder="Loan application name" maxlength="{{MAX_LEN.GEN_NAME}}">
            <mat-error *ngIf="name.hasError('required')">
              Name is required
            </mat-error>
            <mat-error *ngIf="name.hasError('minlength')">
              Name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div>
        <mat-checkbox formControlName="is_default">&nbsp;Default loan application</mat-checkbox>
        <mat-hint class="pr-checkbox-hint">
          <br/>The default loan application is used for all loan and document operations
        </mat-hint>
      </div>

      <div class="row pt-1 pb-1">
        <div class="col-lg-6">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit" style="width:90px;">Update</button>
          <button (click)="cancel()" class="btn btn-lg btn-light ms-3">Cancel</button>
        </div>
        <div class="col-lg-6 text-end">
          <button (click)="delete()" class="btn btn-lg btn-danger ms-3" type="button">Delete</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
