<app-segment-header (eventEmitted)="onEvent($event)" *ngIf="mode==='View'" [header]="'Loan Applications'" [help]="false" [op]="'add'" [isCard]="false" class="mt-3"></app-segment-header>

<app-box *ngIf="!isLoanApp" [close]="false" [type]="'warning'" class="mt-2">
  <strong>No loan application!</strong> To create a loan application, click the add button above (plus sign)
  or import a loan in XML format.
</app-box>

<app-box *ngIf="isLoanApp&&!isDefaultLoanApp" [close]="false" [type]="'danger'" class="pt-2">
  <strong>No default loan!</strong>
</app-box>

<div class="mt-4 mb-4">
  <span class="pr-font-large">Import Loan in MISMO format</span>
  <button (click)="import()" title="Import loan application" class="btn btn-lg btn-primary ms-3" aria-label="Import loan application">
    <i class="fa fa-file-upload"></i>&nbsp;Import
  </button>
</div>

<app-add-loan-app *ngIf="mode==='Add'" (eventEmitted)="onEvent($event)" [tran]="tran"></app-add-loan-app>

<div *ngIf="mode==='Upload'" class="mb-3">
  <app-upload-file (cancel)="cancel()" (uploadComplete)="onUploadDownloadComplete()" [actionUrl]="actionUrl" [title]="'Import MISMO 3.4 XML File'" [uploadType]="'mismo'" [multipleFiles]="false"></app-upload-file>
</div>

<div  *ngFor="let loanApp of loanApps" >
  <app-loan-app-container (eventEmitted)="onEvent($event)" [loanApp]="loanApp" [tran]="tran"></app-loan-app-container>
  <div class="mb-3"></div>
</div>

<iframe *ngIf="downloading" [src]="urlSafe" class="d-none">
</iframe>
