<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1 w-100">
    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="getSectionTitle()" [help]="true" [op]="'edit'">
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>
            In Configure Cash Flow Analysis you can configure values for FNMA Cash Flow Analysis, Form 1084.
          </li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <mat-card-content>
      <div class="row">
        <div class="col-lg-6">
          <div *ngFor="let cc of savedDoc[section]; let i = index">
            <div *ngIf="i < savedDoc[section].length/2" class="row pt-1 pb-1 border-bottom hover-gray">
              <div class="fw-bold col-lg-6">{{cc['item_desc']}}</div>
              <div *ngIf="cc['type']==='string' || cc['type']==='date'" class="col-lg-6">{{cc['value']}}</div>
              <div *ngIf="cc['type']==='percent'" class="col-lg-6">{{cc['value'] ? (cc['value'] / 100.00 | percent:'1.3-3') : '' }}</div>
              <div *ngIf="cc['type']==='currency'" class="col-lg-6">{{cc['value'] | currency}}</div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div *ngFor="let cc of savedDoc[section]; let i = index">
            <div *ngIf="i >= savedDoc[section].length/2" class="row pt-1 pb-1 border-bottom hover-gray">
              <div class="fw-bold col-lg-6">{{cc['item_desc']}}</div>
              <div *ngIf="cc['type']==='string' || cc['type']==='date'" class="col-lg-6">{{cc['value']}}</div>
              <div *ngIf="cc['type']==='percent'" class="col-lg-6">{{cc['value'] ? (cc['value'] / 100.00 | percent:'1.3-3') : '' }}</div>
              <div *ngIf="cc['type']==='currency'" class="col-lg-6">{{cc['value'] | currency}}</div>
            </div>
          </div>
        </div>
      </div>
      <app-box *ngIf="!savedDoc[section] || savedDoc[section].length === 0" [close]="false" [type]="'warning'" class="pt-2">
        The
        <span class="fw-bold">{{getSectionTitle()}}</span> tab has no entries.
        Please, update this tab to provide values for its fields.
      </app-box>
    </mat-card-content>
  </mat-card>
</div>
