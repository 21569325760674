<mat-card class="mt-2 mb-1">
  <app-view-sticky-note (eventEmitted)="onEvent($event)" [section]="section" [segment]="segment"></app-view-sticky-note>

  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Borrower Setup'" [help]="true" [op]="'edit'">
      Borrower Setup defines:
      <ul [ngClass]="{'is-mobile': isMobile}" class="help">
        <li>The total number of borrowers</li>
        <li>The default URLA form(s) utilized for generating PDFs</li>
        <li>The choice between obtaining a single or joint credit report</li>
      </ul>

      <h5>Grouping</h5>
      When there are multiple borrowers, the grouping of those borrowers identifies the individuals who share assets and liabilities.
      The primary borrower in each group is given the full URLA form, which contains information on assets and liabilities for both borrowers,
      while the second borrower receives the URLA Additional form. Additionally, only one credit report is obtained,
      and the liabilities are associated with the primary borrower.
      <br/>
      <span class="fw-bold">How to group:</span> Drag-and-Drop borrowers from the borrower list into the designated groups.
      <h5>Generating Documents</h5>
      When you generate documents (Repository/Generate Documents), the default borrower grouping will be displayed.
      However, you can modify this default grouping according to your preferences.
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row hover-gray border-bottom pb-1 pt-1">
      <div class="col-lg-6 fw-bold">
        Borrower count
      </div>
      <div class="col-lg-6 " *ngIf="+item.borrower_count===4">Four borrowers</div>
      <div class="col-lg-6" *ngIf="+item.borrower_count===3">Three borrowers</div>
      <div class="col-lg-6" *ngIf="+item.borrower_count===2">Two borrowers</div>
      <div class="col-lg-6" *ngIf="+item.borrower_count===1">One borrower</div>
    </div>

    <div class="row hover-gray border-bottom pb-1 pt-1">
      <div class="col-lg-6 fw-bold">
        Borrower grouping
      </div>
      <div class="col-lg-6 ">{{getGroupingSummary()}}</div>
    </div>
  </mat-card-content>
</mat-card>
