import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Tran} from '../../../models/tran';
import {CompBaseComponent} from '../../comp-base.component';
import {globals} from '../../../shared/globals/globals';
import {Help} from '../../../models/help';

@Component({
  selector: 'app-view-permission-tabs',
  templateUrl: './view-permission-tabs.component.html',
})
export class ViewPermissionTabsComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public static currentSection = globals.section.transactionPermissions;
  public pageTitle = 'Access Control';
  public currentSection: string;
  public localSection = globals.section;
  public isLoanOfficerTran: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload(false);
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.task
      });
    }, 0);
    this.reloadOnSync();
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload(false);
      return;
    }

    super.onEvent(event);
  }

  reload(force: boolean): void {
    this.reloaded = false;
    this.tranService.getCurrentTran(force)
      .then((tran: Tran) => {
        setTimeout(() => {
          this.isLoanOfficerTran = tran.isLoanOfficerTran();
          this.reloaded = true;
          this.setSection(null, ViewPermissionTabsComponent.currentSection);
        }, 0);
      });
  }

  setSection(event, section): void {
    this.currentSection = ViewPermissionTabsComponent.currentSection = section;
  }
}
