import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {globals} from '../../../../shared/globals/globals';

@Component({
  selector: 'app-view-tasks-tabs',
  templateUrl: './view-secured-uploads-tabs.component.html',
})
export class ViewSecuredUploadsTabsComponent extends CompBaseComponent implements OnInit {
  public static currentSection: string;
  public currentSection: string;
  public localSection = globals.section;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!ViewSecuredUploadsTabsComponent.currentSection) {
      ViewSecuredUploadsTabsComponent.currentSection = globals.section.securedUploads;
    }
    this.reload(false);
  }

  reload(force: boolean): void {
    this.reloaded = false;
    this.setSection(null, ViewSecuredUploadsTabsComponent.currentSection);
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  setSection(event, section): void {
    this.currentSection = section;
    ViewSecuredUploadsTabsComponent.currentSection = section;
  }
}
