import {Component, Injector, OnInit} from '@angular/core';
import {CADocBaseComponent} from "../../ca-doc-base.component";

@Component({
  selector: 'app-view-ca-doc',
  templateUrl: './view-ca-doc.component.html',
})
export class ViewCADocComponent extends CADocBaseComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.mode = 'View';
    this.reload();
  }

  reload(): void {
    this.reloaded = false;
    this.mode = 'View';
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  edit(): void {
    if (this.canUpdateTransaction) {
      this.eventEmitted.emit({
        type: 'ChangeMode',
        mode: 'Edit'
      });
    }
  }
}
