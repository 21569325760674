<div class="row">
  <div class="col-lg-9">
    <mat-card *ngIf="reloaded" class="mt-1 mb-1 h-100">
      <mat-card-title>Invite Users to Join PrudentCO</mat-card-title>
      <mat-card-content>
        You can invite new users to join PrudentCO in two different ways.

        <h5>Use POLOS to Send an Invite</h5>

        You can invite new user to join PrudentCO from
        <a class="pr-blue-bold" routerLink="/loan/view-emails">Communication/Emails</a>

        <ul>
          <li>
            You will receive a confirmation message after sending the invitation.
          </li>
          <li>
            Additionally, you will be notified once the user creates a PrudentCO account.
          </li>
        </ul>

        <h5>Use Email Service</h5>
        You can also invite a user through an external service like your CRM or email platform.
        To do this, simply copy the link below into your external service
        <div class="segment-header-box mt-2">
          <div class="row">
            <div class="col-lg-9 my-auto" id="invite_user_link">{{inviteUserLink}}</div>
            <div class="col-lg-3 my-auto">
              <button class="btn btn-lg btn-primary" (click)="copyLinkToClipboard()">Copy Link</button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
