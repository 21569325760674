import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';

import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserService} from '../services/user.service';
import {AdminService} from '../services/admin.service';
import {SharedModule} from '../shared/shared.module';
import {TranService} from '../services/tran.service';
import {NoteService} from '../services/note.service';
import {DocumentService} from '../services/document.service';
import {MessageService} from '../services/message.service';
import {TimeoutService} from '../services/timeout.service';
import {SharedService} from '../layouts/shared-service';
import {ViewPhotoComponent} from './account/photo/view-photo/view-photo.component';
import {AddContactComponent} from './account/contacts/add-contact/add-contact.component';
import {ViewContactComponent} from './account/contacts/view-contact/view-contact.component';
import {ViewContactsComponent} from './account/contacts/view-contacts/view-contacts.component';
import {ViewSegmentContactsComponent} from './account/contacts/view-segment-contacts/view-segment-contacts.component';
import {ViewSegmentContactComponent} from './account/contacts/view-segment-contact/view-segment-contact.component';
import {ViewLoanListComponent} from './loans/view-loan-list/view-loan-list.component';
import {ViewLoanComponent} from './loan/view-loan/view-loan.component';
import {ViewLoanRecordComponent} from './loan/view-loan-record/view-loan-record.component';
import {EditLoanRecordComponent} from './loan/edit-loan-record/edit-loan-record.component';
import {UIModule} from '../components/ui.module';
import {EditStickyNoteSegmentComponent} from './sticky/edit-sticky-note-segment/edit-sticky-note-segment.component';
import {StickyNoteComponent} from './sticky/sticky-note/sticky-note.component';
import {ViewStickyNoteComponent} from './sticky/view-sticky-note/view-sticky-note.component';
import {ViewStickyNoteSegmentComponent} from './sticky/view-sticky-note-segment/view-sticky-note-segment.component';

import {ResetPasswordComponent} from './account/reset-password/reset-password.component';
import {DocumentComponent} from './documents/document/document.component';
import {ViewDocumentComponent} from './documents/view-document/view-document.component';
import {EditDocumentComponent} from './documents/edit-document/edit-document.component';
import {ViewDocumentSystemComponent} from './documents/view-document-system/view-document-system.component';
import {ViewFolderComponent} from './documents/view-folder/view-folder.component';
import {FolderComponent} from './documents/folder/folder.component';
import {ViewFolderGroupComponent} from './documents/view-folder-group/view-folder-group.component';
import {EditFolderGroupComponent} from './documents/edit-folder-group/edit-folder-group.component';
import {AddFolderComponent} from './documents/add-folder/add-folder.component';
import {EditFolderComponent} from './documents/edit-folder/edit-folder.component';
import {FolderGroupComponent} from './documents/folder-group/folder-group.component';
import {ViewLoanAppComponent} from './loan-app/view-loan-app/view-loan-app.component';
import {ViewApplicantComponent} from './loan-app/view-applicant/view-applicant.component';
import {ViewTermsComponent} from './loan-app/view-terms/view-terms.component';
import {ViewLoComponent} from './loan-app/view-lo/view-lo.component';

import {ViewSegmentBorrowerComponent} from './loan-app/borrower/view-segment-borrower/view-segment-borrower.component';
import {ViewBorrowerComponent} from './loan-app/borrower/view-borrower/view-borrower.component';
import {EditSegmentBorrowerComponent} from './loan-app/borrower/edit-segment-borrower/edit-segment-borrower.component';

import {ViewAddressComponent} from './loan-app/addresses/view-address/view-address.component';
import {AddAddressComponent} from './loan-app/addresses/add-address/add-address.component';
import {ViewSegmentAddressComponent} from './loan-app/addresses/view-segment-address/view-segment-address.component';
import {EditSegmentAddressComponent} from './loan-app/addresses/edit-segment-address/edit-segment-address.component';
import {ViewSegmentAddressesComponent} from './loan-app/addresses/view-segment-addresses/view-segment-addresses.component';

import {ViewAssetComponent} from './loan-app/assets/view-asset/view-asset.component';
import {ViewSegmentAssetComponent} from './loan-app/assets/view-segment-asset/view-segment-asset.component';
import {ViewSegmentAssetsComponent} from './loan-app/assets/view-segment-assets/view-segment-assets.component';
import {EditSegmentAssetComponent} from './loan-app/assets/edit-segment-asset/edit-segment-asset.component';
import {AddAssetComponent} from './loan-app/assets/add-asset/add-asset.component';

import {ViewSegmentAliasComponent} from './loan-app/aliases/view-segment-alias/view-segment-alias.component';
import {ViewSegmentAliasesComponent} from './loan-app/aliases/view-segment-aliases/view-segment-aliases.component';
import {EditSegmentAliasComponent} from './loan-app/aliases/edit-segment-alias/edit-segment-alias.component';
import {AddAliasComponent} from './loan-app/aliases/add-alias/add-alias.component';

import {ViewDependentComponent} from './loan-app/dependents/view-dependent/view-dependent.component';
import {ViewSegmentDependentComponent} from './loan-app/dependents/view-segment-dependent/view-segment-dependent.component';
import {ViewSegmentDependentsComponent} from './loan-app/dependents/view-segment-dependents/view-segment-dependents.component';
import {EditSegmentDependentComponent} from './loan-app/dependents/edit-segment-dependent/edit-segment-dependent.component';
import {AddDependentComponent} from './loan-app/dependents/add-dependent/add-dependent.component';

import {ViewLiabilityComponent} from './loan-app/liabilities/view-liability/view-liability.component';
import {ViewSegmentLiabilityComponent} from './loan-app/liabilities/view-segment-liability/view-segment-liability.component';
import {ViewSegmentLiabilitiesComponent} from './loan-app/liabilities/view-segment-liabilities/view-segment-liabilities.component';
import {EditSegmentLiabilityComponent} from './loan-app/liabilities/edit-segment-liability/edit-segment-liability.component';
import {AddLiabilityComponent} from './loan-app/liabilities/add-liability/add-liability.component';

import {ViewIncomeComponent} from './loan-app/incomes/view-income/view-income.component';
import {ViewSegmentIncomeComponent} from './loan-app/incomes/view-segment-income/view-segment-income.component';
import {ViewSegmentIncomesComponent} from './loan-app/incomes/view-segment-incomes/view-segment-incomes.component';
import {EditSegmentIncomeComponent} from './loan-app/incomes/edit-segment-income/edit-segment-income.component';
import {AddIncomeComponent} from './loan-app/incomes/add-income/add-income.component';

import {ViewDownPaymentComponent} from './loan-app/down-payments/view-down-payment/view-down-payment.component';
import {ViewSegmentDownPaymentComponent} from './loan-app/down-payments/view-segment-down-payment/view-segment-down-payment.component';
import {ViewSegmentDownPaymentsComponent} from './loan-app/down-payments/view-segment-down-payments/view-segment-down-payments.component';
import {EditSegmentDownPaymentComponent} from './loan-app/down-payments/edit-segment-down-payment/edit-segment-down-payment.component';
import {AddDownPaymentComponent} from './loan-app/down-payments/add-down-payment/add-down-payment.component';

import {ViewExpenseComponent} from './loan-app/expenses/view-expense/view-expense.component';
import {ViewSegmentExpenseComponent} from './loan-app/expenses/view-segment-expense/view-segment-expense.component';
import {ViewSegmentExpensesComponent} from './loan-app/expenses/view-segment-expenses/view-segment-expenses.component';
import {EditSegmentExpenseComponent} from './loan-app/expenses/edit-segment-expense/edit-segment-expense.component';
import {AddExpenseComponent} from './loan-app/expenses/add-expense/add-expense.component';

import {ViewCreditComponent} from './loan-app/credits/view-credit/view-credit.component';
import {ViewSegmentCreditComponent} from './loan-app/credits/view-segment-credit/view-segment-credit.component';
import {ViewSegmentCreditsComponent} from './loan-app/credits/view-segment-credits/view-segment-credits.component';
import {EditSegmentCreditComponent} from './loan-app/credits/edit-segment-credit/edit-segment-credit.component';
import {AddCreditComponent} from './loan-app/credits/add-credit/add-credit.component';

import {ViewSupportComponent} from './loan-app/supports/view-support/view-support.component';
import {ViewSegmentSupportComponent} from './loan-app/supports/view-segment-support/view-segment-support.component';
import {ViewSegmentSupportsComponent} from './loan-app/supports/view-segment-supports/view-segment-supports.component';
import {EditSegmentSupportComponent} from './loan-app/supports/edit-segment-support/edit-segment-support.component';
import {AddSupportComponent} from './loan-app/supports/add-support/add-support.component';

import {ViewRaceComponent} from './loan-app/races/view-race/view-race.component';

import {ViewSegmentRaceComponent} from './loan-app/races/view-segment-race/view-segment-race.component';
import {ViewSegmentRacesComponent} from './loan-app/races/view-segment-races/view-segment-races.component';
import {EditSegmentRaceComponent} from './loan-app/races/edit-segment-race/edit-segment-race.component';
import {AddRaceComponent} from './loan-app/races/add-race/add-race.component';

import {ViewLoanPurposeComponent} from './loan-app/loan-purpose/view-loan-purpose/view-loan-purpose.component';
import {ViewSegmentLoanPurposeComponent} from './loan-app/loan-purpose/view-segment-loan-purpose/view-segment-loan-purpose.component';
import {EditSegmentLoanPurposeComponent} from './loan-app/loan-purpose/edit-segment-loan-purpose/edit-segment-loan-purpose.component';

import {MatDatepickerModule} from '@angular/material/datepicker';

import {ViewAliasComponent} from './loan-app/aliases/view-alias/view-alias.component';

import {ViewPropertyInformationComponent} from './loan-app/property-information/view-property-information/view-property-information.component';
import {ViewSegmentPropertyInformationComponent} from './loan-app/property-information/view-segment-property-information/view-segment-property-information.component';
import {EditSegmentPropertyInformationComponent} from './loan-app/property-information/edit-segment-property-information/edit-segment-property-information.component';

import {ViewTransmittalDataComponent} from './loan-app/transmittal-data/view-transmittal-data/view-transmittal-data.component';
import {ViewSegmentTransmittalDataComponent} from './loan-app/transmittal-data/view-segment-transmittal-data/view-segment-transmittal-data.component';
import {EditSegmentTransmittalDataComponent} from './loan-app/transmittal-data/edit-segment-transmittal-data/edit-segment-transmittal-data.component';

import {ViewLoanInformationComponent} from './loan-app/loan-information/view-loan-information/view-loan-information.component';
import {ViewSegmentLoanInformationComponent} from './loan-app/loan-information/view-segment-loan-information/view-segment-loan-information.component';
import {EditSegmentLoanInformationComponent} from './loan-app/loan-information/edit-segment-loan-information/edit-segment-loan-information.component';

import {ViewProductIdentificationComponent} from './loan-app/product-identification/view-product-identification/view-product-identification.component';
import {ViewSegmentProductIdentificationComponent} from './loan-app/product-identification/view-segment-product-identification/view-segment-product-identification.component';
import {EditSegmentProductIdentificationComponent} from './loan-app/product-identification/edit-segment-product-identification/edit-segment-product-identification.component';

import {ViewLoanCharacteristicsComponent} from './loan-app/loan-characteristics/view-loan-characteristics/view-loan-characteristics.component';
import {ViewSegmentLoanCharacteristicsComponent} from './loan-app/loan-characteristics/view-segment-loan-characteristics/view-segment-loan-characteristics.component';
import {EditSegmentLoanCharacteristicsComponent} from './loan-app/loan-characteristics/edit-segment-loan-characteristics/edit-segment-loan-characteristics.component';

import {ViewProductCharacteristicsComponent} from './loan-app/product-characteristics/view-product-characteristics/view-product-characteristics.component';
import {ViewSegmentProductCharacteristicsComponent} from './loan-app/product-characteristics/view-segment-product-characteristics/view-segment-product-characteristics.component';
import {EditSegmentProductCharacteristicsComponent} from './loan-app/product-characteristics/edit-segment-product-characteristics/edit-segment-product-characteristics.component';

import {ViewArmDataComponent} from './loan-app/arm-data/view-arm-data/view-arm-data.component';
import {ViewSegmentArmDataComponent} from './loan-app/arm-data/view-segment-arm-data/view-segment-arm-data.component';
import {EditSegmentArmDataComponent} from './loan-app/arm-data/edit-segment-arm-data/edit-segment-arm-data.component';

import {ViewFormTopComponent} from './loan-app/form-top/view-form-top/view-form-top.component';
import {ViewSegmentFormTopComponent} from './loan-app/form-top/view-segment-form-top/view-segment-form-top.component';
import {EditSegmentFormTopComponent} from './loan-app/form-top/edit-segment-form-top/edit-segment-form-top.component';

import {ViewInformationComponent} from './loan-app/information/view-information/view-information.component';
import {ViewSegmentInformationComponent} from './loan-app/information/view-segment-information/view-segment-information.component';
import {EditSegmentInformationComponent} from './loan-app/information/edit-segment-information/edit-segment-information.component';

import {ViewAgreementComponent} from './loan-app/agreement/view-agreement/view-agreement.component';
import {ViewSegmentAgreementComponent} from './loan-app/agreement/view-segment-agreement/view-segment-agreement.component';
import {EditSegmentAgreementComponent} from './loan-app/agreement/edit-segment-agreement/edit-segment-agreement.component';

import {ViewLoInformationComponent} from './loan-app/lo-information/view-lo-information/view-lo-information.component';
import {ViewSegmentLoInformationComponent} from './loan-app/lo-information/view-segment-lo-information/view-segment-lo-information.component';
import {EditSegmentLoInformationComponent} from './loan-app/lo-information/edit-segment-lo-information/edit-segment-lo-information.component';

import {ViewEmploymentComponent} from './loan-app/employment/view-employment/view-employment.component';
import {ViewSegmentEmploymentComponent} from './loan-app/employment/view-segment-employment/view-segment-employment.component';
import {EditSegmentEmploymentComponent} from './loan-app/employment/edit-segment-employment/edit-segment-employment.component';

import {ViewSegmentSecEmploymentsComponent} from './loan-app/sec-employments/view-segment-sec-employments/view-segment-sec-employments.component';
import {ViewSegmentSecEmploymentComponent} from './loan-app/sec-employments/view-segment-sec-employment/view-segment-sec-employment.component';
import {ViewSecEmploymentComponent} from './loan-app/sec-employments/view-sec-employment/view-sec-employment.component';
import {EditSegmentSecEmploymentComponent} from './loan-app/sec-employments/edit-segment-sec-employment/edit-segment-sec-employment.component';
import {AddSecEmploymentComponent} from './loan-app/sec-employments/add-sec-employment/add-sec-employment.component';

import {ViewSegmentRealEstateComponent} from './loan-app/real-estates/view-segment-real-estate/view-segment-real-estate.component';
import {ViewSegmentRealEstatesComponent} from './loan-app/real-estates/view-segment-real-estates/view-segment-real-estates.component';
import {ViewRealEstateComponent} from './loan-app/real-estates/view-real-estate/view-real-estate.component';
import {EditSegmentRealEstateComponent} from './loan-app/real-estates/edit-segment-real-estate/edit-segment-real-estate.component';
import {AddRealEstateComponent} from './loan-app/real-estates/add-real-estate/add-real-estate.component';

import {ViewPermissionsComponent} from './permissions/view-permissions/view-permissions.component';
import {ViewSegmentPermissionComponent} from './permissions/view-segment-permission/view-segment-permission.component';
import {ViewSegmentPermissionsComponent} from './permissions/view-segment-permissions/view-segment-permissions.component';
import {ViewPermissionComponent} from './permissions/view-permission/view-permission.component';
import {EditSegmentPermissionComponent} from './permissions/edit-segment-permission/edit-segment-permission.component';
import {AddPermissionComponent} from './permissions/add-permission/add-permission.component';

import {ViewLoanDataComponent} from './loan-app/loan-data/view-loan-data/view-loan-data.component';
import {ViewSegmentLoanDataComponent} from './loan-app/loan-data/view-segment-loan-data/view-segment-loan-data.component';
import {EditSegmentLoanDataComponent} from './loan-app/loan-data/edit-segment-loan-data/edit-segment-loan-data.component';

import {ViewEnvelopeHeaderComponent} from './loan-app/envelope-header/view-envelope-header/view-envelope-header.component';
import {ViewSegmentEnvelopeHeaderComponent} from './loan-app/envelope-header/view-segment-envelope-header/view-segment-envelope-header.component';
import {EditSegmentEnvelopeHeaderComponent} from './loan-app/envelope-header/edit-segment-envelope-header/edit-segment-envelope-header.component';

import {ViewDeclarationsComponent} from './loan-app/declarations/view-declarations/view-declarations.component';
import {ViewSegmentDeclarationsComponent} from './loan-app/declarations/view-segment-declarations/view-segment-declarations.component';
import {EditSegmentDeclarationsComponent} from './loan-app/declarations/edit-segment-declarations/edit-segment-declarations.component';

import {ViewDetailsComponent} from './loan-app/details/view-details/view-details.component';
import {ViewSegmentDetailsComponent} from './loan-app/details/view-segment-details/view-segment-details.component';
import {EditSegmentDetailsComponent} from './loan-app/details/edit-segment-details/edit-segment-details.component';

import {LoanAppService} from '../services/loan-app.service';
import {AnalyzerService} from '../services/analyzer.service';
import {PermissionService} from '../services/permission.service';
import {ComplianceService} from '../services/compliance.service';
import {LoanAppStructureService} from '../services/loan-app-structure.service';
import {MortgageCalculatorService} from '../services/mortgage-calculator.service';
import {EmailService} from '../services/email.service';

import {ViewSegmentDeclarationExplanationComponent} from './loan-app/declaration-explanations/view-segment-declaration-explanation/view-segment-declaration-explanation.component';
import {ViewSegmentDeclarationExplanationsComponent} from './loan-app/declaration-explanations/view-segment-declaration-explanations/view-segment-declaration-explanations.component';
import {ViewDeclarationExplanationComponent} from './loan-app/declaration-explanations/view-declaration-explanation/view-declaration-explanation.component';
import {EditSegmentDeclarationExplanationComponent} from './loan-app/declaration-explanations/edit-segment-declaration-explanation/edit-segment-declaration-explanation.component';

import {ViewAccountComponent} from './account/settings/view-account/view-account.component';
import {ViewSegmentAccountComponent} from './account/settings/view-segment-account/view-segment-account.component';
import {EditSegmentAccountComponent} from './account/settings/edit-segment-account/edit-segment-account.component';

import {ViewMLOConfigurationComponent} from './account/mlo-configuration/view-mlo-configuration/view-mlo-configuration.component';
import {ViewUserConfigurationComponent} from './account/mlo-configuration/view-user-configuration/view-user-configuration.component';
import {ViewSegmentUserConfigurationComponent} from './account/mlo-configuration/view-segment-user-configuration/view-segment-user-configuration.component';
import {EditSegmentUserConfigurationComponent} from './account/mlo-configuration/edit-segment-user-configuration/edit-segment-user-configuration.component';

import {ViewProfileComponent} from './account/branding/view-profile/view-profile.component';
import {ViewSegmentProfileComponent} from './account/branding/view-segment-profile/view-segment-profile.component';
import {EditSegmentProfileComponent} from './account/branding/edit-segment-profile/edit-segment-profile.component';

import {ViewWorkflowTabsComponent} from './workflow/view-workflow-tabs/view-workflow-tabs.component';
import {ViewWorkflowComponent} from './workflow/view-workflow/view-workflow.component';
import {ViewSegmentWorkflowComponent} from './workflow/view-segment-workflow/view-segment-workflow.component';
import {SegmentWorkflowComponent} from './workflow/segment-workflow/segment-workflow.component';
import {EditSegmentWorkflowComponent} from './workflow/edit-segment-workflow/edit-segment-workflow.component';

import {WorkflowService} from '../services/workflow.service';
import {TraceService} from '../services/trace.service';

import {ViewTasksTabsComponent} from './tasks/view-tasks-tabs/view-tasks-tabs.component';
import {ViewTaskComponent} from './tasks/view-task/view-task.component';
import {ViewSegmentTaskComponent} from './tasks/view-segment-task/view-segment-task.component';
import {EditSegmentTaskComponent} from './tasks/edit-segment-task/edit-segment-task.component';

import {ViewTimelineComponent} from './timeline/view-timeline/view-timeline.component';
import {ViewNotesComponent} from './notes/view-notes/view-notes.component';
import {ViewSegmentNoteComponent} from './notes/view-segment-note/view-segment-note.component';
import {EditSegmentNoteComponent} from './notes/edit-segment-note/edit-segment-note.component';
import {AddNoteComponent} from './notes/add-note/add-note.component';

import {ViewCompEventsComponent} from './compliance/view-comp-events/view-comp-events.component';
import {ViewCompEventComponent} from './compliance/view-comp-event/view-comp-event.component';
import {ViewSegmentCompEventComponent} from './compliance/view-segment-comp-event/view-segment-comp-event.component';
import {EditSegmentCompEventComponent} from './compliance/edit-segment-comp-event/edit-segment-comp-event.component';

import {ViewToolsComponent} from './loan-app/view-tools/view-tools.component';
import {ViewErrorsComponent} from './loan-app/tools/error-check/view-errors/view-errors.component';
import {ViewErrorCheckComponent} from './loan-app/tools/error-check/view-error-check/view-error-check.component';
import {ViewSegmentErrorCheckComponent} from './loan-app/tools/error-check/view-segment-error-check/view-segment-error-check.component';
import {EditSegmentErrorCheckComponent} from './loan-app/tools/error-check/edit-segment-error-check/edit-segment-error-check.component';

import {ViewSegmentFinalizeLoanComponent} from './loan-app/tools/finalize-loan/view-segment-finalize-loan/view-segment-finalize-loan.component';

import {ViewFinancialRatioComponent} from './loan-app/tools/financial-ratios/view-financial-ratio/view-financial-ratio.component';
import {EditSegmentFinancialRatioComponent} from './loan-app/tools/financial-ratios/edit-segment-financial-ratio/edit-segment-financial-ratio.component';
import {ViewSegmentFinancialRatioComponent} from './loan-app/tools/financial-ratios/view-segment-financial-ratio/view-segment-financial-ratio.component';
import {ViewFinancialRatiosComponent} from './loan-app/tools/financial-ratios/view-financial-ratios/view-financial-ratios.component';

import {ViewSegmentMessagesComponent} from './comm/view-segment-messages/view-segment-messages.component';
import {ViewSegmentMessageComponent} from './comm/view-segment-message/view-segment-message.component';
import {SendMessageComponent} from './comm/send-message/send-message.component';
import {SendEmailComponent} from './comm/send-email/send-email.component';
import {NewLoanComponent} from './loans/new-loan/new-loan.component';

import {ViewDossierTabsComponent} from './dossier/view-dossier-tabs/view-dossier-tabs.component';
import {ViewLoanAppsComponent} from './dossier/view-loan-apps/view-loan-apps.component';
import {ViewLoanAppRecordComponent} from './dossier/view-loan-app-record/view-loan-app-record.component';
import {ViewLoanAppContainerComponent} from './dossier/view-loan-app-container/view-loan-app-container.component';
import {EditLoanAppRecordComponent} from './dossier/edit-loan-app-record/edit-loan-app-record.component';
import {AddLoanAppComponent} from './dossier/add-loan-app/add-loan-app.component';
import {GenerateDocumentComponent} from './dossier/gendoc/generate-document/generate-document.component';
import {EditECOADocComponent} from './dossier/gendoc/US/edit-ecoa-doc/edit-ecoa-doc.component';
import {EditCreditAuthDocComponent} from './dossier/gendoc/US/edit-credit-auth-doc/edit-credit-auth-doc.component';
import {EditMBFADocComponent} from './dossier/gendoc/US/edit-mbfa-doc/edit-mbfa-doc.component';
import {EditAntiDocComponent} from './dossier/gendoc/US/edit-anti-doc/edit-anti-doc.component';
import {EditCOTnbDocComponent} from './dossier/gendoc/CO/edit-co-tnb-doc/edit-co-tnb-doc.component';
import {EditPatriotDocComponent} from './dossier/gendoc/US/edit-patriot-doc/edit-patriot-doc.component';
import {ViewDashboardComponent} from './loan-dashboard/view-dashboard/view-dashboard.component';
import {ContactComponent} from './account/contacts/contact/contact.component';
import {CompEventComponent} from './compliance/comp-event/comp-event.component';
import {AddressComponent} from './loan-app/addresses/address/address.component';
import {AssetComponent} from './loan-app/assets/asset/asset.component';
import {AliasComponent} from './loan-app/aliases/alias/alias.component';
import {DependentComponent} from './loan-app/dependents/dependent/dependent.component';
import {LiabilityComponent} from './loan-app/liabilities/liability/liability.component';
import {IncomeComponent} from './loan-app/incomes/income/income.component';
import {ExpenseComponent} from './loan-app/expenses/expense/expense.component';
import {CreditComponent} from './loan-app/credits/credit/credit.component';
import {SupportComponent} from './loan-app/supports/support/support.component';
import {RaceComponent} from './loan-app/races/race/race.component';
import {RealEstateComponent} from './loan-app/real-estates/real-estate/real-estate.component';
import {DeclarationExplanationComponent} from './loan-app/declaration-explanations/declaration-explanation/declaration-explanation.component';
import {PermissionComponent} from './permissions/permission/permission.component';
import {DownPaymentComponent} from './loan-app/down-payments/down-payment/down-payment.component';
import {SecEmploymentComponent} from './loan-app/sec-employments/sec-employment/sec-employment.component';
import {NoteComponent} from './notes/note/note.component';
import {ErrorCheckComponent} from './loan-app/tools/error-check/error-check/error-check.component';
import {FinancialRatioComponent} from './loan-app/tools/financial-ratios/financial-ratio/financial-ratio.component';
import {MessageComponent} from './comm/message/message.component';
import {EmailComponent} from './comm/email/email.component';
import {HighlightsComponent} from './home/highlights/highlights.component';
import {ViewUtilComponent} from './dossier/view-util/view-util.component';

import {ViewUserListComponent} from './admin/user/view-user-list/view-user-list.component';
import {FindUsersComponent} from './admin/user/find-users/find-users.component';
import {AdminUserComponent} from './admin/user/admin-user.component';
import {AdminEditUserComponent} from './admin/user/edit-user/admin-edit-user.component';
import {AdminAddUserComponent} from './admin/user/add-user/admin-add-user.component';

import {ViewPermissionTabsComponent} from './permissions/view-permission-tabs/view-permission-tabs.component';

import {HelpComponent} from './help/help.component';
import {HelpAnyComponent} from './help/subjects/any/help-any.component';
import {HelpLoansDashboardComponent} from './help/subjects/loans/dashboard/help-loans-dashboard.component';
import {HelpLoanDashboardComponent} from './help/subjects/loan/loan-dashboard/help-loan-dashboard.component';
import {HelpDirectoryComponent} from './help/subjects/loan/directory/help-directory.component';
import {HelpLoanAppComponent} from './help/subjects/loan/loan-app/help-loan-app.component';
import {HelpLoansListComponent} from './help/subjects/loans/list/help-loans-list.component';
import {HelpMessageComponent} from './help/subjects/loan/message/help-message.component';
import {HelpNoteComponent} from './help/subjects/loan/note/help-note.component';
import {HelpPermissionComponent} from './help/subjects/loan/permission/help-permission.component';
import {HelpTaskComponent} from './help/subjects/loan/task/help-task.component';
import {HelpTimelineComponent} from './help/subjects/loan/timeline/help-timeline.component';
import {HelpWorkflowComponent} from './help/subjects/loan/workflow/help-workflow.component';

import {HelpBaseComponent} from './help/help-base.component';
import {HelpComplianceComponent} from './help/subjects/loan/compliance/help-compliance.component';
import {BulkUpdateTasksComponent} from './tasks/bulk-update-tasks/bulk-update-tasks.component';

import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';

import {ViewSegmentResetWorkflowComponent} from './tasks/view-segment-reset-workflow/view-segment-reset-workflow.component';
import {ComplianceDashboardComponent} from './dashboard/compliance-dashboard/compliance-dashboard.component';
import {DashboardService} from '../services/dashboard.service';
import {ViewDashboardTabsComponent} from './dashboard/view-dashboard-tabs/view-dashboard-tabs.component';
import {WorkflowDashboardComponent} from './dashboard/workflow-dashboard/workflow-dashboard.component';
import {MessagesDashboardComponent} from './dashboard/messages-dashboard/messages-dashboard.component';
import {StepByStepComponent} from './home/step-by-step/step-by-step.component';
import {SiteLayoutComponent} from './home/site-layout/site-layout.component';
import {HomeComponent} from './home/home.component';

import {ViewSysAlertsComponent} from './alerts/view-sys-alerts/view-sys-alerts.component';
import {SysAlertService} from '../services/sys-alert.service';
import {SysAlertComponent} from './alerts/sys-alert/sys-alert.component';

import {LOInfoComponent} from './home/lo-info/lo-info.component';
import {HelpAccountContactsComponent} from './help/subjects/account/contacts/help-account-contacts.component';
import {HelpAccountPasswordComponent} from './help/subjects/account/password/help-account-password.component';
import {HelpAccountSettingsComponent} from './help/subjects/account/settings/help-account-settings.component';
import {HelpAccountPhotoComponent} from './help/subjects/account/photo/help-account-photo.component';
import {HelpAccountMLOComponent} from './help/subjects/account/mlo/help-account-mlo.component';
import {HelpAccountBrandingComponent} from './help/subjects/account/branding/help-account-branding.component';
import {HelpHomeHighlightsComponent} from './help/subjects/home/highlights/help-home-highlights.component';
import {HelpHomeMapComponent} from './help/subjects/home/map/help-home-map.component';
import {HelpHomeOfficerComponent} from './help/subjects/home/officer/help-home-officer.component';
import {HelpHomeStepComponent} from './help/subjects/home/step/help-home-step.component';
import {HelpLoansNewComponent} from './help/subjects/loans/new/help-loans-new.component';
import {SendInviteEmailComponent} from './comm/send-invite-email/send-invite-email.component';
import {ViewSegmentTasksComponent} from './tasks/view-segment-tasks/view-segment-tasks.component';
import {ViewInviteComponent} from './account/invite/view-invite/view-invite.component';
import {HelpAccountInviteComponent} from './help/subjects/account/invite/help-account-invite.component';
import {ViewCreditReportsComponent} from './loan-app/tools/credit-reports/view-credit-reports/view-credit-reports.component';
import {ViewCreditReportComponent} from './loan-app/tools/credit-reports/view-credit-report/view-credit-report.component';
import {ViewSegmentCreditReportComponent} from './loan-app/tools/credit-reports/view-segment-credit-report/view-segment-credit-report.component';
import {EditSegmentCreditReportComponent} from './loan-app/tools/credit-reports/edit-segment-credit-report/edit-segment-credit-report.component';
import {GenCreditReportComponent} from './loan-app/tools/credit-reports/gen-credit-report/gen-credit-report.component';
import {CreditReportService} from '../services/credit-report.service';
import {ManageSegmentCreditReportComponent} from './loan-app/tools/credit-reports/manage-segment-credit-report/manage-segment-credit-report.component';
import {EditOrganizationComponent} from './admin/organization/edit-organization/edit-organization.component';
import {AddOrganizationComponent} from './admin/organization/add-organization/add-organization.component';
import {OrganizationComponent} from './admin/organization/organization.component';
import {FindOrganizationsComponent} from './admin/organization/find-organizations/find-organizations.component';
import {ViewOrganizationListComponent} from './admin/organization/view-organization-list/view-organization-list.component';
import {ViewOrganizationComponent} from './admin/organization/view-organization/view-organization.component';
import {OrganizationService} from '../services/organization.service';
import {ViewUserOrgComponent} from './account/user-org/view-user-org/view-user-org.component';
import {EditSegmentUserOrgComponent} from './account/user-org/edit-segment-user-org/edit-segment-user-org.component';
import {ViewSegmentUserOrgComponent} from './account/user-org/view-segment-user-org/view-segment-user-org.component';
import {ViewOrgLoansComponent} from './org/loans/view-org-loans.component';
import {HelpAccountOrgComponent} from './help/subjects/account/org/help-account-org.component';
import {HelpOrgLoansComponent} from './help/subjects/org/loans/help-org-loans.component';
import {GenerateURLAComponent} from './dossier/gendoc/US/generate-urla/generate-urla.component';
import {EditSegmentLiabilitiesComponent} from './loan-app/liabilities/edit-segment-liabilities/edit-segment-liabilities.component';
import {ViewMcrComponent} from './loan-app/tools/mcr/view-mcr/view-mcr.component';
import {ViewSegmentMcrComponent} from './loan-app/tools/mcr/view-segment-mcr/view-segment-mcr.component';
import {EditSegmentMcrComponent} from './loan-app/tools/mcr/edit-segment-mcr/edit-segment-mcr.component';
import {ViewMcrListComponent} from './loans/mcr/view-mcr-list/view-mcr-list.component';
import {McrService} from '../services/mcr.service';
import {ViewLoMcrComponent} from './loans/mcr/view-lo-mcr/view-lo-mcr.component';
import {ViewSegmentLoMcrComponent} from './loans/mcr/view-segment-lo-mcr/view-segment-lo-mcr.component';
import {ManageSegmentLoMcrComponent} from './loans/mcr/manage-segment-lo-mcr/manage-segment-lo-mcr.component';
import {ViewOrgMcrsComponent} from './org/mcrs/view-org-mcrs/view-org-mcrs.component';
import {ViewSegmentOrgMcrComponent} from './org/mcrs/view-segment-org-mcr/view-segment-org-mcr.component';
import {ViewOrgMcrComponent} from './org/mcrs/view-org-mcr/view-org-mcr.component';
import {AddTranMcrComponent} from './loan-app/tools/mcr/add-tran-mcr/add-tran-mcr.component';
import {OrgMcrReportComponent} from './org/mcrs/org-mcr-report/org-mcr-report.component';
import {OrgMcrXmlComponent} from './org/mcrs/org-mcr-xml/org-mcr-xml.component';
import {ViewSegmentGiftsComponent} from './loan-app/gifts/view-segment-gifts/view-segment-gifts.component';
import {ViewSegmentGiftComponent} from './loan-app/gifts/view-segment-gift/view-segment-gift.component';
import {ViewGiftComponent} from './loan-app/gifts/view-gift/view-gift.component';
import {AddGiftComponent} from './loan-app/gifts/add-gift/add-gift.component';
import {EditSegmentGiftComponent} from './loan-app/gifts/edit-segment-gift/edit-segment-gift.component';
import {GiftComponent} from './loan-app/gifts/gift/gift.component';
import {ViewMilitaryComponent} from './loan-app/military/view-military/view-military.component';
import {ViewSegmentMilitaryComponent} from './loan-app/military/view-segment-military/view-segment-military.component';
import {EditSegmentMilitaryComponent} from './loan-app/military/edit-segment-military/edit-segment-military.component';
import {ViewNewMortgageComponent} from './loan-app/new-mortgages/view-new-mortgage/view-new-mortgage.component';
import {ViewSegmentNewMortgagesComponent} from './loan-app/new-mortgages/view-segment-new-mortgages/view-segment-new-mortgages.component';
import {AddNewMortgageComponent} from './loan-app/new-mortgages/add-new-mortgage/add-new-mortgage.component';
import {ViewSegmentNewMortgageComponent} from './loan-app/new-mortgages/view-segment-new-mortgage/view-segment-new-mortgage.component';
import {EditSegmentNewMortgageComponent} from './loan-app/new-mortgages/edit-segment-new-mortgage/edit-segment-new-mortgage.component';
import {ViewSegmentHmdaComponent} from './loan-app/hmda/view-segment-hmda/view-segment-hmda.component';
import {ViewHmdaComponent} from './loan-app/hmda/view-hmda/view-hmda.component';
import {EditSegmentHmdaComponent} from './loan-app/hmda/edit-segment-hmda/edit-segment-hmda.component';
import {ViewSegmentPartiesComponent} from './loan-app/parties/view-segment-parties/view-segment-parties.component';
import {ViewPartyComponent} from './loan-app/parties/view-party/view-party.component';
import {AddPartyComponent} from './loan-app/parties/add-party/add-party.component';
import {EditSegmentPartyComponent} from './loan-app/parties/edit-segment-party/edit-segment-party.component';
import {ViewSegmentPartyComponent} from './loan-app/parties/view-segment-party/view-segment-party.component';
import {CompBaseComponent} from './comp-base.component';
import {ViewLoanAppTabComponent} from './loan-app/view-loan-app-tab.component';
import {GenerateMISMOComponent} from './dossier/gendoc/US/generate-mismo/generate-mismo.component';
import {ViewSegmentConcessionsComponent} from './loan-app/concessions/view-segment-concessions/view-segment-concessions.component';
import {ViewConcessionComponent} from './loan-app/concessions/view-concession/view-concession.component';
import {EditSegmentConcessionComponent} from './loan-app/concessions/edit-segment-concession/edit-segment-concession.component';
import {AddConcessionComponent} from './loan-app/concessions/add-concession/add-concession.component';
import {ViewSegmentConcessionComponent} from './loan-app/concessions/view-segment-concession/view-segment-concession.component';
import {ViewConstructionComponent} from './loan-app/construction/view-construction/view-construction.component';
import {EditSegmentConstructionComponent} from './loan-app/construction/edit-segment-construction/edit-segment-construction.component';
import {ViewSegmentConstructionComponent} from './loan-app/construction/view-segment-construction/view-segment-construction.component';
import {SboxService} from '../services/sbox.service';
import {SendUploadEmailComponent} from './comm/send-upload-email/send-upload-email.component';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {ViewSegmentConditionsComponent} from './conditions/view-segment-conditions/view-segment-conditions.component';
import {ViewSegmentConditionComponent} from './conditions/view-segment-condition/view-segment-condition.component';
import {EditSegmentConditionComponent} from './conditions/edit-segment-condition/edit-segment-condition.component';
import {AddConditionComponent} from './conditions/add-condition/add-condition.component';
import {ConditionService} from '../services/condition.service';
import {ConditionComponent} from './conditions/condition/condition.component';
import {ViewSecuredUploadsComponent} from './ez-uploads/secured-uploads/view-secured-uploads/view-secured-uploads.component';
import {ViewSecuredUploadsTabsComponent} from './ez-uploads/secured-uploads/view-secured-uploads-tabs/view-secured-uploads-tabs.component';
import {HelpConditionsComponent} from './help/subjects/loan/conditions/help-conditions.component';
import {CalcComponent} from './calc/calc/calc.component';
import {ViewCalcMonthlyComponent} from './calc/view-calc-monthly/view-calc-monthly.component';
import {ViewSegmentCalcMonthlyComponent} from './calc/view-segment-calc-monthly/view-segment-calc-monthly.component';
import {EditSegmentCalcMonthlyComponent} from './calc/edit-segment-calc-monthly/edit-segment-calc-monthly.component';
import {ViewCalcRateComponent} from './calc/view-calc-rate/view-calc-rate.component';
import {ViewSegmentCalcRateComponent} from './calc/view-segment-calc-rate/view-segment-calc-rate.component';
import {EditSegmentCalcRateComponent} from './calc/edit-segment-calc-rate/edit-segment-calc-rate.component';
import {ViewCalcAmorComponent} from './calc/view-calc-amor/view-calc-amor.component';
import {ViewSegmentCalcAmorComponent} from './calc/view-segment-calc-amor/view-segment-calc-amor.component';
import {EditSegmentCalcAmorComponent} from './calc/edit-segment-calc-amor/edit-segment-calc-amor.component';
import {ViewCalcAPRComponent} from './calc/view-calc-apr/view-calc-apr.component';
import {ViewSegmentCalcAPRComponent} from './calc/view-segment-calc-apr/view-segment-calc-apr.component';
import {EditSegmentCalcAPRComponent} from './calc/edit-segment-calc-apr/edit-segment-calc-apr.component';
import {ViewOrgUsersComponent} from './account/user-org/view-org-users/view-org-users.component';
import {EditSegmentOrgUserComponent} from './account/user-org/edit-segment-org-user/edit-segment-org-user.component';
import {AddSegmentOrgUserComponent} from './account/user-org/add-segment-org-user/add-segment-org-user.component';
import {OrgAdminService} from '../services/org-admin.service';
import {ViewLoanMcrsComponent} from './org/loan-mcrs/view-loan-mcrs/view-loan-mcrs.component';
import {YearQuarterDialogComponent} from './org/loan-mcrs/year-quarter-dialog/year-quarter-dialog.component';
import {ViewLoanMcrComponent} from './org/loan-mcrs/view-loan-mcr/view-loan-mcr.component';
import {AddStateLicenseComponent} from './account/mlo-configuration/add-state-license/add-state-licenseview-segment-user-configuration.component';
import {ViewOrgInstructionsComponent} from './home/view-org-instructions/view-org-instructions.component';
import {OrgUserComponent} from './account/user-org/org-user.component';
import {HelpLoMcrListComponent} from './help/subjects/loans/lo-mcr-list/help-lo-mcr-list.component';
import {AdminResetPwComponent} from './admin/user/reset-pw/admin-reset-pw.component';
import {AdminViewUserComponent} from './admin/user/view-user/admin-view-user.component';
import {AddAgentOrganizationComponent} from './agent/organization/add-agent-organization/add-agent-organization.component';
import {EditAgentOrganizationComponent} from './agent/organization/edit-agent-organization/edit-agent-organization.component';
import {FindAgentOrganizationsComponent} from './agent/organization/find-agent-organizations/find-agent-organizations.component';
import {ViewAgentOrganizationListComponent} from './agent/organization/view-agent-organization-list/view-agent-organization-list.component';
import {ViewAgentOrganizationComponent} from './agent/organization/view-agent-organization/view-agent-organization.component';
import {ViewLoanMcrsContainerComponent} from './org/loan-mcrs/view-loan-mcrs-container/view-loan-mcrs-container.component';
import {ViewAgentPermissionsComponent} from './agent/permissions/view-agent-permissions/view-agent-permissions.component';

import {ViewSandboxLoansComponent} from './loans/view-sandbox-loans/view-sandbox-loans.component';
import {NewSandboxLoanComponent} from './loans/new-sandbox-loan/new-sandbox-loan.component';
import {FindTransComponent} from './admin/tran/find-trans/find-trans.component';
import {ViewTranListComponent} from './admin/tran/view-tran-list/view-tran-list.component';
import {AdminViewTranComponent} from './admin/tran/view-tran/admin-view-tran.component';
import {AdminTranComponent} from './admin/tran/admin-tran.component';
import {AdminEditTranComponent} from './admin/tran/edit-tran/admin-edit-tran.component';
import {ViewSandboxTranComponent} from './dossier/view-sandbox-tran/view-sandbox-tran.component';
import {EventService} from '../services/event.service';
import {CacheService} from '../services/cache.service';
import {PopupComplianceContainerComponent} from './popup-compliance-container/popup-compliance-container.component';
import {PopupComplianceComponent} from './popup-compliance/popup-compliance.component';
import {LoginFromAuthComponent} from './login/login-from-auth.component';
import {DeleteUserComponent} from './admin/user/delete-user/delete-user.component';
import {ViewPitchComponent} from './pitch/view-pitch/view-pitch.component';
import {NgxEchartsModule} from 'ngx-echarts';
import {PitchService} from '../services/pitch.service';
import {LoMcrComponent} from './loans/mcr/lo-mcr/lo-mcr.component';
import {McrComponent} from './loan-app/tools/mcr/mcr/mcr.component';
import {NewMortgageComponent} from './loan-app/new-mortgages/new-mortgage/new-mortgage.component';
import {OrgMcrComponent} from './org/mcrs/org-mcr/org-mcr.component';
import {PartyComponent} from './loan-app/parties/party/party.component';
import {ConcessionComponent} from './loan-app/concessions/concession/concession.component';
import {AgentOrganizationComponent} from './agent/organization/organization.component';
import {ViewConvertibleComponent} from "./pitch/view-convertible/view-convertible.component";
import {ViewSegmentConfigsComponent} from "./configs/view-segment-configs/view-segment-configs.component";
import {ViewSegmentConfigComponent} from "./configs/view-segment-config/view-segment-config.component";
import {ConfigComponent} from "./configs/config/config.component";
import {AddConfigComponent} from "./configs/add-config/add-config.component";
import {EditSegmentConfigComponent} from "./configs/edit-segment-config/edit-segment-config.component";
import {ViewSecuredDocumentComponent} from "./ez-uploads/secured-uploads/view-secured-document/view-secured-document.component";
import {SiteTopBannerComponent} from "./site/site-top-banner/site-top-banner.component";
import {ViewEmailsComponent} from "./comm/view-emails/view-emails.component";
import {ViewMessagesComponent} from "./comm/view-messages/view-messages.component";
import {CommComponent} from "./comm/comm.component";
import {AddCustomerComponent} from "./account/customers/add-customer/add-customer.component";
import {CustomerComponent} from "./account/customers/customer/customer.component";
import {ViewCustomerComponent} from "./account/customers/view-customer/view-customer.component";
import {ViewCustomersComponent} from "./account/customers/view-customers/view-customers.component";
import {ViewSegmentCustomerComponent} from "./account/customers/view-segment-customer/view-segment-customer.component";
import {ViewSegmentCustomersComponent} from "./account/customers/view-segment-customers/view-segment-customers.component";
import {ViewServicesComponent} from "./account/services/view-services/view-services.component";
import {ViewOrdersComponent} from "./account/subscriptions/view-orders/view-orders.component";
import {OrderService} from "../services/order.service";
import {OrderComponent} from "./account/subscriptions/order/order.component";
import {AddOrderComponent} from "./account/subscriptions/add-order/add-order.component";
import {EzUploadsInstructionsComponent} from "./ez-uploads/ez-uploads-instructions/ez-uploads-instructions.component";
import {ServicesComponent} from "./home/services/services.component";
import {ViewInvoicesComponent} from "./account/invoices/view-invoices/view-invoices.component";
import {PayInvoiceComponent} from "./account/invoices/pay-invoice/pay-invoice.component";
import {AuthPaymentComponent} from "./account/invoices/auth-payment/auth-payment.component";
import {ViewInvoiceComponent} from "./account/invoices/view-invoice/view-invoice.component";
import {HelpEZUploadsComponent} from "./help/subjects/uploads/help-ez-uploads.component";
import {FinalizeOrderComponent} from "./account/subscriptions/finalize-order/finalize-order.component";
import {HelpHomeServicesComponent} from "./help/subjects/home/services/help-home-services.component";
import {HelpAccountServicesComponent} from "./help/subjects/account/services/help-account-services.component";
import {HelpEmailsComponent} from "./help/subjects/loan/emails/help-emails.component";
import {HelpAccountInvoicesComponent} from "./help/subjects/account/invoices/help-account-invoices.component";
import {HelpAccountSubscriptionsComponent} from "./help/subjects/account/subscriptions/help-account-subscriptions.component";
import {ViewWizardComponent} from "./org/view-wizard/view-wizard.component";
import {ViewMcrInfoComponent} from "./org/mcrs/view-mcr-info/view-mcr-info.component";
import {InstructionsTabComponent} from "./Instructions/instructions-tab.component";
import {EditCODualDocComponent} from "./dossier/gendoc/CO/edit-co-dual-doc/edit-co-dual-doc.component";
import {GenerateDocumentBaseComponent} from "./dossier/gendoc/generate-document-base.component";
import {GenerateReqsComponent} from "./dossier/gendoc/US/generate-reqs/generate-reqs.component";
import {SelectDocumentComponent} from "./dossier/gendoc/select-document/select-document.component";
import {SendSocketMessageComponent} from "./admin/user/send-socket-message/send-socket-message.component";
import {EditSegmentBuydownComponent} from "./loan-app/buydown/edit-segment-buydown/edit-segment-buydown.component";
import {ViewBuydownComponent} from "./loan-app/buydown/view-buydown/view-buydown.component";
import {ViewSegmentBuydownComponent} from "./loan-app/buydown/view-segment-buydown/view-segment-buydown.component";
import {ConfigDocumentComponent} from "./dossier/gendoc/config-document/config-document.component";
import {UploadCreditReportComponent} from "./loan-app/tools/credit-reports/upload-credit-report/upload-credit-report.component";
import {GetCreditReportComponent} from "./loan-app/tools/credit-reports/get-credit-report/get-credit-report.component";

import {FeeWorksheetBaseComponent} from "./dossier/gendoc/fee-worksheet/fee-worksheet-base.component";
import {ViewWorksheetTabsComponent} from "./dossier/gendoc/fee-worksheet/view-worksheet-tabs/view-worksheet-tabs.component";
import {ViewWorksheetComponent} from "./dossier/gendoc/fee-worksheet/view-worksheet/view-worksheet.component";
import {ViewSegmentWorksheetComponent} from "./dossier/gendoc/fee-worksheet/view-segment-worksheet/view-segment-worksheet.component";
import {EditSegmentWorksheetComponent} from "./dossier/gendoc/fee-worksheet/edit-segment-worksheet/edit-segment-worksheet.component";
import {LoanEstimateBaseComponent} from "./dossier/gendoc/loan-estimate/loan-estimate-base.component";
import {ViewEstimateTabsComponent} from "./dossier/gendoc/loan-estimate/view-estimate-tabs/view-estimate-tabs.component";
import {ViewEstimateComponent} from "./dossier/gendoc/loan-estimate/view-estimate/view-estimate.component";
import {ViewSegmentEstimateComponent} from "./dossier/gendoc/loan-estimate/view-segment-estimate/view-segment-estimate.component";
import {EditSegmentEstimateComponent} from "./dossier/gendoc/loan-estimate/edit-segment-estimate/edit-segment-estimate.component";
import {CreditReportComponent} from "./loan-app/tools/credit-reports/credit-report.component";

import {CFABaseComponent} from "./dossier/gendoc/cash-flow-analysis/cfa-base.component";
import {ViewCFATabsComponent} from "./dossier/gendoc/cash-flow-analysis/view-cfa-tabs/view-cfa-tabs.component";
import {ViewCFAComponent} from "./dossier/gendoc/cash-flow-analysis/view-cfa/view-cfa.component";
import {ViewSegmentCFAComponent} from "./dossier/gendoc/cash-flow-analysis/view-segment-cfa/view-segment-cfa.component";
import {EditSegmentCFAComponent} from "./dossier/gendoc/cash-flow-analysis/edit-segment-cfa/edit-segment-cfa.component";
import {ViewBorrowerDocumentComponent} from "./documents/view-borrower-document/view-borrower-document.component";
import {ProfileService} from "../services/profile.service";
import {CADocBaseComponent} from "./dossier/gendoc/CA/ca-doc-base.component";
import {EditSegmentOriginationComponent} from "./loan-app/origination/edit-segment-origination/edit-segment-origination.component";
import {ViewSegmentOriginationComponent} from "./loan-app/origination/view-segment-origination/view-segment-origination.component";
import {ViewOriginationComponent} from "./loan-app/origination/view-origination/view-origination.component";
import {EditSegmentCADocComponent} from "./dossier/gendoc/CA/ca-doc/edit-segment-ca-doc/edit-segment-ca-doc.component";
import {ViewCADocComponent} from "./dossier/gendoc/CA/ca-doc/view-ca-doc/view-ca-doc.component";
import {ViewSegmentCADocComponent} from "./dossier/gendoc/CA/ca-doc/view-segment-ca-doc/view-segment-ca-doc.component";

@NgModule({
  declarations: [
    ContactComponent,
    CompEventComponent,
    HighlightsComponent,
    ServicesComponent,
    AddressComponent,
    AssetComponent,
    AliasComponent,
    DependentComponent,
    LiabilityComponent,
    IncomeComponent,
    ExpenseComponent,
    CreditComponent,
    SupportComponent,
    RaceComponent,
    RealEstateComponent,
    DeclarationExplanationComponent,
    PermissionComponent,
    DownPaymentComponent,
    SecEmploymentComponent,
    NoteComponent,
    ErrorCheckComponent,
    FinancialRatioComponent,
    MessageComponent,
    EmailComponent,
    CompBaseComponent,
    ViewLoanAppTabComponent,
    ResetPasswordComponent,
    ViewPhotoComponent,
    ViewContactsComponent,
    ViewSegmentContactsComponent,
    ViewContactComponent,
    ViewSegmentContactComponent,
    AddContactComponent,
    ViewLoanListComponent,
    ViewSandboxLoansComponent,
    ViewLoanComponent,
    ViewLoanRecordComponent,
    EditLoanRecordComponent,
    ViewDocumentSystemComponent,
    ViewFolderGroupComponent,
    EditFolderGroupComponent,
    FolderGroupComponent,
    FolderComponent,
    ViewFolderComponent,
    EditFolderComponent,
    DocumentComponent,
    ViewDocumentComponent,
    EditDocumentComponent,
    AddFolderComponent,
    ViewLoanAppComponent,
    ViewApplicantComponent,
    ViewTermsComponent,
    ViewLoComponent,
    ViewBorrowerComponent,
    EditSegmentBorrowerComponent,
    ViewSegmentBorrowerComponent,
    ViewAddressComponent,
    AddAddressComponent,
    ViewSegmentAddressComponent,
    EditSegmentAddressComponent,
    ViewSegmentAddressesComponent,
    ViewAssetComponent,
    ViewSegmentAssetsComponent,
    ViewSegmentAssetComponent,
    EditSegmentAssetComponent,
    AddAssetComponent,
    ViewSegmentAliasesComponent,
    ViewSegmentAliasComponent,
    EditSegmentAliasComponent,
    AddAliasComponent,
    ViewSegmentDependentsComponent,
    ViewSegmentDependentComponent,
    EditSegmentDependentComponent,
    AddDependentComponent,
    ViewDependentComponent,
    ViewLiabilityComponent,
    ViewSegmentLiabilitiesComponent,
    ViewSegmentLiabilityComponent,
    EditSegmentLiabilityComponent,
    EditSegmentLiabilitiesComponent,
    AddLiabilityComponent,
    ViewIncomeComponent,
    ViewSegmentIncomesComponent,
    ViewSegmentIncomeComponent,
    EditSegmentIncomeComponent,
    AddIncomeComponent,
    ViewDownPaymentComponent,
    ViewSegmentDownPaymentsComponent,
    ViewSegmentDownPaymentComponent,
    EditSegmentDownPaymentComponent,
    AddDownPaymentComponent,
    ViewSegmentExpensesComponent,
    ViewSegmentExpenseComponent,
    EditSegmentExpenseComponent,
    AddExpenseComponent,
    ViewExpenseComponent,
    ViewSegmentCreditsComponent,
    ViewSegmentCreditComponent,
    EditSegmentCreditComponent,
    AddCreditComponent,
    ViewCreditComponent,
    ViewSegmentSupportsComponent,
    ViewSegmentSupportComponent,
    EditSegmentSupportComponent,
    AddSupportComponent,
    ViewSupportComponent,
    ViewRaceComponent,
    ViewSegmentRacesComponent,
    ViewSegmentRaceComponent,
    EditSegmentRaceComponent,
    AddRaceComponent,
    ViewAliasComponent,
    ViewLoanPurposeComponent,
    ViewSegmentLoanPurposeComponent,
    EditSegmentLoanPurposeComponent,
    ViewPropertyInformationComponent,
    ViewSegmentPropertyInformationComponent,
    EditSegmentPropertyInformationComponent,
    ViewTransmittalDataComponent,
    ViewSegmentTransmittalDataComponent,
    EditSegmentTransmittalDataComponent,
    ViewLoanDataComponent,
    ViewSegmentLoanDataComponent,
    EditSegmentLoanDataComponent,
    ViewLoanInformationComponent,
    ViewSegmentLoanInformationComponent,
    EditSegmentLoanInformationComponent,
    ViewArmDataComponent,
    ViewSegmentArmDataComponent,
    EditSegmentArmDataComponent,
    ViewFormTopComponent,
    ViewSegmentFormTopComponent,
    EditSegmentFormTopComponent,
    ViewInformationComponent,
    ViewSegmentInformationComponent,
    EditSegmentInformationComponent,
    ViewLoInformationComponent,
    ViewSegmentLoInformationComponent,
    EditSegmentLoInformationComponent,
    ViewEmploymentComponent,
    ViewSegmentEmploymentComponent,
    EditSegmentEmploymentComponent,
    ViewSegmentSecEmploymentsComponent,
    ViewSegmentSecEmploymentComponent,
    ViewSecEmploymentComponent,
    EditSegmentSecEmploymentComponent,
    AddSecEmploymentComponent,
    ViewAgreementComponent,
    ViewSegmentAgreementComponent,
    EditSegmentAgreementComponent,
    ViewProductIdentificationComponent,
    ViewSegmentProductIdentificationComponent,
    EditSegmentProductIdentificationComponent,
    ViewProductCharacteristicsComponent,
    ViewSegmentProductCharacteristicsComponent,
    EditSegmentProductCharacteristicsComponent,
    ViewLoanCharacteristicsComponent,
    ViewSegmentLoanCharacteristicsComponent,
    EditSegmentLoanCharacteristicsComponent,
    ViewEnvelopeHeaderComponent,
    ViewSegmentEnvelopeHeaderComponent,
    EditSegmentEnvelopeHeaderComponent,
    ViewDetailsComponent,
    ViewSegmentDetailsComponent,
    EditSegmentDetailsComponent,
    ViewDeclarationsComponent,
    ViewSegmentDeclarationsComponent,
    EditSegmentDeclarationsComponent,
    ViewSegmentRealEstateComponent,
    ViewSegmentRealEstatesComponent,
    ViewRealEstateComponent,
    EditSegmentRealEstateComponent,
    AddRealEstateComponent,
    ViewSegmentDeclarationExplanationComponent,
    ViewSegmentDeclarationExplanationsComponent,
    EditSegmentDeclarationExplanationComponent,
    ViewDeclarationExplanationComponent,
    ViewPermissionsComponent,
    ViewSegmentPermissionComponent,
    ViewSegmentPermissionsComponent,
    ViewPermissionComponent,
    EditSegmentPermissionComponent,
    AddPermissionComponent,
    ViewAccountComponent,
    ViewSegmentAccountComponent,
    EditSegmentAccountComponent,
    ViewMLOConfigurationComponent,
    ViewUserConfigurationComponent,
    ViewSegmentUserConfigurationComponent,
    EditSegmentUserConfigurationComponent,
    ViewProfileComponent,
    ViewSegmentProfileComponent,
    EditSegmentProfileComponent,
    ViewWorkflowTabsComponent,
    ViewWorkflowComponent,
    ViewSegmentWorkflowComponent,
    SegmentWorkflowComponent,
    EditSegmentWorkflowComponent,
    ViewSegmentResetWorkflowComponent,
    ViewTasksTabsComponent,
    ViewTaskComponent,
    ViewSegmentTaskComponent,
    ViewSegmentTasksComponent,
    EditSegmentTaskComponent,
    ViewTimelineComponent,
    ViewNotesComponent,
    ViewSegmentNoteComponent,
    EditSegmentNoteComponent,
    AddNoteComponent,
    ViewCompEventsComponent,
    ViewCompEventsComponent,
    ViewCompEventComponent,
    ViewSegmentCompEventComponent,
    EditSegmentCompEventComponent,
    ViewToolsComponent,
    ViewErrorsComponent,
    ViewErrorCheckComponent,
    ViewSegmentErrorCheckComponent,
    EditSegmentErrorCheckComponent,
    ViewSegmentFinalizeLoanComponent,
    ViewFinancialRatiosComponent,
    ViewFinancialRatioComponent,
    EditSegmentFinancialRatioComponent,
    ViewSegmentFinancialRatioComponent,
    ViewEmailsComponent,
    ViewMessagesComponent,
    ViewSegmentMessagesComponent,
    ViewSegmentMessageComponent,
    SendMessageComponent,
    SendEmailComponent,
    SendInviteEmailComponent,
    SendUploadEmailComponent,
    NewLoanComponent,
    NewSandboxLoanComponent,
    ViewDossierTabsComponent,
    ViewLoanAppsComponent,
    ViewLoanAppRecordComponent,
    ViewLoanAppContainerComponent,
    EditLoanAppRecordComponent,
    AddLoanAppComponent,
    ViewSandboxTranComponent,
    GenerateDocumentComponent,
    GenerateDocumentBaseComponent,
    GenerateURLAComponent,
    GenerateReqsComponent,
    SelectDocumentComponent,
    GenerateMISMOComponent,
    EditECOADocComponent,
    EditCreditAuthDocComponent,
    EditMBFADocComponent,
    EditAntiDocComponent,
    EditCOTnbDocComponent,
    EditPatriotDocComponent,
    EditCODualDocComponent,
    ViewDashboardComponent,
    ViewUtilComponent,
    ViewUserListComponent,
    ViewOrganizationListComponent,
    ViewOrganizationComponent,
    FindUsersComponent,
    FindOrganizationsComponent,
    AdminUserComponent,
    AdminEditUserComponent,
    AdminAddUserComponent,
    OrganizationComponent,
    EditOrganizationComponent,
    AddOrganizationComponent,
    AdminResetPwComponent,
    AdminViewUserComponent,
    FindTransComponent,
    ViewTranListComponent,
    AdminTranComponent,
    AdminViewTranComponent,
    AdminEditTranComponent,
    ViewPermissionTabsComponent,
    EditStickyNoteSegmentComponent,
    StickyNoteComponent,
    ViewStickyNoteComponent,
    ViewStickyNoteSegmentComponent,
    HelpBaseComponent,
    HelpComponent,
    HelpAccountContactsComponent,
    HelpAccountPasswordComponent,
    HelpAccountSettingsComponent,
    HelpAccountInvoicesComponent,
    HelpAccountSubscriptionsComponent,
    HelpAccountPhotoComponent,
    HelpAccountMLOComponent,
    HelpAccountBrandingComponent,
    HelpAccountInviteComponent,
    HelpHomeHighlightsComponent,
    HelpHomeMapComponent,
    HelpHomeOfficerComponent,
    HelpHomeStepComponent,
    HelpAnyComponent,
    HelpLoansDashboardComponent,
    HelpLoanDashboardComponent,
    HelpDirectoryComponent,
    HelpLoanAppComponent,
    HelpLoansListComponent,
    HelpLoMcrListComponent,
    HelpLoansNewComponent,
    HelpMessageComponent,
    HelpEmailsComponent,
    HelpNoteComponent,
    HelpPermissionComponent,
    HelpTaskComponent,
    HelpTimelineComponent,
    HelpWorkflowComponent,
    HelpComplianceComponent,
    HelpConditionsComponent,
    HelpAccountOrgComponent,
    HelpOrgLoansComponent,
    HelpEZUploadsComponent,
    HelpHomeServicesComponent,
    HelpAccountServicesComponent,
    BulkUpdateTasksComponent,
    ComplianceDashboardComponent,
    ViewDashboardTabsComponent,
    WorkflowDashboardComponent,
    MessagesDashboardComponent,
    StepByStepComponent,
    SiteLayoutComponent,
    HomeComponent,
    ViewSysAlertsComponent,
    SysAlertComponent,
    LOInfoComponent,
    ViewInviteComponent,
    ViewCreditReportsComponent,
    ViewCreditReportComponent,
    ViewSegmentCreditReportComponent,
    EditSegmentCreditReportComponent,
    GenCreditReportComponent,
    ManageSegmentCreditReportComponent,
    ViewUserOrgComponent,
    EditSegmentUserOrgComponent,
    ViewSegmentUserOrgComponent,
    ViewOrgLoansComponent,
    ViewMcrComponent,
    ViewSegmentMcrComponent,
    EditSegmentMcrComponent,
    McrComponent,
    ViewMcrListComponent,
    ViewLoMcrComponent,
    ViewSegmentLoMcrComponent,
    ManageSegmentLoMcrComponent,
    LoMcrComponent,
    ViewOrgMcrsComponent,
    ViewOrgMcrComponent,
    ViewSegmentOrgMcrComponent,
    AddTranMcrComponent,
    OrgMcrReportComponent,
    OrgMcrXmlComponent,
    OrgMcrComponent,
    ViewMcrInfoComponent,
    GiftComponent,
    ViewSegmentGiftsComponent,
    ViewGiftComponent,
    ViewSegmentGiftComponent,
    AddGiftComponent,
    EditSegmentGiftComponent,
    ViewMilitaryComponent,
    ViewSegmentMilitaryComponent,
    EditSegmentMilitaryComponent,
    ViewNewMortgageComponent,
    ViewSegmentNewMortgagesComponent,
    AddNewMortgageComponent,
    ViewSegmentNewMortgageComponent,
    EditSegmentNewMortgageComponent,
    NewMortgageComponent,
    ViewSegmentHmdaComponent,
    ViewHmdaComponent,
    EditSegmentHmdaComponent,
    ViewSegmentPartiesComponent,
    ViewPartyComponent,
    PartyComponent,
    EditSegmentPartyComponent,
    AddPartyComponent,
    ViewSegmentPartyComponent,
    ViewConcessionComponent,
    EditSegmentConcessionComponent,
    AddConcessionComponent,
    ViewSegmentConcessionComponent,
    ViewSegmentConcessionsComponent,
    ConcessionComponent,
    ConditionComponent,
    ViewConstructionComponent,
    EditSegmentConstructionComponent,
    ViewSegmentConstructionComponent,
    ViewSecuredUploadsComponent,
    ViewSecuredUploadsTabsComponent,
    MaintenanceComponent,
    ViewSegmentConditionsComponent,
    ViewSegmentConditionComponent,
    EditSegmentConditionComponent,
    AddConditionComponent,
    ConditionComponent,
    ViewCFAComponent,
    CalcComponent,
    ViewCalcMonthlyComponent,
    ViewSegmentCalcMonthlyComponent,
    EditSegmentCalcMonthlyComponent,
    ViewCalcRateComponent,
    ViewSegmentCalcRateComponent,
    EditSegmentCalcRateComponent,
    ViewCalcAmorComponent,
    ViewSegmentCalcAmorComponent,
    EditSegmentCalcAmorComponent,
    ViewCalcAPRComponent,
    ViewSegmentCalcAPRComponent,
    EditSegmentCalcAPRComponent,
    ViewOrgUsersComponent,
    EditSegmentOrgUserComponent,
    AddSegmentOrgUserComponent,
    ViewLoanMcrsComponent,
    YearQuarterDialogComponent,
    ViewLoanMcrComponent,
    AddStateLicenseComponent,
    ViewOrgInstructionsComponent,
    OrgUserComponent,
    AddAgentOrganizationComponent,
    EditAgentOrganizationComponent,
    FindAgentOrganizationsComponent,
    ViewAgentOrganizationListComponent,
    ViewAgentOrganizationComponent,
    AgentOrganizationComponent,
    ViewLoanMcrsContainerComponent,
    ViewAgentPermissionsComponent,
    ViewInvoicesComponent,
    ViewInvoiceComponent,
    PayInvoiceComponent,
    AuthPaymentComponent,
    PopupComplianceContainerComponent,
    PopupComplianceComponent,
    LoginFromAuthComponent,
    DeleteUserComponent,
    ViewPitchComponent,
    ViewConvertibleComponent,
    ViewSegmentConfigsComponent,
    ViewSegmentConfigComponent,
    ConfigComponent,
    AddConfigComponent,
    EditSegmentConfigComponent,
    ViewSecuredDocumentComponent,
    SiteTopBannerComponent,
    CommComponent,
    AddCustomerComponent,
    CustomerComponent,
    ViewCustomerComponent,
    ViewCustomersComponent,
    ViewSegmentCustomerComponent,
    ViewSegmentCustomersComponent,
    ViewServicesComponent,
    ViewOrdersComponent,
    OrderComponent,
    AddOrderComponent,
    EzUploadsInstructionsComponent,
    FinalizeOrderComponent,
    ViewWizardComponent,
    InstructionsTabComponent,
    SendSocketMessageComponent,
    EditSegmentBuydownComponent,
    ViewBuydownComponent,
    ViewSegmentBuydownComponent,
    ConfigDocumentComponent,
    UploadCreditReportComponent,
    GetCreditReportComponent,
    FeeWorksheetBaseComponent,
    CFABaseComponent,
    ViewWorksheetTabsComponent,
    ViewCFATabsComponent,
    ViewWorksheetComponent,
    ViewCFAComponent,
    ViewSegmentWorksheetComponent,
    ViewSegmentCFAComponent,
    EditSegmentWorksheetComponent,
    EditSegmentCFAComponent,
    LoanEstimateBaseComponent,
    ViewEstimateTabsComponent,
    ViewEstimateComponent,
    ViewSegmentEstimateComponent,
    EditSegmentEstimateComponent,
    CreditReportComponent,
    ViewBorrowerDocumentComponent,

    CADocBaseComponent,
    EditSegmentOriginationComponent,
    ViewSegmentOriginationComponent,
    ViewOriginationComponent,
    EditSegmentCADocComponent,
    ViewCADocComponent,
    ViewSegmentCADocComponent
  ],
  exports: [
    ContactComponent,
    CompEventComponent,
    PopupComplianceContainerComponent,
    PopupComplianceComponent,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTabsModule,
    MatSelectModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatSliderModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatInputModule,
    MatGridListModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatListModule,
    MatDialogModule,
    MatCheckboxModule,
    SharedModule,
    HelpComponent,
    ViewPitchComponent,
    ViewConvertibleComponent,
    SiteTopBannerComponent
  ],
  imports: [MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTabsModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatSliderModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatInputModule,
    MatGridListModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatListModule,
    MatDialogModule,
    MatCheckboxModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    UIModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    })],
  providers: [
    UserService,
    AdminService,
    OrgAdminService,
    OrganizationService,
    TranService,
    DocumentService,
    LoanAppService,
    AnalyzerService,
    PermissionService,
    ComplianceService,
    WorkflowService,
    TraceService,
    NoteService,
    LoanAppStructureService,
    MortgageCalculatorService,
    MessageService,
    McrService,
    EmailService,
    TimeoutService,
    SharedService,
    DashboardService,
    CreditReportService,
    SysAlertService,
    SboxService,
    ConditionService,
    EventService,
    CacheService,
    PitchService,
    OrderService,
    ProfileService,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class ContentModule {
}
