<div *ngIf="reloaded">
  <ul class="nav nav-tabs" id="loan_app_tabs" role="list">
    <li class="nav-item">
      <a (click)="setSection($event,localSection.terms,null)" [attr.aria-selected]="currentSection===localSection.terms" [ngClass]="{active:currentSection===localSection.terms}" class="nav-link" data-toggle="tab"
        id="terms-tab">Shared Data</a>
    </li>

    <li class="nav-item">
      <a (click)="setSection($event,localSection.borrower_1,null)" [attr.aria-selected]="currentSection===localSection.borrower_1" [ngClass]="{active:currentSection===localSection.borrower_1}" class="nav-link" data-toggle="tab"
        id="borrower-tab">Borrower</a>
    </li>

    <li *ngIf="borrowerCount >= 2" class="nav-item">
      <a (click)="setSection($event,localSection.borrower_2,null)" [attr.aria-selected]="currentSection===localSection.borrower_2" [ngClass]="{active:currentSection===localSection.borrower_2}" class="nav-link"
        data-toggle="tab" id="co-borrower-tab"
      >Co-Borrower</a>
    </li>

    <li *ngIf="borrowerCount >= 3" class="nav-item">
      <a (click)="setSection($event,localSection.borrower_3,null)" [attr.aria-selected]="currentSection===localSection.borrower_3" [ngClass]="{active:currentSection===localSection.borrower_3}" class="nav-link"
        data-toggle="tab" id="third-borrower-tab"
      >Borrower 3</a>
    </li>

    <li *ngIf="borrowerCount >= 4" class="nav-item">
      <a (click)="setSection($event,localSection.borrower_4,null)" [attr.aria-selected]="currentSection===localSection.borrower_4" [ngClass]="{active:currentSection===localSection.borrower_4}" class="nav-link"
        data-toggle="tab" id="fourth-borrower-tab"
      >Borrower 4</a>
    </li>

    <li *ngIf="canReadLoanOfficer" class="nav-item">
      <a (click)="setSection($event,localSection.lo,null)" [attr.aria-selected]="currentSection===localSection.lo" [ngClass]="{active:currentSection===localSection.lo}" class="nav-link" data-toggle="tab"
        id="lo-tab">Loan Officer</a>
    </li>

    <li class="nav-item">
      <a (click)="setSection($event,localSection.tools,null)" [attr.aria-selected]="currentSection===localSection.tools" [ngClass]="{active:currentSection===localSection.tools}" class="nav-link" data-toggle="tab"
        id="tools-tab">Tools</a>
    </li>
  </ul>

  <div class="tab-content" id="tabs">
    <div [ngClass]="{'show active':currentSection===localSection.terms}" aria-labelledby="terms-tab" class="tab-pane fade" id="terms" role="tabpanel">
      <app-view-terms (eventEmitted)="onEvent($event)"></app-view-terms>
    </div>

    <div [ngClass]="{'show active':currentSection===localSection.borrower_1}" aria-labelledby="borrower-tab" class="tab-pane fade" id="applicant_borrower" role="tabpanel">
      <app-view-applicant (eventEmitted)="onEvent($event)" [applicant]="'borrower_1'"></app-view-applicant>
    </div>

    <div *ngIf="borrowerCount>=2" [ngClass]="{'show active':currentSection===localSection.borrower_2}" aria-labelledby="borrower-tab" class="tab-pane fade" id="applicant_co_borrower" role="tabpanel">
      <app-view-applicant (eventEmitted)="onEvent($event)" [applicant]="'borrower_2'"></app-view-applicant>
    </div>

    <div *ngIf="borrowerCount>=3" [ngClass]="{'show active':currentSection===localSection.borrower_3}" aria-labelledby="borrower-tab" class="tab-pane fade" id="applicant_borrower_3" role="tabpanel">
      <app-view-applicant (eventEmitted)="onEvent($event)" [applicant]="'borrower_3'"></app-view-applicant>
    </div>

    <div *ngIf="borrowerCount>=4" [ngClass]="{'show active':currentSection===localSection.borrower_4}" aria-labelledby="borrower-tab" class="tab-pane fade" id="applicant_borrower_4" role="tabpanel">
      <app-view-applicant (eventEmitted)="onEvent($event)" [applicant]="'borrower_4'"></app-view-applicant>
    </div>

    <div *ngIf="canReadLoanOfficer" [ngClass]="{'show active':currentSection===localSection.lo}" aria-labelledby="lo-tab" class="tab-pane fade" id="lo" role="tabpanel">
      <app-view-lo (eventEmitted)="onEvent($event)"></app-view-lo>
    </div>

    <div [ngClass]="{'show active':currentSection===localSection.tools}" aria-labelledby="tools-tab" class="tab-pane fade" id="tools" role="tabpanel">
      <app-view-tools (eventEmitted)="onEvent($event)"></app-view-tools>
    </div>
  </div>
</div>

<app-box *ngIf="!doc&&canReadLoanApp" [close]="false" [type]="'warning'" class="pt-2">
  <strong>No loan application!</strong>
</app-box>
