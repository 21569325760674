import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../models/user';
import {ActivatedRoute, Router} from '@angular/router';
import {FormUtil} from '../../../form-util';
import {CompBaseComponent} from '../../../comp-base.component';
import {Help} from '../../../../models/help';

@Component({
  selector: 'app-view-photo',
  templateUrl: './view-photo.component.html',
})
export class ViewPhotoComponent extends CompBaseComponent implements OnInit, OnDestroy {
  pageTitle = 'Profile Picture';
  user: User;

  constructor(public injector: Injector,
              private router: Router,
              private route: ActivatedRoute) {
    super(injector);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.accountPhoto
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
    this.reloadOnSync();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.mode = 'View';
    this.userService.getUserFromSession(force)
      .then(() => {
        this.user = this.userService.getUserSync();
        this.reloaded = true;
      });
  }

  deleteImage(): void {
    this.userService.deleteAccountPhoto()
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Photo successfully deleted');
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'account'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to delete photo', data);

      });
  }

  replaceImage(): void {
    this.mode = this.mode === 'View' ? 'Update' : 'View';
  }

  uploadImage(): void {
    this.router.navigate(['../upload-photo'], {relativeTo: this.route});
  }

  onCancel(): void {
    this.navigateToAccountSettings();
  }

  onUploadComplete(): void {
    this.userService.refreshUser()
      .then(() => {
        return this.tranService.getCurrentTran(true);
      })
      .then(() => {
          this.eventService.eventEmitter.emit({type: 'Reload', component: 'account'});
          this.navigateToAccountSettings();
        }
      );
  }

  navigateToAccountSettings() {
    this.router.navigate(['../settings'], {relativeTo: this.route});
  }
}
