import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {User} from '../../../models/user';
import {OrgAdminService} from '../../../services/org-admin.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../../services/data.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {AgentService} from '../../../services/agent.service';
import {ModelBase} from '../../../models/model-base';
import {globals} from '../../../shared/globals/globals';
import {FormUtil} from "../../form-util";
import {Organization} from "../../../models/organization";

@Component({
  selector: 'app-org-user',
  template: '',
})
export class OrgUserComponent extends CompBaseComponent implements OnInit {
  @Input() public user: User;
  @Input() userId: number;
  @Input() public item: Organization;
  public org_roles = ModelBase.org_roles;
  public op: string;
  public agentService: AgentService;
  public orgAdminService: OrgAdminService;
  public route: ActivatedRoute;
  public router: Router;
  // form and form controls
  form: FormGroup;
  userType: FormControl;
  first: FormControl;
  last: FormControl;
  email: FormControl;
  nmls: FormControl;
  states: FormControl;
  orgRole: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.agentService = this.injector.get(AgentService);
    this.orgAdminService = this.injector.get(OrgAdminService);
    this.route = this.injector.get(ActivatedRoute);
    this.router = this.injector.get(Router);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  createFormControls(user: User) {
    this.userType = new FormControl('AUX');
    this.first = new FormControl(user.first, Validators.compose([AppValidators.present, Validators.minLength(2)]));
    this.last = new FormControl(user.last, Validators.compose([AppValidators.present, Validators.minLength(2)]));
    this.email = new FormControl(user.email, Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.email)]));
    this.nmls = new FormControl(user.nmls, Validators.compose([AppValidators.present]));
    this.states = new FormControl(user.getStates(), Validators.compose([AppValidators.present]));
    this.orgRole = new FormControl(user.organization_role);
  }

  canEditUser(): boolean {
    const loggedUser = this.userService.getUserSync();
    return (this.op === 'Add' && this.userType.value === 'AUX') || (this.op === 'EditUser' && this.user.isAuxiliary());
  }

  canEditOrgRole(): boolean {
    const loggedUser = this.userService.getUserSync();
    return loggedUser.isAgent() || loggedUser.isAdmin();
  }

  createForm() {
    this.form = new FormGroup({
      userType: this.userType,
      first: this.first,
      last: this.last,
      email: this.email,
      nmls: this.nmls,
      states: this.states,
      orgRole: this.orgRole
    });
  }

  // get the appropriate service for the user
  getService(): OrgAdminService | AgentService {
    const user = this.userService.getUserSync();
    if (user.isAgent()) {
      return this.agentService;
    }
    if (user.isOrgAdmin()) {
      return this.orgAdminService;
    }
  }

  addFreeLO(): void {
    if (!this.form.valid) {
      return;
    }
    this.states.setValue(this.states.value.toUpperCase());
    const statesCured = this.states.value.toUpperCase().replace(/,/g, ' ').trim().split(/\s+/);
    const errorMessages = [];
    statesCured.forEach((state) => {
      if (!globals.pattern.state.test(state)) {
        const errorMessage = `State ${state} is invalid`;
        errorMessages.push(errorMessage);
      }
    });
    if (errorMessages.length > 0) {
      FormUtil.genErrorMessage(this.sharedService, errorMessages.join('. '), null);
      return;
    }

    const payload = Object.assign({}, this.form.value);
    payload.organization_id = this.item.id;
    payload.roles = ModelBase.ROLES.AUX;
    payload.organization_role = Organization.ORG_ROLES.NONE;
    this.getService().createAccount(payload)
        .then(() => {
          return this.userService.getDefaultOrganization(true);
        })
        .then(() => {
          FormUtil.genSuccessMessage(this.sharedService, 'New Free Loan Officer account successfully created');
          this.eventEmitted.emit({type: 'Reload'});
        })
        .catch((data) => {
          FormUtil.genErrorMessage(this.sharedService, 'Failed to create user', data);
        });
  }

  addLO(): void {
    if (!this.nmls.valid) {
      return;
    }
    const payload = {
      organization_id: this.item.id,
      nmls: this.nmls.value
    };
    this.orgAdminService.addUser(payload)
        .then(() => {
          return this.userService.getDefaultOrganization(true);
        })
        .then(() => {
          FormUtil.genSuccessMessage(this.sharedService, 'Existing Loan Officer successfully added to organization');
          this.eventEmitted.emit({type: 'Reload'});
        })
        .catch((data) => {
          FormUtil.genErrorMessage(this.sharedService, 'Failed to add existing user to organization', data);
        });
  }
}
