<div *ngIf="reloaded && mcr">
  <app-view-segment-mcr (eventEmitted)="onEvent($event)" *ngIf="mode==='View'" [tran]="tran" [mcr]="mcr">
  </app-view-segment-mcr>

  <app-edit-segment-mcr (eventEmitted)="onEvent($event)" *ngIf="mode==='Edit'" [tran]="tran" [mcr]="mcr">
  </app-edit-segment-mcr>
</div>

<div *ngIf="reloaded && !mcr">
  <app-add-tran-mcr (eventEmitted)="onEvent($event)" [tran]="tran" [mcr]="mcr">
  </app-add-tran-mcr>
</div>
