import {Component, Injector, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {globals} from "../../../../shared/globals/globals";
import {GenerateDocumentBaseComponent} from "../generate-document-base.component";

@Component({
  selector: 'app-cfa-base',
  template: '',
})

export class CFABaseComponent extends GenerateDocumentBaseComponent implements OnInit {
  @Input() section: string;
  public router: Router;
  public readonly tabKeys = globals.section;
  // @ts-ignore
  public readonly cfaStock = {
    "general": [
      {
        "type_ex": "General",
        "key": "borrower_name",
        "note": "",
        "item_desc": "Borrower Name",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "General",
        "key": "business_name",
        "note": "",
        "item_desc": "Business Name",
        "value": "",
        "type": "string",
        "stock": "true"
      }
    ],
    "individual": [
      {
        "type_ex": "Year",
        "key": "year1",
        "note": "",
        "item_desc": "Year",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "income1",
        "note": "",
        "item_desc": "W2 Income",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_b_interest1",
        "note": "",
        "item_desc": "B. Interest Income from Self-Employment",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_b_div1",
        "note": "",
        "item_desc": "B. Dividends from Self-Employment",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "sched_c_net_profit1",
        "note": "",
        "item_desc": "C. Profit or Loss as Sole Proprietor",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "sched_c_nr_other1",
        "note": "",
        "item_desc": "C. Nonrecurring Other Loss/Expenses",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_c_depletion1",
        "note": "",
        "item_desc": "C. Depletion",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_c_depreciation1",
        "note": "",
        "item_desc": "C. Depreciation",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "sched_c_travel1",
        "note": "",
        "item_desc": "C. Travel and Meal Expenses",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_c_home1",
        "note": "",
        "item_desc": "C. Business Use of Home",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_c_amort1",
        "note": "",
        "item_desc": "C. Amortization/Casualty Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_d_cap_gain1",
        "note": "",
        "item_desc": "D. Capital Gains and Losses",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_e_rolyalty1",
        "note": "",
        "item_desc": "E. Supplemental Income - Royalties",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "sched_e_expense1",
        "note": "",
        "item_desc": "E. Supplemental Income - Total Expense",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_e_depletion1",
        "note": "",
        "item_desc": "E. Supplemental Income - Depletion",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "sched_f_farm_profit1",
        "note": "",
        "item_desc": "F. Net Farm Profit/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_f_coop_ccc1",
        "note": "",
        "item_desc": "F. Non-Taxable Co-Op and CCC Payments",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "sched_f_nr_other1",
        "note": "",
        "item_desc": "F. Nonrecurring Other Income/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_f_depreciation1",
        "note": "",
        "item_desc": "F. Depreciation",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_f_amort1",
        "note": "",
        "item_desc": "F. Amortization/Casualty Loss/Depletion",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_f_home1",
        "note": "",
        "item_desc": "F. Business Use of Home",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Year",
        "key": "year2",
        "note": "",
        "item_desc": "Year",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "income2",
        "note": "",
        "item_desc": "W2 Income",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_b_interest2",
        "note": "",
        "item_desc": "B. Interest Income from Self-Employment",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_b_div2",
        "note": "",
        "item_desc": "B. Dividends from Self-Employment",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "sched_c_net_profit2",
        "note": "",
        "item_desc": "C. Profit or Loss as Sole Proprietor",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "sched_c_nr_other2",
        "note": "",
        "item_desc": "C. Nonrecurring Other Loss/Expenses",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_c_depletion2",
        "note": "",
        "item_desc": "C. Depletion",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_c_depreciation2",
        "note": "",
        "item_desc": "C. Depreciation",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "sched_c_travel2",
        "note": "",
        "item_desc": "C. Travel and Meal Expenses",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_c_home2",
        "note": "",
        "item_desc": "C. Business Use of Home",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_c_amort2",
        "note": "",
        "item_desc": "C. Amortization/Casualty Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_d_cap_gain2",
        "note": "",
        "item_desc": "D. Capital Gains and Losses",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_e_rolyalty2",
        "note": "",
        "item_desc": "E. Supplemental Income - Royalties",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "sched_e_expense2",
        "note": "",
        "item_desc": "E. Supplemental Income - Total Expense",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_e_depletion2",
        "note": "",
        "item_desc": "E. Supplemental Income - Depletion",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "sched_f_farm_profit2",
        "note": "",
        "item_desc": "F. Net Farm Profit/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_f_coop_ccc2",
        "note": "",
        "item_desc": "F. Non-Taxable Co-Op and CCC Payments",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "sched_f_nr_other2",
        "note": "",
        "item_desc": "F. Nonrecurring Other Income/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_f_depreciation2",
        "note": "",
        "item_desc": "F. Depreciation",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_f_amort2",
        "note": "",
        "item_desc": "F. Amortization/Casualty Loss/Depletion",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "sched_f_home2",
        "note": "",
        "item_desc": "F. Business Use of Home",
        "value": "",
        "type": "currency",
        "stock": "true"
      }
    ],
    "partnership": [
      {
        "type_ex": "Year",
        "key": "partner_year1",
        "note": "",
        "item_desc": "Year",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Percentage",
        "key": "partnership_percentage_year1",
        "note": "",
        "item_desc": "Partnership Percentage Ownership",
        "value": "",
        "type": "percent",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "k1_1065_income_share1",
        "note": "",
        "item_desc": "Ordinary Income/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "k1_1065_rental_share1",
        "note": "",
        "item_desc": "Net Real Estate Rental/Other Income",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "k1_1065_guaranteed_share1",
        "note": "",
        "item_desc": "Guaranteed Payments",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "form_1065_income_adjustment1",
        "note": "",
        "item_desc": "Orignary Income/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "form_1065_nr_adjustment1",
        "note": "",
        "item_desc": "Nonrecurring Other Income/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "form_1065_depreciation1",
        "note": "",
        "item_desc": "Depreciation",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "form_1065_depletion1",
        "note": "",
        "item_desc": "Depletion",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "form_1065_amortization1",
        "note": "",
        "item_desc": "Amortization/Casualty Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "form_1065_note_payable1",
        "note": "",
        "item_desc": "Mortgages or Notes Payable",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "form_1065_travel1",
        "note": "",
        "item_desc": "Travel and Entertainment",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Year",
        "key": "partner_year2",
        "note": "",
        "item_desc": "Year",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Percentage",
        "key": "partnership_percentage_year2",
        "note": "",
        "item_desc": "Partnership Percentage Ownership",
        "value": "",
        "type": "percent",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "k1_1065_income_share2",
        "note": "",
        "item_desc": "Ordinary Income/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "k1_1065_rental_share2",
        "note": "",
        "item_desc": "Net Real Estate Rental/Other Income",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "k1_1065_guarnanteed_share2",
        "note": "",
        "item_desc": "Guaranteed Payments",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "form_1065_income_adjustment2",
        "note": "",
        "item_desc": "Orignary Income/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "form_1065_nr_adjustment2",
        "note": "",
        "item_desc": "Nonrecurring Other Income/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "form_1065_depreciation2",
        "note": "",
        "item_desc": "Depreciation",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "form_1065_depletion2",
        "note": "",
        "item_desc": "Depletion",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "form_1065_amortization2",
        "note": "",
        "item_desc": "Amortization/Casualty Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "form_1065_note_payable2",
        "note": "",
        "item_desc": "Mortgages or Notes Payable",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "form_1065_travel2",
        "note": "",
        "item_desc": "Travel and Entertainment",
        "value": "",
        "type": "currency",
        "stock": "true"
      }
    ],
    "s_corp": [
      {
        "type_ex": "Year",
        "key": "s_corp_year1",
        "note": "",
        "item_desc": "Year",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Percentage",
        "key": "s_corp_percentage_year1",
        "note": "",
        "item_desc": "Percentage Ownership",
        "value": "",
        "type": "percent",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "s_corp_income1",
        "note": "",
        "item_desc":  "Ordinary Income",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "s_corp_rental1",
        "note": "",
        "item_desc": "Rental Income/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "s_corp_nr1",
        "note": "",
        "item_desc": "Nonrecurring Income/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "s_corp_depreciation1",
        "note": "",
        "item_desc": "Depreciation",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "s_corp_depletion1",
        "note": "",
        "item_desc": "Depletion",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "s_corp_amortization1",
        "note": "",
        "item_desc": "Amortization/Casualty Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "s_corp_note_payable1",
        "note": "",
        "item_desc": "Mortgages or Notes Payable",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "s_corp_travel1",
        "note": "",
        "item_desc": "Travel and Entertainment",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Year",
        "key": "s_corp_year2",
        "note": "",
        "item_desc": "Year",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Percentage",
        "key": "s_corp_percentage_year2",
        "note": "",
        "item_desc": "Percentage Ownership",
        "value": "",
        "type": "percent",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "s_corp_income2",
        "note": "",
        "item_desc": "Ordinary Income",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "s_corp_rental2",
        "note": "",
        "item_desc": "Rental Income/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "s_corp_nr2",
        "note": "",
        "item_desc": "Nonrecurring Income/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "s_corp_depreciation2",
        "note": "",
        "item_desc": "Depreciation",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "s_corp_depletion2",
        "note": "",
        "item_desc": "Depletion",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "s_corp_amortization2",
        "note": "",
        "item_desc": "Amortization/Casualty Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "s_corp_note_payable2",
        "note": "",
        "item_desc": "Mortgages or Notes Payable",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "s_corp_travel2",
        "note": "",
        "item_desc": "Travel and Entertainment",
        "value": "",
        "type": "currency",
        "stock": "true"
      }
    ],
    "c_corp": [
      {
        "type_ex": "Year",
        "key": "c_corp_year1",
        "note": "",
        "item_desc": "Year",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "c_corp_income1",
        "note": "",
        "item_desc": "Taxable Income",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Tax",
        "key": "c_corp_tax1",
        "note": "",
        "item_desc": "Tax",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "c_corp_nr1",
        "note": "",
        "item_desc": "Nonrecurring Gain/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "c_corp_nro1",
        "note": "",
        "item_desc": "Nonrecurring Other Income/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "c_corp_depreciation1",
        "note": "",
        "item_desc": "Depreciation",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "c_corp_depletion1",
        "note": "",
        "item_desc": "Depletion",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "c_corp_amortization1",
        "note": "",
        "item_desc": "Amortization/Casualty Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "c_corp_operating_loss1",
        "note": "",
        "item_desc": "Net Operating Loss/Deductions",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "c_corp_note_payable1",
        "note": "",
        "item_desc": "Mortgages or Notes Payable",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "c_corp_travel1",
        "note": "",
        "item_desc": "Travel and Entertainment",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "c_corp_divs_paid1",
        "note": "",
        "item_desc": "Dividends Paid to Borrower",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Year",
        "key": "c_corp_year2",
        "note": "",
        "item_desc": "Year",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "c_corp_income2",
        "note": "",
        "item_desc": "Taxable Income",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Tax",
        "key": "c_corp_tax2",
        "note": "",
        "item_desc": "Tax",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "c_corp_nr2",
        "note": "",
        "item_desc": "Nonrecurring Gain/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "LossOrGain",
        "key": "c_corp_nro2",
        "note": "",
        "item_desc": "Nonrecurring Other Income/Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "c_corp_depreciation2",
        "note": "",
        "item_desc": "Depreciation",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "c_corp_depletion2",
        "note": "",
        "item_desc": "Depletion",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "c_corp_amortization2",
        "note": "",
        "item_desc": "Amortization/Casualty Loss",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Income",
        "key": "c_corp_operating_loss2",
        "note": "",
        "item_desc": "Net Operating Loss/Deductions",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "c_corp_note_payable2",
        "note": "",
        "item_desc": "Mortgages or Notes Payable",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "c_corp_travel2",
        "note": "",
        "item_desc": "Travel and Entertainment",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Expense",
        "key": "c_corp_divs_paid2",
        "note": "",
        "item_desc": "Dividends Paid to Borrower",
        "value": "",
        "type": "currency",
        "stock": "true"
      }
    ]
  }
  public sections = [];

  constructor(public injector: Injector) {
    super(injector);
    this.router = this.injector.get(Router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.formType = 'FNMA_CFA';
    this.sections = Object.keys(this.cfaStock);
    this.savedDoc = this.doc.content3[this.formType] || {};
  }

  getSectionTitle(): string {
    switch (this.section) {
      case globals.section.general:
        return 'General';
      case globals.section.individual:
        return 'Individual'
      case globals.section.partnership:
        return 'Partnership'
      case globals.section.s_corp:
        return 'Simple/S Corp'
      case globals.section.c_corp:
        return 'Regular/C Corp'
    }
  }
  getLongSectionTitle(): string {
    switch (this.section) {
      case globals.section.general:
        return 'General';
      case globals.section.individual:
        return 'Individual - IRS Form 1040'
      case globals.section.partnership:
        return 'Partnership - IRS Form 1065'
      case globals.section.s_corp:
        return 'Simple/S Corp - IRS Form 1120S'
      case globals.section.c_corp:
        return 'Regular/C Corp - IRS Form 1120'
    }
  }
}
