import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Help} from '../../../models/help';
import {Tran} from '../../../models/tran';
import {ProfessionalProfile} from '../../../models/professional-profile';
import {User} from '../../../models/user';
import {HomeComponent} from '../home.component';

@Component({
  selector: 'app-lo-info',
  templateUrl: './lo-info.component.html',
})
export class LOInfoComponent extends HomeComponent implements OnInit, OnDestroy {
  public pageTitle = 'Loan Owner\'s Info';
  public tran: Tran;
  public user: User;
  public profile: ProfessionalProfile;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
    this.reloadOnSync();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.user = this.userService.getUserSync();
    this.tran = this.tranService.getCurrentTranSync();
    this.profile = this.tran ? this.tran.owner_professional_profile : null;
    this.reloadHome()
      .then(() => {
        setTimeout(() => {
          this.sharedService.emitChange({
            type: 'ChangePage',
            title: this.pageTitle,
            help: Help.helpKeys.homeOfficer
          });
          this.reloaded = true;
        }, 0, this);
      });
  }
}
