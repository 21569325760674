<div class="art-bg w-100">
  <div class="container fraunces text-center fs-4em art-blue" [ngClass]="{'fs-4em': isMobile, 'fs-10em': !isMobile}">
    Co.llaborate
  </div>

  <div class="row justify-content-md-center art-blue">
    <div class="col-lg-8 pr-font-x-large fraunces text-center" [ngClass]="{'fs-2em': isMobile, 'fs-3em': !isMobile}">
      <div class="p-2">
        We believe that a collaborative platform is key for the success of the loan transaction.
        That's why we developed PrudentCO Online LOS (POLOS): a collaborative, user friendly, and efficient environment
        for the loan officer, borrower, processor, and real estate agent.
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col mx-auto text-center">
      <img src="assets/img/collaborative-work.png" alt="Collaborative work" style="width:512px;"><br>
    </div>
  </div>

  <div class="row justify-content-md-center">
    <div class="col-lg-4 text-center art-blue">
      <div class="p-2">
        <h3 class="">What should you expect to find in POLOS?</h3>
      </div>
    </div>
  </div>

  <div class="row justify-content-md-center">
    <div class="col-lg-4">
      <div class="m-4 p-3 art-box">
        <div class="fw-bold pr-font-x-large p-3  text-center">A collaborative environment</div>
        <div class="pr-font-18 pt-3 pb-4 text-center">
          POLOS was designed from the ground up to enhance collaboration among loan stakeholders, including the loan officer, borrower, processor, and real estate agent.
        </div>
      </div>
    </div>

    <div class="col-sm-1">&nbsp;</div>

    <div class="col-lg-4">
      <div class="m-4 p-3 art-box">
        <div class="fw-bold pr-font-x-large p-3  text-center">Loan Origination Platform</div>
        <div class="pr-font-18 pt-3 ps-3 pb-4 text-center">
          The LOS Platform: POLOS features a comprehensive implementation of MISMO 3.4, fully supporting all loan codes and values as defined by MISMO.
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-md-center mt-4">
    <div class="col-lg-4">
      <div class="m-4 p-3 art-box">
        <div class="fw-bold pr-font-x-large ps-3 pb-3 pt-4 text-center">Workflow and Compliance</div>
        <div class="pr-font-18 pt-3 ps-3 pb-4 text-center">
          POLOS features a workflow engine that works in conjunction with its compliance engine, guiding loan officers on the tasks that require their attention.
        </div>
      </div>
    </div>

    <div class="col-lg-1">&nbsp;</div>

    <div class="col-lg-4">
      <div class="m-4 p-3 art-box">
        <div class="fw-bold pr-font-x-large ps-3 pb-3 pt-4 text-center">Document Creation</div>
        <div class="pr-font-18 pt-3 ps-3 pb-4 text-center">
          Our proprietary technology allows us to implement any PDF generation in a short time. We support Fannie Mae's URLA and Federal and State forms.
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-md-center mt-4">
    <div class="col-lg-4">
      <div class="m-4 p-3 art-box">
        <div class="fw-bold pr-font-x-large ps-3 pb-3 pt-4 text-center">MCR Wizard</div>
        <div class="pr-font-18 pt-3 ps-3 pb-4  text-center">
          Our MCR Wizard offers the fastest and most efficient generation of Mortgage Call Reports, and it functions seamlessly even if your loans are not stored in POLOS.
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-md-center" style="margin-top:24px;">
    <div class="col-lg-4 text-center m-2 art-blue">
      <h3 class="">And Many Other Features:</h3>
    </div>
  </div>

  <div class="container my-4">
    <div id="carouselExampleDark" class="carousel carousel-dark slide">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 0"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3" aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="4" aria-label="Slide 4"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="5" aria-label="Slide 5"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="6" aria-label="Slide 6"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="7" aria-label="Slide 7"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="8" aria-label="Slide 8"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="9" aria-label="Slide 9"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="10000">
          <!-- Slide 0 -->
          <div style="min-height: 160px;">
            <div class="carousel-caption">
              <h4 class="fw-bold">ezUploads</h4>
              <p class="pr-font-18">
                Securely collect documents from anybody
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="2000">
          <!-- Slide 1 -->
          <div style="min-height: 160px;">
            <div class="carousel-caption d-block">
              <h4 class="fw-bold">User Experience</h4>
              <p class="pr-font-18">
                Quick, smart, and responsive
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="2000">
          <!-- Slide 2 -->
          <div style="min-height: 160px;">
            <div class="carousel-caption d-block">
              <h4 class="fw-bold">Error Checking</h4>
              <p class="pr-font-18">
                Hundreds of error checks on each loan
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="2000">
          <div style="min-height: 160px;">
            <!-- Slide 3 -->
            <div class="carousel-caption d-block">
              <h4 class="fw-bold">Ancillary Services</h4>
              <p class="pr-font-18">POLOS integrates with 30 credit reporting agencies
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <!-- Slide 4 -->
          <div style="min-height: 160px;">
            <div class="carousel-caption d-block">
              <h4 class="fw-bold">Document Repository</h4>
              <p class="pr-font-18">
                Slick and quick document management
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <!-- Slide 5 -->
          <div style="min-height: 160px;">
            <div class="carousel-caption d-block">
              <h4 class="fw-bold">Special Forms</h4>
              <p class="pr-font-18">
                Fee Worksheet, Loan Estimate, and others
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <!-- Slide 6 -->
          <div style="min-height: 160px;">
            <div class="carousel-caption d-block">
              <h4 class="fw-bold">Timeline</h4>
              <p class="pr-font-18">
                The loan process displays as a timeline visual
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <!-- Slide 7 -->
          <div style="min-height: 160px;">
            <div class="carousel-caption d-block">
              <h4 class="fw-bold">Notification Services</h4>
              <p class="pr-font-18">
                Send email, system, and text messages
              </p>
            </div>
          </div>
        </div>

        <div class="carousel-item">
          <!-- Slide 8 -->
          <div style="min-height: 160px;">
            <div class="carousel-caption d-block">
              <h4 class="fw-bold">Contextual Help</h4>
              <p class="pr-font-18">
                Contextual help is available everywhere
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <!-- Slide 9 -->
          <div style="min-height: 160px;">
            <div class="carousel-caption d-block">
              <h4 class="fw-bold">Access Control</h4>
              <p class="pr-font-18">
                Granular access control to loan resources
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        &nbsp;
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>

  <div class="row justify-content-md-center">
    <div class="col-lg-4 text-center m-2 art-blue">
      <h3 class="">What our users say:</h3>
    </div>
  </div>

  <div class="row display-flex" [ngStyle]="{margin: 'auto', width: isMobile ? '100%' : '80%'}">
    <div class="col-lg-4">
      <div class="p-5 m-3 bg-white pr-card" style="position: relative;font-family: Helvetica; font-size: 19px;font-style: normal;text-align:center">
        <span style="font-weight: 700;">&ldquo;</span>I recently switched from Calyx Point to the POLOS loan origination system.
        As an independent loan broker, I really like the fact that it’s designed for me, the loan originator.
        All the features and options are there to help me organize my loan application.
        It’s simple to set up and to use.
        It took me a while to make the switch because I’d been using Calyx Point for so long, and I wish I had done it sooner!
        <span style="font-weight: 700;">&rdquo;</span>
        <div class="mt-4">
          <img src="assets/img/monica-portrait.jpg" alt="Image of Bruce Peters;" style="width:75px; border-radius: 50%;"><br>
          <div class="mt-4" style="font-size: 18px;font-weight: bold;">
            <div>Monica Fullmer, Mortgage Broker</div>
            <div>NMLS 1431482</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4">
      <div class="p-5 m-3 h-100 bg-white pr-card">
        <div style="position:relative;height:100%; font-family: Helvetica; font-size: 19px; font-style: normal;text-align:center">
          <span style="font-weight: 700;">&ldquo;</span>I recently signed up for PrudentCO's ezUploads (secure upload), and I must say,
          "it's impressive!" It has transformed the way I handle confidential documents,
          providing top-notch security and ease of use. PrudentCO has truly made document management a breeze!<span style="font-weight: 700;">&rdquo;</span>
          <div class="mt-4 w-100" [ngClass]="{'at-parent-bottom': !isMobile}">
            <img src="assets/img/jared-schaffer.jpg" alt="Image of Jared Schaffer;" style="max-width:75px; border-radius: 50%;">
            <div class="mt-4 w-100" style="font-size: 18px;font-weight: bold;text-align: center">
              <div>Jared Schaffer, The Maven Group</div>
              <div>Boulder, CO</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4">
      <div class="p-5 m-3 bg-white h-100 pr-card">
        <div style="position:relative;height:100%; font-family: Helvetica; font-size: 19px;padding-top: 10px; font-style: normal;text-align:center">
          <span style="font-weight: 700;">&ldquo;</span>I wanted to say how impressed and pleased I am with POLOS and its ezUploads (secure upload) feature.
          It is exactly what I need in for my business. Nice job.<span style="font-weight: 700;">&rdquo;</span>
          <div class="mt-4 w-100" [ngClass]="{'at-parent-bottom': !isMobile}">
            <img src="assets/img/bruce-peters.jpg" alt="Image of Bruce Peters;" style="width:75px; border-radius: 50%;"><br>
            <div class="mt-4 w-100" style="font-size: 18px;font-weight: bold;text-align: center">
              <div>Bruce Peters, Mortgage Broker</div>
              <div>NMLS 349175</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-md-center">
    <div class="col-lg-4 text-center mt-5 mb-5">
      <button (click)="navigateToShop($event)" class="btn btn-lg btn-primary  bg-dark-blue" style="font-size: 24px;">Take the first step today!</button>
    </div>
  </div>
</div>
