import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {MessageService} from '../../../services/message.service';
import {Message} from '../../../models/message';
import {ActivatedRoute} from '@angular/router';
import {EmailComponent} from "../email/email.component";

@Component({
  selector: 'app-view-emails',
  templateUrl: './view-emails.component.html',
  styleUrls: ['../comm.scss']
})
export class ViewEmailsComponent extends EmailComponent implements OnInit, OnDestroy {
  public messageService: MessageService;
  public currentSeg: string;
  public messages: Array<Message>;

  constructor(public injector: Injector, private route: ActivatedRoute) {
    super(injector);
    this.messageService = this.injector.get(MessageService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setSeg(null, this.localSeg.received);
    this.sharedService.eventEmitted.subscribe((event) => {
      if (event.type === 'ToggleNestedMenu') {
        this.setMenuOpen(!this.isOpen);
      }
    });
    this.reload(true);
    this.reloadOnSync();
  }

  onEvent(event: any) {
    if (event.type === 'ChangeMode' && event.mode === 'Add') {
      this.setSeg(null, this.localSeg.newMessage);
      return;
    }

    if (event.type === 'Reload') {
      this.reload();
      this.setSeg(null, this.localSeg.received);
      return;
    }
  }

  reload(force?: boolean): void {
    if (typeof force === 'undefined') {
      force = true;
    }
    this.reloaded = false;
    this.tranService.getCurrentTranId()
      .then((tranId) => {
        return this.messageService.getMessages(force);
      })
      .then((messages) => {
        this.setSeg(null, this.localSeg.newUser)
        this.messages = messages;
        setTimeout(() => {
          this.reloaded = true;
        });
      });
  }
}
