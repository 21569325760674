import {Component, Injector, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {globals} from "../../../../shared/globals/globals";
import {GenerateDocumentBaseComponent} from "../generate-document-base.component";

@Component({
  selector: 'app-loan-estimate-base',
  template: '',
})

export class LoanEstimateBaseComponent extends GenerateDocumentBaseComponent implements OnInit {
  @Input() section: string;
  public router: Router;
  public readonly tabKeys = globals.section;
  // @ts-ignore
  public readonly loanEstimateStock = {
    "general": [
      {
        "type_ex": "General",
        "key": "date_issued",
        "note": "",
        "item_desc": "Date Issued",
        "value": "",
        "type": "date",
        "stock": "true"
      },
      {
        "type_ex": "General",
        "key": "prepaid_days",
        "note": "",
        "item_desc": "Number of Prepaid Days",
        "value": "15",
        "type": "integer",
        "stock": "true"
      }
    ],
    "transaction_details": [
      {
        "type_ex": "Details",
        "key": "purchase_price",
        "note": "",
        "item_desc": "Property Sale Price",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Details",
        "key": "deposit",
        "note": "",
        "item_desc": "Deposit (Earnest Money)",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Details",
        "key": "seller_credit",
        "note": "",
        "item_desc": "Seller Credit",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Details",
        "key": "lender_credit",
        "note": "",
        "item_desc": "Lender Credit",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Details",
        "key": "funding_financed_amt",
        "note": "",
        "item_desc": "Financed Loan Costs",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Details",
        "key": "funds_for_borrower",
        "note": "",
        "item_desc": "Funds for Borrower",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Details",
        "key": "adjustments",
        "note": "",
        "item_desc": "Adjustments",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
    ],
    "lender": [
      {
        "type_ex": "Lender",
        "key": "lender",
        "note": "",
        "item_desc": "Lender",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Lender",
        "key": "lender_nmls",
        "note": "",
        "item_desc": "Lender NMLS",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Lender",
        "key": "lender_name",
        "note": "",
        "item_desc": "Lender Loan Officer Name",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Lender",
        "key": "lender_nmls_license",
        "note": "",
        "item_desc": "Lender Loan Officer NMLS",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Lender",
        "key": "lender_email",
        "note": "",
        "item_desc": "Lender Loan Officer Email",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Lender",
        "key": "lender_phone",
        "note": "",
        "item_desc": "Lender Loan Officer Phone",
        "value": "",
        "type": "string",
        "stock": "true"
      },
    ],
    "other_payments": [
      {
        "type_ex": "Other Payments",
        "key": "other_payments_per_month",
        "note": "",
        "item_desc": "Other Payments per Month",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
    ],
    "loan_terms": [
      {
        "type_ex": "Loan Terms",
        "key": "loan_identifier",
        "note": "",
        "item_desc": "Loan Identifier",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_interest_rate",
        "note": "",
        "item_desc": "Initial/Fixed Interest Rate",
        "value": "",
        "type": "percent",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_duration_months",
        "note": "",
        "item_desc": "Loan Duration in Months",
        "value": "",
        "type": "integer",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_amount",
        "note": "",
        "item_desc": "Loan Amount",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_down_payment_amount",
        "note": "",
        "item_desc": "Down Payment",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_product",
        "note": "",
        "item_desc": "Loan Product",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_io_months",
        "note": "",
        "item_desc": "Number of Months for Interest Only Payments",
        "value": "",
        "type": "integer",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_rate_fixed_months",
        "note": "",
        "item_desc": "Number of Months at Initial Interest Rate (ARM)",
        "value": "",
        "type": "integer",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_subsequent_adjustment_months_no",
        "note": "",
        "item_desc": "Interval Months Between Subsequent Rate Adjustments (ARM)",
        "value": "",
        "type": "integer",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_first_adjustment_cap_pct",
        "note": "",
        "item_desc": "Cap on percentage for First Rate Adjustment (ARM)",
        "value": "",
        "type": "percent",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_periodic_cap_pct",
        "note": "",
        "item_desc": "Cap on percentage for Subsequent Rate Adjustments (ARM)",
        "value": "",
        "type": "percent",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_lifetime_cap_pct",
        "note": "",
        "item_desc": "Maximum Lifetime Interest Rate/Percent (ARM)",
        "value": "",
        "type": "percent",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_rate_minimum_pct",
        "note": "",
        "item_desc": "Minimum Lifetime Percentage Rate (ARM)",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_balloon_payment",
        "note": "",
        "item_desc": "Balloon Payment",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "index_margin",
        "note": "",
        "item_desc": "Index + Margin (ARM)",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "tip_rate",
        "note": "",
        "item_desc": "TIP Rate Estimate (ARM)",
        "value": "",
        "type": "percent",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_max_monthly_payment",
        "note": "",
        "item_desc": "Maximum Monthly P&I Payment (ARM)",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Optional Payments",
        "key": "optional_payments",
        "note": "",
        "item_desc": "Optional Payments",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Seasonal Payments",
        "key": "seasonal_payments",
        "note": "",
        "item_desc": "Seasonal Payments",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "rate_locked",
        "note": "",
        "item_desc": "Rate Locked",
        "value": "",
        "type": "boolean",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "assumable_yes_no",
        "note": "",
        "item_desc": "Loan Assumable",
        "value": "",
        "type": "boolean",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_servicer_yes_no",
        "note": "",
        "item_desc": "Loan Serviced by Lender",
        "value": "",
        "type": "boolean",
        "stock": "true"
      },
    ],
    // Everything below here is for closing costs, and should be same as for Broker Fee Worksheet
    "title":[
      {
        "type_ex": "Shop",
        "key": "title_lender_policy_fee",
        "note": "",
        "item_desc": "Title - Lender's Title Policy Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Shop",
        "key": "title_settlement_fee",
        "note": "",
        "item_desc": "Title - Settlement Agent Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Shop",
        "key": "title_search_fee",
        "note": "",
        "item_desc": "Title - Title Search Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Shop",
        "key": "title_courier_fee",
        "note": "",
        "item_desc": "Title - Courier Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
    ],
    "insurance":[
      {
        "type_ex": "Hazard",
        "key": "hazard_insurance_per_year",
        "note": "",
        "item_desc": "Hazard Insurance per Year",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Hazard",
        "key": "hazard_insurance_months",
        "note": "",
        "item_desc": "Months of hazard insurance prepaid/escrowed",
        "value": "2",
        "type": "integer",
        "stock": "true"
      },
      {
        "type_ex": "Insurance",
        "key": "supplemental_insurance_per_year",
        "note": "",
        "item_desc": "Supplemental Insurance Payment per Month",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "MI",
        "key": "mi_insurance_rate",
        "note": "",
        "item_desc": "MI Premium Rate Percentage per Year",
        "value": "",
        "type": "percent",
        "stock": "true"
      }
    ],
    "taxes_and_fees": [
      {
        "type_ex": "Property Tax",
        "key": "property_tax_per_year",
        "note": "",
        "item_desc": "Property tax per year",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Property Tax",
        "key": "property_tax_months",
        "note": "",
        "item_desc": "Months of property tax prepaid/escrowed",
        "value": "6",
        "type": "integer",
        "stock": "true"
      },
      {
        "type_ex": "Taxes and Other Government Fees",
        "key": "recording_fee",
        "note": "",
        "item_desc": "Recording Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Taxes and Other Government Fees",
        "key": "transfer_fee",
        "note": "",
        "item_desc": "Transfer Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
    ],
    "origination": [
      {
        "type_ex": "Origination",
        "key": "points",
        "note": "",
        "item_desc": "Origination Fee (Points)",
        "value": "",
        "type": "percent",
        "stock": "true"
      },
      {
        "type_ex": "Origination",
        "key": "loan_application_fee",
        "note": "",
        "item_desc": "Application Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Origination",
        "key": "processing_fee",
        "note": "",
        "item_desc": "Processing Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Origination",
        "key": "lender_underwriting",
        "note": "",
        "item_desc": "Lender Underwriting Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "No Shop",
        "key": "appraisal_fee",
        "note": "",
        "item_desc": "Appraisal Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "No Shop",
        "key": "credit_report_fee",
        "note": "",
        "item_desc": "Credit Report Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "No Shop",
        "key": "flood_fee",
        "note": "",
        "item_desc": "Flood Determination Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "No Shop",
        "key": "lender_tax_research_fee",
        "note": "",
        "item_desc": "Tax Status Research Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Shop",
        "key": "inspection_fee",
        "note": "",
        "item_desc": "Inspection Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Shop",
        "key": "survey_fee",
        "note": "",
        "item_desc": "Survey Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
    ],
    "other": [
      {
        "type_ex": "Other",
        "key": "homeowner_title_policy",
        "note": "",
        "item_desc": "Title -- Owner Title Policy (optional)",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
    ],
    "escrow": [
      {
        "type_ex": "Escrow",
        "key": "escrow_hazard_insurance",
        "note": "",
        "item_desc": "Escrow - Homeowner Insurance",
        "value": "",
        "type": "boolean",
        "stock": "true"
      },
      {
        "type_ex": "Escrow",
        "key": "escrow_property_taxes",
        "note": "",
        "item_desc": "Escrow - Property Taxes",
        "value": "",
        "type": "boolean",
        "stock": "true"
      },
    ],
    "prepaids": [ ]
  }
  public sections = [];

  constructor(public injector: Injector) {
    super(injector);
    this.router = this.injector.get(Router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.formType = 'CFPB_LE';
    this.sections = Object.keys(this.loanEstimateStock);
    this.savedDoc = this.doc.content3[this.formType] || {};
  }

  getSectionTitle(): string {
    switch (this.section) {
      case globals.section.general:
        return 'General';
      case globals.section.transaction_details:
        return 'Transaction Details'
      case globals.section.loan_terms:
        return 'Loan Details'
      case globals.section.lender:
        return 'Lender'
      case globals.section.other_payments:
        return 'Other Payments'
      case globals.section.origination:
        return 'Origination'
      case globals.section.other:
        return 'Other Fees'
      case globals.section.title:
        return 'Title'
      case globals.section.insurance:
        return 'Insurance'
      case globals.section.taxes_and_fees:
        return 'Taxes & Fees'
      case globals.section.escrow:
        return 'Escrow'
    }
  }
}
