import {Component, Injector, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import moment from 'moment';
import {globals} from '../../../../../../shared/globals/globals';
import {CADocBaseComponent} from "../../ca-doc-base.component";
import {FormUtil} from "../../../../../form-util";

@Component({
  selector: 'app-edit-segment-ca-doc',
  templateUrl: './edit-segment-ca-doc.component.html',
})
export class EditSegmentCADocComponent extends CADocBaseComponent implements OnInit {
  public txSummary: any;
  public minDate = globals.date.minTranStartDate;
  public maxDate = globals.date.maxTranEndDate;
  public inputArr: Array<any>;
  public form: FormGroup;
  ccArray: FormArray;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(): void {
    this.reloaded = false;
    this.createFormControls();
    this.createForm();
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  createFormControls(): void {
    this.ccArray = new FormArray<any>([]);
    if (!this.savedDoc[this.section]) {
      this.savedDoc[this.section] = this[this.formType][this.section];
    }
    this.inputArr = this.amendObjectProperties(this.savedDoc[this.section], this[this.formType][this.section]);
    for (let i = 0; i < this.inputArr.length; i++) {
      let val = this.inputArr[i]['value']
      this.ccArray.push(new FormControl(val));
    }
  }

  createForm(): void {
    this.form = new FormGroup({
      ccArray: this.ccArray
    });
  }

  amendObjectProperties(target: Array<any>, source: Array<any>): Array<any> {
    source.forEach((elt) => {
      const foundElt = target.find(elt2 => elt2 && (elt2.key === elt.key));
      if (foundElt && foundElt.type === 'date') {
        const val = foundElt.value;
        foundElt.value = (val && val.length >= 10) ? moment(val, 'MM/DD/YYYY').toDate() : moment().toDate();
      }
      if (!foundElt) {
        target.push(elt);
      }
    })
    return target;
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }

  async genCADoc(): Promise<true> {
    if (!this.form.valid) {
      return;
    }
    const formData = this.savedDoc;
    this.ccArray.controls.forEach((ctl, index: number) => {
      if (this.inputArr[index]["type"] === 'date') {
        this.inputArr[index]['value'] = ctl.value ? moment(ctl.value).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY');
      } else {
        this.inputArr[index]['value'] = ctl.value;
      }
    })
    try {
      await this.saveForm(formData);
      this.eventService.eventEmitter.emit({type: 'Reload'});
      FormUtil.genSuccessMessage(this.sharedService, `${this.getFormTitle()} successfully saved`);
      this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
      return Promise.resolve(true);
    } catch (data) {
      FormUtil.genErrorMessage(this.sharedService, 'Failed to save form', data);
      return Promise.reject(false);
    }
  }
}
