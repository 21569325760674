<div *ngIf="reloaded&&!orientationError">
  <div *ngIf="canAccessLODirectory">
    <!-- all folders except for unsorted folder    -->
    <app-folder-group *ngFor="let folderGroup of folderGroups" [hidden]="folderGroup.isLoanApp" (eventEmitted)="onEvent($event)" [folderGroup]="folderGroup" [tran]="tran">
    </app-folder-group>
  </div>

  <!--    Borrower Accessible Directory-->
  <div *ngIf="canAccessBorrowerDirectory">
    <div class="mb-1">
      <mat-card>
        <mat-card-title class="doc-folder">
          Loan Documents
        </mat-card-title>

        <mat-card-content>
          <div *ngFor="let doc of borrowerDocuments">
            <app-view-borrower-document [document]="doc" ></app-view-borrower-document>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="mt-1 mb-1">
    <app-view-folder (eventEmitted)="onEvent($event)" [folder]="unsortedFolder" [tran]="tran">
    </app-view-folder>
  </div>
</div>
