<div *ngIf="displayDownload" class="row m-3">
  <div class="col">
    <app-segment-header  [enable]="true" [help]="false" [isCard]="false" [header]="'Download all Documents in a Single Zip File'"></app-segment-header>
    <div class="mt-3">
      <button (click)="downloadZip($event)" *ngIf="canReadZip" class="btn btn-lg btn-primary" title="Download all files">
        <i class="fa fa-download"></i>&nbsp;Download Docs
      </button>
    </div>
  </div>
</div>

<app-box *ngIf="!displayDownload" class="mt-2" [type]="'warning'">
  There are
  <span class="fw-bold">no files</span> in
  <a href=""  class="pr-blue-bold" (click)="navigateToDocs($event)">Repository/Documents</a>.
</app-box>

<iframe class='d-none' id="download_zip_iframe"></iframe>
