import {Component, Injector, OnInit} from '@angular/core';
import {ProductIdentification} from '../../../../models/product-identification';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-origination',
  templateUrl: './view-origination.component.html',

})
export class ViewOriginationComponent extends CompBaseComponent implements OnInit {
  public item: ProductIdentification;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.item = new ProductIdentification(this.doc.recursive_attributes['product_identification']);
    this.mode = 'View';
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
