<div class="mt-5">
  <app-segment-header (eventEmitted)="onEvent($event)" [enable]="true" [isCard]="false" [header]="'Upload Credit Report'" [isCard]="false" [help]="true" [op]="''">
    <div class="fw-bold">Upload Credit Report</div>
    <div>
      To populate the loan application with credit liabilities, you can upload the credit report file to POLOS.
    </div>
    <ul>
      <li>
        Log in to your Credit Reporting Agency (CRA).
      </li>
      <li>
        Download the desired credit report in XML format. We recommend including the PDF report as well.
      </li>
      <li>
        Specify for whom the credit report was obtained (borrower, co-borrower, or joint report).
      </li>
      <li>
        If the file uploads successfully, you will be able to extract liabilities from
        <span class="fw-bold">Credit Reports</span>.
      </li>
    </ul>
  </app-segment-header>
</div>

<mat-card class="mt-1 mb-1" [hidden]="who.value">
  <mat-card-title>Credit Report Content
  </mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Credit report file was obtained for</mat-label>
            <mat-select formControlName="who" placeholder="Select prepared for " required>
              <mat-option *ngFor="let applicant of applicants" [value]="applicant.key">{{applicant.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="who.hasError('required')">
              Borrower is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<div *ngIf="who.value">
  <h5> This credit report file was obtained for:
    <span class="fw-bold">
    {{getCreditPreparedFor(who.value).value}}
    </span>
  </h5>
</div>

<!-- file upload -->
<div *ngIf="mode==='Upload' && who.value" class="mt-3">
  <app-upload-file (cancel)="cancel()" (uploadComplete)="onUploadComplete()" (error)="onError($event)" [actionUrl]="getActionUrl()" [title]="'Upload Credit Report in XML format (.xml)'" [uploadType]="'xml'" [multipleFiles]="false"></app-upload-file>
</div>
