import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../../../shared/validators/app-validators.validator';
import {CreditReportService} from '../../../../../services/credit-report.service';
import {CompBaseComponent} from '../../../../comp-base.component';
import {Document} from "../../../../../models/document";

@Component({
  selector: 'app-edit-segment-credit-report',
  templateUrl: './edit-segment-credit-report.component.html',
})
export class EditSegmentCreditReportComponent extends CompBaseComponent implements OnInit {
  @Input() creditDoc: Document;
  public creditReportService: CreditReportService;

  // form and form controls
  form: FormGroup;
  name: FormControl;
  description: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reloaded = false;
    this.creditReportService = this.injector.get(CreditReportService);
    this.createFormControls();
    this.createForm();
    this.reloaded = true;
  }

  createFormControls(): void {
    this.name = new FormControl(this.doc.name, AppValidators.present);
    this.description = new FormControl(this.doc.description);
  }

  createForm(): void {
    this.form = new FormGroup({
      name: this.name,
      description: this.description,
    });
  }

  update(event: any) {
    event.preventDefault();
    this.reloaded = false;
    this.creditReportService.updateDocument(this.doc.id, this.form.value)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        this.reloaded = true;
      })
      .catch((data) => {
        console.log('FAILED'); // todo
        this.reloaded = true;
      });
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }

  reset(event: any): void {
    event.preventDefault();
    this.eventEmitted.emit({type: 'Reload'});
  }
}
