import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {TranService} from './tran.service';
import moment from 'moment';
import {CacheService} from './cache.service';

@Injectable()
export class ComplianceService {

  constructor(private httpService: HttpService,
              private cacheService: CacheService,
              private tranService: TranService
  ) {
    this.cacheService.resetSeg('compliance');
  }

  public getCompReport(tranId: number | string, dateFormat, simulatedDate: any, is_refinance_loan: boolean, force?: boolean): Promise<any> {
    if (!tranId) {
      return Promise.resolve(null);
    }
    const isRefinanceLoan = is_refinance_loan ? '1' : '0';
    const tranIdString = tranId.toString();
    const compReport = this.cacheService.getSeg('compliance', tranIdString);
    if (!force && compReport) {
      return Promise.resolve(compReport);
    }

    this.cacheService.resetSeg('compliance', tranIdString);
    return new Promise((resolve, reject) => {
      return this.httpService.get(`comp_events/comp_report?tran_id=${tranId}&date_format=${dateFormat}&simulated_date=${simulatedDate}&refinance_loan=${isRefinanceLoan}`, {}, false)
        .then((data) => {
          this.cacheService.setSeg('compliance', tranIdString, data);
          return resolve(data);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public updateCompEvent(compEventId: number, payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.put(`comp_events/comp_event/${compEventId}`, payload)
        .then((data) => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          this.cacheService.resetSeg('compliance');
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  getMaxDate(tran_id, is_refinance_loan): Promise<Date> {
    return new Promise((resolve, reject) => {
      return this.getCompReport(tran_id, 'MM/DD/YYYY', moment().format('MM/DD/YYYY'), is_refinance_loan)
        .then((data: any) => {
          const compDates: string[] = Object.values(data.report).map((elt) => elt['occurred_on']) as Array<string>;
          if (compDates && compDates.length) {
            const result: any[] = compDates.map((elt) => moment(elt, 'MM/DD/YYYY').toISOString()).sort();
            if (result && result.length) {
              resolve(new Date(result.pop()));
            }
          }
          resolve(new Date());
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }
}
