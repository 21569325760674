import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {LoInformation} from '../../../../models/lo-information';
import {globals} from '../../../../shared/globals/globals';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import moment from 'moment';

@Component({
  selector: 'app-edit-segment-lo-information',
  templateUrl: './edit-segment-lo-information.component.html',
})
export class EditSegmentLoInformationComponent extends CompBaseComponent implements OnInit {
  @Input() public item: LoInformation;

  public minDate = globals.date.minTranStartDate;
  public maxDate = globals.date.maxTranEndDate;

  // form and form controls
  form: FormGroup;
  lo_name: FormControl;
  lo_orig_date: FormControl;
  lo_received_date: FormControl;
  lo_phone_number: FormControl;
  lo_company_name: FormControl;
  lo_company_address: FormControl;
  lo_company_address2: FormControl;
  lo_company_city: FormControl;
  lo_company_state: FormControl;
  lo_company_zip: FormControl;
  lo_company_zip_four: FormControl;
  lo_email: FormControl;
  lo_nmls: FormControl;
  lo_state_license: FormControl;
  lo_company_nmls: FormControl;
  lo_company_state_license_number: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'lo_information';
    this.section = 'lo';
    this.createFormControls(this.item);
    this.createForm();
  }

  // populate form from user account
  populate(): void {
    const tran = this.tranService.getCurrentTranSync();
    const workflow = tran ? tran.workflow : null;
    const loanAppReceivedDate = workflow ? workflow.getLoanAppReceivedDate() : null;
    const user = this.userService.getUserSync();
    const config = user.user_configuration;
    const txSummary = this.tranService.getTransactionSummary({
      transaction: tran,
      mlo: this.userService.getUserSync()
    });
    this.lo_name.setValue(user.full_name);
    this.lo_phone_number.setValue(globals.formatPhone(config.phone_number));
    this.lo_company_name.setValue(config.company_name);
    this.lo_company_address.setValue(config.address);
    this.lo_company_address2.setValue(config.address2);
    this.lo_company_city.setValue(config.city);
    this.lo_company_state.setValue(config.state);
    this.lo_company_zip.setValue(config.zip);
    this.lo_company_zip_four.setValue(config.zip_four);
    this.lo_email.setValue(user.email);
    this.lo_nmls.setValue(config.nmls);
    if (config.licenses && txSummary['propertyState'] && config.licenses[txSummary['propertyState']]) {
      this.lo_state_license.setValue(config.licenses[txSummary['propertyState']]);
    }
    this.lo_company_nmls.setValue(config.company_nmls);
    if (loanAppReceivedDate) {
      this.lo_received_date.setValue(loanAppReceivedDate);
    }
  }

  createFormControls(item): void {
    this.lo_name = new FormControl(item.lo_name, AppValidators.present);
    this.lo_phone_number = new FormControl(item.lo_phone_number, Validators.pattern(globals.pattern.phone));
    this.lo_orig_date = new FormControl(item.lo_orig_date);
    this.lo_received_date = new FormControl(item.lo_received_date);
    this.lo_company_name = new FormControl(item.lo_company_name);
    this.lo_company_address = new FormControl(item.lo_company_address);
    this.lo_company_address2 = new FormControl(item.lo_company_address2);
    this.lo_company_city = new FormControl(item.lo_company_city);
    this.lo_company_state = new FormControl(item.lo_company_state, Validators.pattern(globals.pattern.state));
    this.lo_company_zip = new FormControl(item.lo_company_zip, Validators.pattern(globals.pattern.zip));
    this.lo_company_zip_four = new FormControl(item.lo_company_zip_four, Validators.pattern(globals.pattern.zip_four));
    this.lo_email = new FormControl(item.lo_email, Validators.pattern(globals.pattern.email));
    this.lo_nmls = new FormControl(item.lo_nmls);
    this.lo_state_license = new FormControl(item.lo_state_license);
    this.lo_company_nmls = new FormControl(item.lo_company_nmls);
    this.lo_company_state_license_number = new FormControl(item.lo_company_state_license_number);
  }

  createForm(): void {
    this.form = new FormGroup({
      lo_phone_number: this.lo_phone_number,
      lo_name: this.lo_name,
      lo_orig_date: this.lo_orig_date,
      lo_received_date: this.lo_received_date,
      lo_company_name: this.lo_company_name,
      lo_company_address: this.lo_company_address,
      lo_company_address2: this.lo_company_address2,
      lo_company_city: this.lo_company_city,
      lo_company_state: this.lo_company_state,
      lo_company_zip: this.lo_company_zip,
      lo_company_zip_four: this.lo_company_zip_four,
      lo_email: this.lo_email,
      lo_nmls: this.lo_nmls,
      lo_state_license: this.lo_state_license,
      lo_company_nmls: this.lo_company_nmls,
      lo_company_state_license_number: this.lo_company_state_license_number,
    });
  }

  update(): void {
    if (this.lo_received_date.value && this.lo_orig_date.value) {
      if (moment(this.lo_received_date.value).isAfter(moment(this.lo_orig_date.value))) {
        FormUtil.genErrorMessage(this.sharedService, 'Loan application received date is after origination date', null);
        return;
      }
    }
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new LoInformation(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
