<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Seller Concessions'" [help]="true" [op]="'add'">
        <span class="fw-bold">Seller Concessions</span>
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>
            Seller concessions are closing costs that the seller has agreed to pay.
            Sometimes, the buyer asks the seller to contribute to specific closing costs.
            Other times, sellers may simply pay a percentage of the total closing costs.
          </li>
          <li>
            You can add, edit, and delete concessions.
          </li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <app-add-concession (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'&&canUpdateDocument" [doc]="doc"></app-add-concession>

    <div *ngFor="let item of items">
      <app-view-concession (eventEmitted)="onEvent($event)" [item]="item"></app-view-concession>
    </div>
  </mat-card>
</div>
