import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {Tran} from "../../../../models/tran";
import {Router} from "@angular/router";

@Component({
  selector: 'app-config-document',
  templateUrl: './config-document.component.html',
})
export class ConfigDocumentComponent extends CompBaseComponent implements OnInit {
  public formType: string;
  public stateArr: Array<string>;
  public folderId: string;
  public title: string;
  public tran: Tran;

  constructor(public injector: Injector, private router: Router) {
    super(injector);
    let compInput: any = null;
    try {
      compInput = this.router.getCurrentNavigation().extras.state?.['configInput'];
      this.formType = compInput.formType;
      this.stateArr = compInput.stateArr;
      this.folderId = compInput.folderId;
      this.title = compInput.title;
    } catch {
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.tran = this.tranService.getCurrentTranSync();
    setTimeout(() => {
      if (this.formType) {
        this.reloaded = true;
      } else {
        this.router.navigate(['/loan/view-directory'])
      }
    }, 0);
  }
}
