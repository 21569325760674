<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <app-view-sticky-note (eventEmitted)="onEvent($event)" [section]="section" [segment]="segment"></app-view-sticky-note>

    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Down Payments'" [help]="true" [op]="'add'">
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>
            It is important to provide accurate data for down payment sources. The underwriter will verify the down payment funds.
          </li>
          <li>
            In general a down payment cannot be borrowed. But there are some exceptions which you should discuss with your loan officer.
          </li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <app-add-down-payment (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'&&canUpdateDocument" [doc]="doc"></app-add-down-payment>

    <div *ngFor="let item of items">
      <app-view-down-payment (eventEmitted)="onEvent($event)" [doc]="doc" [item]="item"></app-view-down-payment>
    </div>
  </mat-card>
</div>
