import {ModelBase} from './model-base';

export class LoanData extends ModelBase {
  public static refinance_purpose_codes_ex: Array<any> = [
    {key: 'AssetAcquisition', value: 'Asset Acquisition'},
    {key: 'CapitalizedInterestTaxesInsuranceOrFees', value: 'Capitalized Interest Taxes Insurance or Fees'},
    {key: 'Cash', value: 'Cash'},
    {key: 'Convenience', value: 'Convenience'},
    {key: 'DebtConsolidation', value: 'Debt Consolidation'},
    {key: 'Education', value: 'Education'},
    {key: 'EquityBuyout', value: 'Equity Buyout'},
    {key: 'HomeImprovement', value: 'Home Improvement'},
    {key: 'InterestRateReduction', value: 'Interest Rate Reduction'},
    {key: 'Medical', value: 'Medical'},
    {key: 'MortgageTermReduction', value: 'Mortgage Term Reduction'},
    {key: 'Other', value: 'Other'},
    {key: 'PayoffLeaseholdInterest', value: 'Payoff Leasehold Interest'},
    {key: 'PrimaryLienPayoff', value: 'Primary Lien Payoff'},
    {key: 'SecondaryLienPayoff', value: 'Secondary Lien Payoff'},
    {key: 'SpecialPurpose', value: 'Special Purpose'},
    {key: 'UnsecuredLienPayoff', value: 'Unsecured Lien Payoff'},
    {key: 'Unspecified', value: 'Unspecified'},
  ];

  public static refinance_cash_out_types: Array<any> = [
    {key: 'LimitedCashOut', value: 'Limited Cash-Out'},
    {key: 'NoCashOut', value: 'No Cash-Out'},
    {key: 'CashOut', value: 'Cash-Out'},
    {key: 'Unknown', value: 'Unknown'}
  ];

  public static improvements_statuses_ex: Array<any> = [
    {key: 'Made', value: 'Made'},
    {key: 'ToBeMade', value: 'To Be Made'},
    {key: 'Unknown', value: 'Unknown'},
  ];

  // lot_acquired_year replaced by property_acquired_date
  lot_acquired_year: string;
  property_acquired_date: Date;
  home_improvements_cost: string;
  improvements_made_ex: string;
  refinance_purpose_code_ex: string;
  refinance_cash_out_type: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'home_improvements_cost') {
        this[prop] = this.getNumberProp(prop, object);
      } else if (prop === 'property_acquired_date') {
        this[prop] = this.getDateProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  getRefinanceCashOutType(): string {
    const found = LoanData.refinance_cash_out_types.find((elt) => elt.key === this.refinance_cash_out_type);
    return found ? found['value'] : '';
  }

  getRefinancePurposeCode(): string {
    const found = LoanData.refinance_purpose_codes_ex.find((elt) => elt.key === this.refinance_purpose_code_ex);
    return found ? found['value'] : '';
  }

  getImprovementStatus(): string {
    const found = LoanData.improvements_statuses_ex.find((elt) => elt.key === this.improvements_made_ex);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      lot_acquired_year: this.toNumber(this.lot_acquired_year),
      refinance_purpose_code_ex: this.refinance_purpose_code_ex,
      improvements_made_ex: this.improvements_made_ex,
      home_improvements_cost: this.toServerDecimal(this.home_improvements_cost),
      refinance_cash_out_type: this.refinance_cash_out_type,
      property_acquired_date: this.toServerDate(this.property_acquired_date),
    };
  }
}
