<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <app-view-sticky-note (eventEmitted)="onEvent($event)" [section]="section" [segment]="segment"></app-view-sticky-note>

    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Gifts'" [help]="true" [op]="'add'">
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>
            Include all gifts and grants and select the appropriate gift source for each one.
          </li>
          <li>You can edit or delete gifts.</li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <app-add-gift (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'&&canUpdateDocument" [applicant]="applicant" [doc]="doc"></app-add-gift>

    <div *ngFor="let item of items">
      <app-view-gift (eventEmitted)="onEvent($event)" [applicant]="applicant" [item]="item"></app-view-gift>
    </div>
  </mat-card>
</div>
