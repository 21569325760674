<mat-card *ngIf="reloaded">
  <mat-card-title>{{pageTitle}}</mat-card-title>

  <mat-card-content>
    <!--  Account Settings  -->
    <div *ngIf="!profileStatus.accountSettings.isComplete">
      <div class="fw-bold pr-font-large">Account Settings - {{profileStatus.accountSettings.percentComplete}}% complete</div>
      <div class="progress mb-2 w-25">
        <div class="progress-bar bg-primary" role="progressbar" [ngStyle]="{'width': profileStatus.accountSettings.percentComplete.toString() + '%'}" aria-valuenow="profileStatus.accountSettings.percentComplete" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <div class="mb-1">
        <span>Incomplete: {{profileStatus.accountSettings.messages}}</span>&nbsp;
        <span>Go to <a class="pr-blue-bold" routerLink="/account/settings">Account/Settings</a> to complete account settings.</span>
      </div>
    </div>

    <div *ngIf="user.isLoanOfficer()">
      <!--  MLO Configuration  -->
      <div *ngIf="!profileStatus.userConfiguration.isComplete">
        <div class="fw-bold pr-font-large">MLO Configuration - {{profileStatus.userConfiguration.percentComplete}}% complete</div>
        <div class="progress mb-2 w-25">
          <div class="progress-bar bg-primary" role="progressbar" [ngStyle]="{'width': profileStatus.userConfiguration.percentComplete.toString() + '%'}" aria-valuenow="profileStatus.userConfiguration.percentComplete" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="mb-1">
          <span>Incomplete: {{profileStatus.userConfiguration.messages}}</span>&nbsp;
          <span>Go to <a class="pr-blue-bold" routerLink="/account/mlo-configuration">Account/MLO Configuration</a> to complete MLO configuration.</span>
        </div>
      </div>

      <!-- Professional Profile -->
      <div *ngIf="!profileStatus.professionalProfile.isComplete">
        <div class="fw-bold pr-font-large">Branding - {{profileStatus.professionalProfile.percentComplete}}% complete</div>
        <div class="progress mb-2 w-25">
          <div class="progress-bar bg-primary" role="progressbar" [ngStyle]="{'width': profileStatus.professionalProfile.percentComplete.toString() + '%'}" aria-valuenow="profileStatus.userConfiguration.percentComplete" aria-valuemin="0" aria-valuemax="100"></div>
        </div>

        <div class="mb-1">
          <span>Incomplete: {{profileStatus.professionalProfile.messages}}</span>&nbsp;
          <span>Go to <a class="pr-blue-bold" routerLink="/account/branding">Account/Branding</a> to complete branding.</span>
        </div>
      </div>

      <!--User Organization-->
      <div *ngIf="!profileStatus.userOrganization.isComplete">
        <div class="fw-bold pr-font-large">Organization - {{profileStatus.userOrganization.percentComplete}}% complete</div>
        <div class="progress mb-2 w-25">
          <div class="progress-bar bg-primary" role="progressbar" [ngStyle]="{'width': profileStatus.userOrganization.percentComplete.toString() + '%'}" aria-valuenow="profileStatus.userOrganization.percentComplete" aria-valuemin="0" aria-valuemax="100"></div>
        </div>

        <div class="mb-1">
          <span>Incomplete: {{profileStatus.userOrganization.messages}}</span>&nbsp;
          <span>Go to <a class="pr-blue-bold" routerLink="/account/user-organization">Account/Organization</a> to complete organization configuration.</span>
        </div>
      </div>
    </div>

    <div *ngIf="user.isBorrower()" class="w-100 row">
      <div class="col p-3 mt-5 mb-3" style="border: 1px solid black">
        <div *ngIf="doc">
          <div *ngIf="canReadLoanApp">
            Your loan officer has granted you access to the loan application.
            Please navigate to
            <a class="pr-blue-bold" (click)="navigateToCurrentLoan($event)" href=" ">Current Loan/Loan Application</a> to view it.
          </div>
        </div>
        <div *ngIf="!doc && canReadTimeline">
          Please navigate to
          <a class="pr-blue-bold" (click)="navigateToCurrentLoan($event)" href="">Current Loan/Timeline</a> to view
          your loan process timeline.
        </div>
        <div *ngIf="!doc && !canReadTimeline">
          You currently don't have access to your loan. Please reach out to your loan officer to request access.
        </div>
      </div>
    </div>

    <div *ngIf="hasPOLOSService">
      <div class="fw-bold pr-font-large">Loans</div>

      <div *ngIf="activeTransactionsCount===0">
        <div *ngIf="!isLoanProfessional">
          <strong>Error Checking.</strong> Please contact your loan officer!
        </div>
        <div *ngIf="isLoanOfficer">
          You do not have access to any loan.
          You can create a new loan from
          <a class="pr-blue-bold" routerLink="/loans/new-loan">Loans/New Loan</a>.
        </div>
      </div>

      <div *ngIf="activeTransactionsCount >= 1">
        <ul>
          <li>
            You can access the current loan application under
            <a class="pr-blue-bold" routerLink="/loan/view-loan-app">Current Loan/Loan Application</a>.
          </li>
        </ul>
      </div>

      <div *ngIf="activeTransactionsCount > 1">
        <ul>
          <li>
            You have access to
            <a class="pr-blue-bold" routerLink="/loans/list">{{activeTransactionsCount}} {{activeTransactionsCount == 1 ? 'active loan' : 'active loans' }}</a>.
          </li>
        </ul>
      </div>

      <div class="fw-bold pr-font-large mt-3">Contacts</div>

      <div *ngIf="accountOverview.contact_count===0">
        <div *ngIf="isLoanProfessional">
          You do not have any contacts!
          Add new contacts from
          <a class="pr-blue-bold" routerLink="/account/view-contacts">Account/Contacts</a>.
        </div>

        <div *ngIf="!isLoanProfessional">
          <strong>You are not yet a contact of your loan officer.</strong> Please contact your loan officer!
        </div>
      </div>

      <div *ngIf="accountOverview.contact_count > 0">
        <ul>
          <li>You have
            <a class="pr-blue-bold" routerLink="/account/view-contacts">{{accountOverview.contact_count}} {{accountOverview.contact_count == 1 ? 'contact' : 'contacts' }}</a>.
          </li>
        </ul>
      </div>

      <div class="fw-bold pr-font-large mt-3">Messages</div>

      <ul *ngIf="false">
        <li>
          <a class="pr-blue-bold" routerLink="/loan/view-comm">Sent</a> {{accountOverview.messages_sent_count}} {{accountOverview.messages_sent_count == 1 ? 'message' : 'messages'}}.
        </li>
        <li>
          <a class="pr-blue-bold" routerLink="/loan/view-comm">Received</a> {{accountOverview.messages_received_count}} {{accountOverview.messages_received_count == 1 ? 'message' : 'messages'}}.
        </li>
      </ul>

      <ul *ngIf="true">
        <li>Sent {{accountOverview.messages_sent_count}} messages.</li>
        <li>Received {{accountOverview.messages_received_count}} {{accountOverview.messages_received_count == 1 ? 'message' : 'messages'}}.</li>
      </ul>
    </div>
  </mat-card-content>
</mat-card>
