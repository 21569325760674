<mat-card class="mt-2 mb-1">
  <app-view-sticky-note (eventEmitted)="onEvent($event)" [section]="section" [segment]="segment"></app-view-sticky-note>

  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Ethnicity & Sex'" [help]="true" [op]="'edit'">
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>
          You do not have to provide information about your ethnicity and sex if you do not wish to.
        </li>
        <li>
          However, if you do not provide this information, the lender is required under federal regulations to make a best guess based
          on surname and visual observation if you have made your application in person.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div *ngIf="item.no_information_ethnicity" class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Ethnicity</div>
      <div class="col-lg-6">I do not wish to provide this information</div>
    </div>

    <div [hidden]="item.no_information_ethnicity" class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Ethnicity</div>
      <div class="col-lg-6">{{item.getEthnicity()}}</div>
    </div>

    <div *ngIf="item.no_information_sex" class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Sex</div>
      <div class="col-lg-6">I do not wish to provide this information</div>
    </div>

    <div [hidden]="item.no_information_sex" class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Sex</div>
      <div class="col-lg-6">{{item.getGender()}}</div>
    </div>
  </mat-card-content>
</mat-card>
