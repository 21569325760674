import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Tran} from '../../../models/tran';
import {Document} from '../../../models/document';
import {CompBaseComponent} from '../../comp-base.component';
import {globals} from '../../../shared/globals/globals';
import {Help} from '../../../models/help';

@Component({
  selector: 'app-view-dossier-tabs',
  templateUrl: './view-dossier-tabs.component.html',
})
export class ViewDossierTabsComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public static currentSection = globals.section.loanRecord;
  public currentSection: string;
  public pageTitle = 'Loan Repository';
  public loanApps: Array<Document>;
  public localSection = globals.section;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.directory
      });
    }, 0);
    this.reload(false);
    this.reloadOnSync();
    this.subscription = this.eventService.eventEmitter.subscribe((event) => {
      if (event.type === 'ChangeTab') {
        this.setSection(null, event.tab)
      }
    });
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload(false);
      return;
    }
  }

  reload(force: boolean): void {
    // comment: when tran status changes need to refresh permissions
    super.loadPermissions(true);
    this.reloaded = false;
    this.tranService.getCurrentTran(force)
      .then((tran: Tran) => {
        this.tran = tran;
        const loanApps = Document.deserializeArray(tran['documents']).filter((elt) => elt.dtype === 64);
        this.loanApps = loanApps.sort((x, y) => x.category === y.category ? 0 : (x.category < y.category ? -1 : 1));
        this.setSection(null, ViewDossierTabsComponent.currentSection);
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      });
  }

  setSection(event: any, section: string): void {
    if (event) {
      event.preventDefault();
    }
    CompBaseComponent.fromDocId = null;
    CompBaseComponent.fromFolderId = null;
    this.currentSection = ViewDossierTabsComponent.currentSection = section;
  }
}
