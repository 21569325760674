<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Title'" [help]="true" [op]="'add'">
        <span class="fw-bold">Joint Title</span>
        <p>
          For a joint title, select "Other" in the party drop-down.
        </p>

        <span class="fw-bold">Title Vesting</span>
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>
            <strong>Joint tenancy.</strong> Each person has an undivided interest in the property.
          </li>
          <li>
            <strong>Tenants in common.</strong> Indicates the percentage of ownership in the property. For example, one party may own 25% and
            another party may own 75%.
          </li>
          <li>
            <strong>Individual.</strong> Allows an individual to have 100%
            title to the property. A single or unmarried person may have this type of title.
          </li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <app-add-party (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'&&canUpdateDocument" [doc]="doc"></app-add-party>

    <div *ngFor="let item of items">
      <app-view-party (eventEmitted)="onEvent($event)" [item]="item"></app-view-party>
    </div>
  </mat-card>
</div>
