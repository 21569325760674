<div *ngIf="reloaded">
  <div *ngIf="localSeg && +localSeg > 0" class="row ms-0 pl-0">
    <div class="col ms-0 pl-0 pr-0 me-0">
      <ul class="progressbar m-0 p-0">
        <li *ngFor="let i of [1,2,3,4,5,6,7]" [ngClass]="{'done':+localSeg>i, 'active':+localSeg===i, 'inactive':+localSeg<i}"></li>
      </ul>
    </div>
  </div>

  <div *ngIf="localSeg && +localSeg > 0" style="display: flex; width:100%; flex-direction: row; margin-left: 65px;">
    <div class="ms-0 pl-0 pr-0 me-0" style="flex:14.285%;">
      MLO Config
    </div>
    <div class="ms-0 pl-0 pr-0 me-0" style="flex:14.285%;">
      Organization/Users
    </div>
    <div class="ms-0 pl-0 pr-0 me-0" style="flex:14.285%;">
      Loan MCRs
    </div>
    <div class="ms-0 pl-0 pr-0 me-0" style="flex:14.285%;">
      Create Org MCR
    </div>
    <div class="ms-0 pl-0 pr-0 me-0" style="flex:14.285%;">
      Review State MCR
    </div>
    <div class="ms-0 pl-0 pr-0 me-0" style="flex:14.285%;">
      Generate MCR XML
    </div>
    <div class="ms-0 pl-0 pr-0 me-0" style="flex:14.285%;">
      Upload to NMLS
    </div>
  </div>

  <div *ngIf="+localSeg !== 7" class="mt-3">

  </div>

  <div *ngIf="localSeg && +localSeg > 0" class="row">
    <div class="col-9">
      <div style="margin-left: 75px;">
        <button *ngIf="+localSeg!==7"  (click)="toggleHelp($event)" class="btn btn-lg btn-primary">
          <i class="fa fa-question-circle" aria-hidden="true"></i>&nbsp;Toggle Help
        </button>
      </div>
    </div>
    <div class="col-3 text-end">
      <div style="margin-right: 75px;">
        <button (click)="regressTask()" class="btn btn-lg btn-primary">
          <i class="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;Prev
        </button>

        <button (click)="progressTask()" class="btn btn-lg btn-primary ms-3">Next&nbsp;
          <i class="fa fa-angle-double-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!localSeg || +localSeg === 0" class="row mt-3">
    <div class="col-12 text-center">
      <button (click)="progressTask()" class="btn btn-lg btn-primary ms-3">Get Started&nbsp;
        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <div [ngSwitch]="localSeg">
    <!--MCR Instructions-->
    <app-view-mcr-info [hidden]="+localSeg==7?false:isHidden" *ngSwitchCase="localSeg" [taskID]="localSeg"></app-view-mcr-info>
    <!-- MLO Configuration   -->
    <app-view-mlo-configuration *ngSwitchCase="'1'" [isTitle]="false" [backLink]="'/mcr/view-wizard'"></app-view-mlo-configuration>
    <!-- Organization and Users -->
    <app-view-user-org *ngSwitchCase="'2'" [isTitle]="false" [backLink]="'/mcr/view-wizard'"></app-view-user-org>
    <!-- Loan MCRs -->
    <app-view-loan-mcrs *ngSwitchCase="'3'" [isTitle]="false" [organization]="organization" [backLink]="'/mcr/view-wizard'"></app-view-loan-mcrs>
    <!--Create Org MCR-->
    <app-view-org-mcrs *ngSwitchCase="'4'" [isTitle]="false" [backLink]="'/mcr/view-wizard'"></app-view-org-mcrs>
    <!--Review State MCR-->
    <app-view-org-mcrs *ngSwitchCase="'5'" [isTitle]="false"  [backLink]="'/mcr/view-wizard'"></app-view-org-mcrs>
    <!--Gen MCR XML-->
    <app-view-org-mcrs *ngSwitchCase="'6'" [isTitle]="false" ></app-view-org-mcrs>
    <!--Upload to NMLS (instructions only) -->
  </div>
</div>
