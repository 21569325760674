<mat-card *ngIf="reloaded">
  <mat-card-title>
    Edit {{getFormTitle()}}
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" class="w-100">
      <div *ngFor="let cc of inputArr; let i = index" formArrayName="ccArray">
        <div class="row">
          <div class="col-lg-4">
            <div *ngIf="cc['type']==='currency'">
              <mat-form-field class="w-100">
                <mat-label>{{cc['item_desc']}}</mat-label>
                <input matInput appDecimalOnly [formControlName]="i" placeholder="{{cc['item_desc']}}" maxlength="{{MAX_LEN.MONEY}}">
                <mat-error *ngIf="ccArray.controls[i].hasError('required')">
                  {{cc['item_desc']}} is required
                </mat-error>
              </mat-form-field>
            </div>

            <div *ngIf="cc['type']==='string'">
              <mat-form-field class="w-100">
                <mat-label>{{cc['item_desc']}}</mat-label>
                <input matInput [formControlName]="i" placeholder="{{cc['item_desc']}}" maxlength="{{MAX_LEN.DESC}}">
                <mat-error *ngIf="ccArray.controls[i].hasError('required')">
                  {{cc['item_desc']}} is required
                </mat-error>
              </mat-form-field>
            </div>

            <div *ngIf="cc['type']==='date'">
              <mat-form-field class="w-100">
                <mat-label>{{cc['item_desc']}}</mat-label>
                <input [matDatepicker]="datePicker" [max]="maxDate" [min]="minDate" [formControlName]="i" matInput>
                <mat-datepicker-toggle [for]="datePicker" matIconSuffix></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
                <mat-error *ngIf="ccArray.controls[i].hasError('matDatepickerMin') || ccArray.controls[i].hasError('matDatepickerMax')">
                  Document date is unrealistic
                </mat-error>
                <mat-error *ngIf="ccArray.controls[i].hasError('matDatepickerParse')">
                  Document date format is invalid
                </mat-error>
              </mat-form-field>
            </div>

            <div *ngIf="cc['type']==='percent'">
              <mat-form-field class="w-100">
                <mat-label>{{cc['item_desc']}}</mat-label>
                <input *ngIf="cc['type']==='percent'" appDecimalThreeOnly matInput [formControlName]="i" placeholder="{{cc['item_desc']}}" maxlength="5">
                <mat-error *ngIf="ccArray.controls[i].hasError('required')">
                  {{cc['item_desc']}} is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="genCADoc()" [disabled]="!form.valid" class="ms-2 btn btn-lg btn-primary">Save Data</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-secondary">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
