<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Credits'" [help]="true" [op]="'add'">
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>If you don't find the credit type in the drop-down list,
            select Other and supply a concise description for it.
          </li>
          <li>You can edit or delete credits.</li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <app-add-credit (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'&&canUpdateDocument" [doc]="doc"></app-add-credit>

    <div *ngFor="let item of items">
      <app-view-credit (eventEmitted)="onEvent($event)" [item]="item"></app-view-credit>
    </div>
  </mat-card>
</div>
