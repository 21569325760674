<mat-card class="mb-3" [ngClass]="{'w-100': !isMobile}">
  <mat-card-content>
    <div class="row">
      <div class="col-lg-9">
        <div class="row pt-1 pb-1 hover-gray">
          <div class="col-lg-4 fw-bold">
            File name
          </div>
          <div class="col-lg-8">
            {{anonDoc.name}}
          </div>
        </div>
        <div class="row pt-1 pb-1 hover-gray">
          <div class="col-lg-4 fw-bold">
            Uploaded timestamp
          </div>
          <div class="col-lg-8">
            {{anonDoc.created_at | date: 'medium'}}
          </div>
        </div>
        <div class="row pt-1 pb-1 hover-gray">
          <div class="col-lg-4 fw-bold">
            Description
          </div>
          <div class="col-lg-8" [innerHTML]="anonDoc.description">
          </div>
        </div>
        <div class="row pt-1 pb-1 hover-gray">
          <div *ngIf="anonDoc.isPDF()" class="col-lg-4" title="Click to download">
            <img (click)="downloadDoc()" class="hand-pointer doc-image" src="../../../../../assets/img/adobe-pdf-icon.png">
          </div>
          <div *ngIf="anonDoc.isImage()" class="col-lg-4">
            <img (click)="toggleImage()" [src]="anonDoc.thumbnail_url" class="pointer doc-image" title="Click to view image">
          </div>
          <div class="col-lg-4" title="Download">
            <i  (click)="downloadDoc()" style="font-size: 36px;" class="hand-pointer fa fa-download pr-dark-blue"></i>
          </div>
          <div class="col-lg-4 text-end my-auto">
          </div>
        </div>
        <div (click)="toggleImage()" *ngIf="anonDoc.isImage()&&fullImage" class="mb-1 hover-gray pt-3 pb-3" title="Click to minimize">
          <div class="text-center pb-3">
            <strong>{{anonDoc.name}}</strong>&nbsp;&nbsp;<span class="text-muted">Uploaded: {{anonDoc.updated_at | date:'medium'}}</span>
          </div>
          <div class="text-center">
            <img [src]="anonDoc.image_url" class="pointer">
          </div>
        </div>
      </div>
      <div class="col-lg-3 text-center my-auto">
          <a (click)="deleteDoc($event,anonDoc.id)" class="ms-2" title="Delete file"><i style="font-size: 36px" class="text-danger fa fa-trash hand-pointer"></i></a>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<iframe *ngIf="downloading" [src]="urlSafe" class="d-none">
</iframe>
