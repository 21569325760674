import {ModelBase} from './model-base';
import {Permission} from './permission';
import {CreditReport} from './credit-report';
import {globals} from "../shared/globals/globals";

export class Document extends ModelBase {
  static getURLAFormTypeTable(): Array<any> {
    return [
      {key: 'URLA', value: 'URLA'},
      {key: 'ADDL', value: 'URLA - Additional Borrower'},
      {key: 'CONT', value: "URLA - Continuation Sheet"},
      {key: 'UMAR', value: "URLA - Unmarried Addendum"},
    ]
  }
  static getAllFormTypes(): any {
    return {
      'US': [
        {
          key: 'REQS',
          ID: 0,
          configurable: false,
          forms: [],
          type: 'Container',
          value: 'Single or Multiple Forms in a Single PDF',
          states: ['*']
        },
        {
          key: 'URLA',
          ID: 1,
          configurable: false,
          type: 'PDF',
          forms: ['URLA', 'ADDL', 'CONT', 'UMAR'],
          value: 'Universal Residential Loan Application Forms',
          states: ['*']
        },
        {
          key: 'MISMO',
          ID: 2,
          configurable: false,
          type: 'XML',
          forms: ['MISMO'],
          value: 'MISMO 3.4 XML File',
          states: ['*']
        },
        {
          key: 'BFA',
          ID: 3,
          configurable: true,
          type: 'PDF',
          forms: ['BFA'],
          value: 'Mortgage Broker Fee Agreement',
          states: ['*']
        },
        {
          key: 'STEER',
          ID: 4,
          configurable: true,
          type: 'PDF',
          forms: ['STEER'],
          value: 'Anti-Steering Disclosure',
          states: ['*']
        },
        {
          key: 'ECOA',
          ID: 5,
          configurable: true,
          type: 'PDF',
          forms: ['ECOA'],
          value: 'ECOA Disclosure',
          states: ['*']
        },
        {
          key: 'PATRIOT',
          ID: 6,
          configurable: true,
          type: 'PDF',
          forms: ['PATRIOT'],
          value: 'PATRIOT',
          states: ['*']
        },
        {
          key: 'CRAUTH',
          ID: 7,
          configurable: true,
          type: 'PDF',
          forms: ['CRAUTH'],
          value: 'Credit Authorization',
          states: ['*']
        },
        {
          key: 'FEE_WORKSHEET',
          ID: 8,
          configurable: true,
          type: 'PDF',
          forms: ['FEE_WORKSHEET'],
          value: 'Fee Worksheet',
          states: ['*']
        },
        {
          key: 'CFPB_LE',
          ID: 9,
          configurable: true,
          type: 'PDF',
          forms: ['CFPB_LE'],
          value: 'Loan Estimate',
          states: ['*']
        },
        {
          key: 'FNMA_CFA',
          ID: 10,
          configurable: true,
          type: 'PDF',
          forms: ['FNMA_CFA'],
          value: 'Cash Flow Analysis / FNMA Form 1084',
          states: ['*']
        },
      ],
      'CO': [
        {
          key: 'CO_TNB',
          ID: 11,
          configurable: true,
          type: 'PDF',
            forms: ['CO_TNB'],
            value: 'CO - Tangible Net Benefit Disclosure',
            states: ['CO']
          },
          {
            key: 'CO_DUAL',
          ID: 12,
          configurable: true,
          type: 'PDF',
          forms: ['CO_DUAL'],
          value: 'CO - Dual Status Disclosure',
          states: ['CO']
        },
      ],
      'CA': [
        {
          key: 'CA_ADDN',
          ID: 13,
          configurable: true,
          type: 'PDF',
          forms: ['CA_ADDN'],
          value: 'CA - Addendum to Loan Estimate',
          states: ['CA']
        },
        {
          key: 'CA_ADVC',
          ID: 14,
          configurable: true,
          type: 'PDF',
          forms: ['CA_ADVC'],
          value: 'CA - Advance Fee Disclosure',
          states: ['CA']
        },
        {
          key: 'CA_BROK',
          ID: 15,
          configurable: true,
          type: 'PDF',
          forms: ['CA_BROK'],
          value: 'CA - Loan Brokerage Agreement',
          states: ['CA']
        },
        {
          key: 'CA_CRED',
          ID: 16,
          configurable: true,
          type: 'PDF',
          forms: ['CA_CRED'],
          value: 'CA - Married Applicant Disclosure',
          states: ['CA']
        },
        {
          key: 'CA_DUAL',
          ID: 17,
          configurable: true,
          type: 'PDF',
          forms: ['CA_DUAL'],
          value: 'CA - Dual Status Disclosure',
          states: ['CA']
        },
        {
          key: 'CA_FAIR',
          ID: 18,
          configurable: true,
          type: 'PDF',
          forms: ['CA_FAIR'],
          value: 'CA - Fair Lending Notice',
          states: ['CA']
        },
      ]
    };
  }

  id: number;
  index: number;
  dtype: number;
  tran_id: number;
  transaction_item_id: string;
  name: string;
  category: number;
  code: string;
  content2: any;
  content3: any;
  description: string;
  meta: any;
  // content: any;
  permissions: Array<Permission>;
  user_permission;
  recursive_attributes: any;
  comments: string;
  created_at: Date;
  updated_at: Date;
  // ui only properties
  canReadDocument: boolean;
  _thumbnail_url: string;
  _image_url: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'updated_at' || prop === 'created_at') {
        this[prop] = new Date(object[prop]);
      } else if (prop === 'thumbnail_url') {
        this._thumbnail_url = object[prop];
      } else if (prop === 'image_url') {
        this._image_url = object[prop];
      } else if (prop === 'permissions') {
        this.permissions = Permission.deserializeArray(object[prop]);
      } else {
        this[prop] = object[prop];
      }
    }

    if (this.isLoanDoc() && this.recursive_attributes && this.recursive_attributes['borrowers']) {
      const bor = this.recursive_attributes['borrowers'].find((elt) => elt['role'] === 'borrower_1');
      const co_bor = this.recursive_attributes['borrowers'].find((elt) => elt['role'] === 'borrower_2');
      const bor3 = this.recursive_attributes['borrowers'].find((elt) => elt['role'] === 'borrower_3');
      const bor4 = this.recursive_attributes['borrowers'].find((elt) => elt['role'] === 'borrower_4');
      this.recursive_attributes['borrower_1'] = bor;
      this.recursive_attributes['borrower_2'] = co_bor;
      this.recursive_attributes['borrower_3'] = bor3;
      this.recursive_attributes['borrower_4'] = bor4;
    }
    this.canReadDocument = this.user_permission > 0;
  }

  get thumbnail_url() {
    return 'api/' + this._thumbnail_url;
  }

  get image_url() {
    return 'api/' + this._image_url;
  }

  static deserializeArray(arr: Array<any>):
    Array<Document> {
    const result = [];
    let index = 0;
    for (const obj of arr
      ) {
      const item = new Document(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  isDefault(): boolean {
    return this.category === 0;
  }

  isPDF(): boolean {
    return this.dtype === ModelBase.DTYPE.PDF || this.dtype === ModelBase.DTYPE.GEN_PDF || this.dtype === ModelBase.DTYPE.CREDIT_PDF || this.dtype === ModelBase.DTYPE.ANON_PDF;
  }

  isXML(): boolean {
    return this.dtype === ModelBase.DTYPE.MISMO_XML;
  }

  isImage(): boolean {
    return this.dtype === ModelBase.DTYPE.IMAGE || this.dtype === ModelBase.DTYPE.ANON_IMAGE;
  }

  isFolderDoc(): boolean {
    return this.dtype === ModelBase.DTYPE.IMAGE ||
      this.dtype === ModelBase.DTYPE.PDF ||
      this.dtype === ModelBase.DTYPE.GEN_PDF ||
      this.dtype === ModelBase.DTYPE.CREDIT_PDF ||
      this.dtype === ModelBase.DTYPE.MISMO_XML ||
      this.dtype === ModelBase.DTYPE.ANON_PDF ||
      this.dtype === ModelBase.DTYPE.ANON_IMAGE;
  }

  isLoanDoc(): boolean {
    return this.dtype === 64;
  }

  getCreditProvider(): string {
    const creditProviderKey = this.meta.credit_provider;
    return CreditReport.creditProviderName(creditProviderKey);
  }

  getDocumentTypeString(): string {
    switch (this.dtype) {
      case ModelBase.DTYPE.NONE:
        return 'None';
      case ModelBase.DTYPE.IMAGE:
      case ModelBase.DTYPE.ANON_IMAGE:
        return 'Image';
      case ModelBase.DTYPE.PDF:
      case ModelBase.DTYPE.GEN_PDF:
      case ModelBase.DTYPE.CREDIT_PDF:
      case ModelBase.DTYPE.ANON_PDF:
        return 'PDF';
      case ModelBase.DTYPE.MISMO_XML:
      case ModelBase.DTYPE.CREDIT_XML:
        return 'XML';
      case ModelBase.DTYPE.CREDIT:
        return 'Credit';
      case ModelBase.DTYPE.LOAN_APP:
        return 'Loan Application';
      case ModelBase.DTYPE.WS:
        return 'Worksheet';
    }
  }
}
