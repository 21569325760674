import {Injectable} from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import {User} from '../models/user';
import {globals} from '../shared/globals/globals';

// unprotected methods
const unprotectedMethods = {
  'ping': ['get'],
  'ping.json': ['get'],
  'home/ping': ['get'],
  'home/login': ['post'],
  'home/login_with_device': ['post'],
  'home/logout_session': ['delete'],
  'home/login_from_auth': ['get', 'post'],
  'home/get_session': ['get'],
  'home/account_password_reset_json': ['post'],
  'home/account_verification_json': ['post'],
  'home/user_token_verification_json': ['post'],
  'home/unsubscribe': ['post'],
  'home/anon_doc_upload/:id': ['post'],
  'emails/create_notification': ['post'],
  'emails/update_notification/:id': ['put'],
  'contacts': ['post'],
  'pitch/logistic': ['get', 'post'],
  'pitch/convertible': ['get', 'post'],
  'config': ['get'],
};

// user methods
const userMethods = {
  'account/logo_upload': ['post'],
  'account/photo_upload': ['post'],
  'account/anon_docs': ['get'],
  'account': ['put'],
  'account/overview': ['get'],
  'account/photo': ['delete'],
  'account/logo': ['delete'],
  'account/user_configurations/:id': ['put'],
  'account/professional_profiles/:id': ['put'],
  'account/update_password': ['put'],
  'account/delete_anon_doc/:id': ['delete'],
  'account/contacts': ['post'],
  'account/contacts/:id': ['delete'],
  'account/contacts/hide/:id': ['put'],
  'account/contacts/show/:id': ['put'],
  'account/remove_services': ['put'],
  'loan_apps/update_loan_application/:id': ['put'],
  'loan_apps/update_loan_app_obj/:id': ['put'],
  'loan_apps/add_loan_app_item/:id': ['post'],
  'loan_apps/update_loan_app_item/:id': ['put'],
  'loan_apps/delete_loan_application_obj/:id': ['post'],
  'loan_apps/empty_loan_application_obj/:id': ['put'],
  'loan_apps/update_applicant_liabilities/:id': ['put'],
  'loan_apps/finalize_loan_application/:id': ['put'],
  'documents/copy_document/:id': ['post'],
  'dashboard/compliance_dash/:id': ['get'],
  'dashboard/workflow_dash/:id': ['get'],
  'documents/move_to_tran/:id': ['put'],
  'permissions/:id': ['put'],
  'workflow/update_workflow_state/:id': ['put'],
  'workflow/reset_future_traces/:id': ['put'],
  'workflow/update_bulk_traces/:id': ['put'],
  'workflow/undo_last_trace/:id': ['put'],
  'traces/:id': ['put'],
  'traces/hide_trace/:id': ['put'],
  'traces/show_trace/:id': ['put'],
  'notes': ['get', 'post'],
  'notes/:id': ['get', 'put', 'delete'],
  'trans/:id/posts/:id': ['put', 'delete'],
  'trans': ['post', 'get'],
  'trans/:id': ['get', 'put', 'delete'],
  'trans/file_upload': ['post'],
  'trans/credit_file_upload': ['post'],
  'trans/transaction_items_update/:id': ['post'],
  'trans/:id/transaction_items': ['post'],
  'trans/:id/transaction_items/:str': ['put'],
  'trans/copy_tran_docs': ['put'],
  'trans/ws/:id': ['put', 'get'],
  'trans/mismo_3_4_upload': ['post'],
  'comp_events/comp_report': ['get'],
  'comp_events/comp_event/:id': ['put'],
  'documents/:id': ['put', 'delete'],
  'documents': ['post'],
  'documents/gen_patriot': ['post'],
  'documents/save_form': ['post'],
  'documents/gen_docs/:id': ['post'],
  'documents/disp_nurla/:id': ['get'],
  'documents/gen_mismo/:id': ['post'],
  'documents/update_icn/:id': ['put'],
  'credit_reports': ['post'],
  'credit_reports/create': ['post'],
  'credit_reports/refresh/:id': ['post'],
  'credit_reports/update_credit_report/:id': ['post'],
  'credit_reports/generate_credit_pdf/:id': ['post'],
  'credit_reports/:id': ['put', 'delete'],
  'credit_reports/credit_file_upload': ['post'],
  'messages': ['get', 'post'],
  'messages/:id': ['delete'],
  'emails': ['post'],
  'organizations/update/:id': ['put'],
  'organizations': ['post'],
  'organizations/logo_upload': ['post'],
  'organizations/logo': ['delete'],
  'organizations/get_trans/:id': ['get'],
  'organizations/set_org_admin': ['put'],
  'organizations/get_user_org_info': ['get'],
  'organizations/find_org_by_id/:id': ['get'],
  'mcrs': ['post'],
  'mcrs/:id': ['get', 'delete', 'put'],
  'mcrs/:id/populate': ['put'],
  'mcrs/:id/gen_lo_mcr_report': ['put'],
  'mcrs/:id/gen_org_mcr_report': ['put'],
  'mcrs/user_mcrs': ['get'],
  'mcrs/tran_mcr/:id': ['get'],
  'mcrs/:id/gen_org_xml_mcr': ['get'],
  'mcrs/org_mcrs/:id': ['get'],
  'mcrs/tran_mcrs/:id': ['get'],
  'requests': ['post'],
  'conditions/:id': ['delete', 'get', 'put', 'post'],
  'conditions/get_condition_by_id/:id': ['get'],
  'org_admin/create_account': ['post'],
  'org_admin/add_org_user': ['put'],
  'org_admin/update_user': ['put'],
  'org_admin/remove_user': ['put'],
  'agent/find_org': ['get', 'post'],
  'agent/find_org_by_id/:id': ['get'],
  'agent/update_org/:id': ['put'],
  'agent/create_org': ['post'],
  'agent/create_account': ['post'],
  'agent/update_user': ['put'],
  'agent/remove_user': ['put'],
  'invoices/:id/invoices': ['get'],
  'invoices/mark_paid/:id': ['put'],
  'invoices/apply_coupon/:id': ['put'],
  'invoices/pay/:id': ['post'],
  'invoices/index': ['get'],
  'invoices/:id': ['get', 'put', 'delete'],
  'invoices': ['post'],
  'orders/:id': ['delete', 'get'],
  'orders/:id/account_orders': ['get'],
  'orders': ['post'],
  'orders/add_services': ['put'],
};

// admin methods
const adminMethods = {
  'admin/find_user_by_id/:id': ['get'],
  'admin/find_user': ['get', 'post'],
  'admin/find_order/:id': ['get'],
  'admin/find_orders/:id': ['get'],
  'admin/find_payments/:id': ['get'],
  'admin/find_tran_by_id/:id': ['get'],
  'admin/find_tran': ['get'],
  'admin/find_loan_officer': ['get'],
  'admin/grant_lo_access': ['put'],
  'admin/revoke_lo_access': ['put'],
  'admin/create_organization': ['post'],
  'admin/find_organization_by_id/:id': ['get'],
  'admin/find_organization': ['get', 'post'],
  'admin/update_organization': ['put'],
  'admin/update_user': ['put'],
  'admin/create_account': ['post'],
  'admin/create_uid': ['post'],
  'admin/delete_user/:id': ['delete'],
  'admin/hard_reset_pw': ['put'],
  'admin/update_org/:id': ['put'],
  'admin/create_org': ['post'],
  'admin/find_org': ['get'],
  'admin/find_org_by_id/:id': ['get'],
  'admin/set_org_admin/:id': ['put'],
  'admin/remove_from_org/:id': ['put'],
  'admin/add_to_org/:id': ['put'],
  'admin/send_socket_message': ['post'],
  'sys_alerts': ['get'],
  'sys_alerts/:id': ['put'],
  'config/index': ['get'],
  'config': ['put', 'get', 'post', 'delete'],
};

@Injectable()
export class HttpService {
  private static csrf_token: string;
  private urlBase: string;
  private authTable = {};

  constructor(private http: HttpClient) {
    this.setUnprotectedAuthorization();
  }

  static generateRequestHeaders(url): HttpHeaders {
    const headers = new HttpHeaders();
    if (HttpService.isAuthenticationUrl(url)) {
      return headers;
    }

    if (HttpService.csrf_token) {
      return headers.append('X-CSRF-TOKEN', HttpService.csrf_token);
    }
  }

  static processResponseHeaders(url: string, response: HttpResponse<any>): void {
    if (HttpService.isAuthenticationUrl(url)) {
      HttpService.csrf_token = response.headers.get('X-CSRF-TOKEN');
    }
  }

  static isAuthenticationUrl(url): boolean {
    return url.startsWith('home/login') || url.startsWith('home/get_session');
  }

  setUrlBase(urlBase: string): void {
    this.urlBase = urlBase;
  }

  setUnprotectedAuthorization(): void {
    this.authTable = Object.assign(this.authTable, unprotectedMethods);
  }

  setAuthorization(user: User): void {
    this.authTable = Object.assign(this.authTable, userMethods);
    if (user.isAdmin() || user.isAgent()) {
      this.authTable = Object.assign(this.authTable, adminMethods);
    }
  }

  isAuthorizedEndPoint(method: string, inUrl: string): boolean {
    inUrl = inUrl.split('?')[0];
    let url: string;
    const error = `Navigation error. Report error to PrudentCO Support: Unauthorized url: ${inUrl} method: ${method}`;

    if (inUrl.indexOf('sessions/') >= 0) {
      url = 'sessions/:token';
    } else if (inUrl.indexOf('sw_workers/assign_jobs') >= 0) {
      url = 'sw_workers/assign_jobs';
    } else if (inUrl.indexOf('sw_worker_assignments/remove_job') >= 0) {
      url = 'sw_worker_assignments/remove_job';
    } else if (inUrl.indexOf('trans/mismo_3_4_upload') >= 0) {
      url = 'trans/mismo_3_4_upload';
    } else {
      url = inUrl.replace(/[\d]+/g, ':id');
      if (url.indexOf('trans/:id/transaction_items/') >= 0) {
        url = 'trans/:id/transaction_items/:str';
      }
    }

    if (!this.authTable.hasOwnProperty(url)) {
      console.log(error);
      return false;
    }

    if (!(this.authTable[url].indexOf(method) >= 0)) {
      console.log('Unauthorized URL', error);
      return false;
    }

    return true;
  }

  get(url: string, params: any = {}, useJSON?: boolean): Promise<any> {
    if (!this.isAuthorizedEndPoint('get', url)) {
      return Promise.reject({
        status: 401,
        message: 'Unauthorized'
      });
    }

    const httpParams = Object.keys(params).reduce((map, key) => map.set(key, params[key]), new HttpParams());
    return new Promise((resolve, reject) => {
      return this.http.get<any>(`${this.urlBase}/${this.getURL(url, useJSON)}`, {
        params: httpParams,
        headers: HttpService.generateRequestHeaders(url),
        observe: 'response'
      }).subscribe({
        next: (res: HttpResponse<any>) => {
          HttpService.processResponseHeaders(url, res);
          resolve(res.body);
        },
        error: (err) => {
          if (!this.handleError(err, url)) {
            reject(err);
          }
        }
      });
    });
  }

  delete(url: string, params = {}): Promise<any> {
    if (!this.isAuthorizedEndPoint('delete', url)) {
      return Promise.reject({
        status: 401,
        message: 'Unauthorized'
      });
    }
    const httpParams = Object.keys(params).reduce((map, key) => map.set(key, params[key]), new HttpParams());
    return new Promise((resolve, reject) => {
      return this.http.delete(`${this.urlBase}/${this.getURL(url, false)}`, {
        params: httpParams,
        headers: HttpService.generateRequestHeaders(url),
        observe: 'response'
      }).subscribe({
        next: (res: HttpResponse<any>) => {
          if (res.statusText && res.statusText.length === 0) {
            resolve(true);
          } else {
            resolve(res.body);
          }
        },
        error: (err) => {
          if (!this.handleError(err, url)) {
            reject(err);
          }
        }
      });
    });
  }

  post(url: string, data: any, useJSON?: boolean): Promise<any> {
    if (!this.isAuthorizedEndPoint('post', url)) {
      return Promise.reject({
        status: 401,
        message: 'Unauthorized'
      });
    }

    return new Promise((resolve, reject) => {
      return this.http.post(`${this.urlBase}/${this.getURL(url, useJSON)}`, data, {
        headers: HttpService.generateRequestHeaders(url),
        observe: 'response'
      }).subscribe({
        next: (res: HttpResponse<any>) => {
          HttpService.processResponseHeaders(url, res);
          resolve(res.body);
        },
        error: (err: HttpErrorResponse) => {
          if (!this.handleError(err, url)) {
            reject(err);
          }
        }
      });
    });
  }

  put(url: string, data: any, useJSON?: boolean): Promise<any> {
    if (!this.isAuthorizedEndPoint('put', url)) {
      return Promise.reject({
        status: 401,
        message: 'Unauthorized'
      });
    }

    return new Promise((resolve, reject) => {
      return this.http.put(`${this.urlBase}/${this.getURL(url, useJSON)}`, data, {
        headers: HttpService.generateRequestHeaders(url),
        observe: 'response'
      })
        .subscribe({
          next: (res: HttpResponse<any>) => {
            resolve(res.body);
          },
          error: (err) => {
            if (!this.handleError(err, url)) {
              reject(err);
            }
          }
        });
    });
  }

  getURL(url: string, useJSON?: boolean): string {
    return url;
  }

  handleError(err, url: string): boolean {
    if (globals.maintenance === true) {
      window.location.href = '/maintenance';
      return true;
    }
    if (err && err.status === 502) {
      // window.location.href = '/pages/page-500';
      return true;
    }
    if (err && err.status === 401) {
      // normal processing: we call get-session to check if user is already logged-in;
      // if he is not logged-in the server throws a 401
      // the 401 is handled by the caller and we therefore do nothing (save for returning false)
      if (url.indexOf('get_session') >= 0) {
        return false;
      }
      // if the server throws a 401 when session times-out OR
      // the resource the user was working on is no longer accessible to them
      // THEN we display the user a friendly 401 page and asks them to re-authenticate
      window.localStorage.clear();
      window.location.href = '/pages/page-401';
      return true;
    }
    return false;
  }
}
