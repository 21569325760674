<mat-card class="mt-2 mb-1" *ngIf="reloaded">
  <mat-card-title>
    Credit Report
    <div *ngIf="canUpdateDocument" class="row">
      <div class="col-lg-1">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'edit'" title="Edit"></app-line-header>
      </div>

      <div class="col-lg-10">
      </div>

      <div class="col-lg-1 text-end">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'delete'" title="Delete"></app-line-header>
      </div>
    </div>
  </mat-card-title>

  <mat-card-content>
    <div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Credit report name</div>
        <div class="col-lg-6">{{creditDoc.name}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Description</div>
        <div class="col-lg-6">{{creditDoc.description}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Created at</div>
        <div class="col-lg-6">{{creditDoc.created_at | date: 'medium'}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Prepared for</div>
        <div class="col-lg-6">{{getCreditPreparedFor(creditDoc.meta['section']).value}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Credit report provider</div>
        <div class="col-lg-6">{{creditDoc.getCreditProvider()}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Credit report reference</div>
        <div class="col-lg-6">{{creditDoc.meta.order_id}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Credit report type</div>
        <div class="col-lg-6">{{creditDoc.meta.credit_report_type}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Credit report status</div>
        <div class="col-lg-6">{{creditDoc.meta.status}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Experian score</div>
        <div class="col-lg-6">{{formatCreditScores(creditDoc.meta.scores.experian)}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Equifax score</div>
        <div class="col-lg-6">{{formatCreditScores(creditDoc.meta.scores.equifax)}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Trans Union score</div>
        <div class="col-lg-6">{{formatCreditScores(creditDoc.meta.scores.trans)}}</div>
      </div>

      <div class="row pt-3 pb-3">
        <div class="col">
          <button (click)="manage($event)" class="btn btn-lg btn-primary" *ngIf="isCreditReport">Manage Credit Report</button>
          <button (click)="poll($event)" class="ms-2 btn btn-lg btn-primary" *ngIf="!isCreditReport">Check Credit Report Status</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<div>
  <mat-spinner [diameter]="68" [hidden]="reloaded" class="ms-5 mb-3"></mat-spinner>
</div>
