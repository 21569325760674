import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Document} from '../../../models/document';
import {CompBaseComponent} from '../../comp-base.component';
import {globals} from '../../../shared/globals/globals';
import {Help} from '../../../models/help';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-view-loan-app',
  templateUrl: './view-loan-app.component.html',
})
export class ViewLoanAppComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public static section: string;
  public readonly localSection = globals.section;
  public currentSection: string;
  public pageTitle = 'Loan Application';
  public isCoBorrower: boolean;
  public borrowerCount: number;

  constructor(public injector: Injector, private route: ActivatedRoute) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.isMobile) {
      this.pageTitle = 'Loan Appl';
    }
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.loanApp
      });
    }, 0);
    this.route.queryParams.subscribe((params) => {
      if (params && params['fn'] === 'mcr') {
        this.setSection(null, globals.section.tools, globals.segment.mcr);
      }
      this.reload();
      this.reloadOnSync();
    });
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload();
      return;
    }
    this.eventEmitted.emit(event);
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.loanAppService.getCurrentLoanDocument()
      .then((document: Document) => {
        if (document) {
          this.doc = document;
          this.isCoBorrower = this.analyzerService.isCoBorrower(this.doc.recursive_attributes);
          this.borrowerCount = this.analyzerService.borrowerCount(this.doc.recursive_attributes);
        }
        this.currentSection = CompBaseComponent.currentLoanAppSection;
        // after an update, the entire view loan application is updated
        // since we want the user to see the update we emit the next event
        setTimeout(() => {
          this.reloaded = true;
          this.sharedService.emitChange({type: 'ReloadLoanAppSegment'});
        }, 0);
      });
  }

  setSection(event: any, section: string, segment: string): void {
    if (event) {
      event.preventDefault();
    }
    CompBaseComponent.currentLoanAppSection = section;
    this.currentSection = section;
    this.sharedService.emitChange({type: 'ReloadLoanAppTab', section: section, segment: segment});
    setTimeout(() => {
      globals.scrollTop();
    }, 0);
  }
}
