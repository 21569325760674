import {ModelBase} from './model-base';

export class FormTop extends ModelBase {
  borrower_count: string;
  borrower_groups: Array<Array<string>>;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      this[prop] = object[prop];
    }
    if (!this.borrower_groups) {
      this.borrower_groups = [];
    }
  }

  toServerPayload(): any {
    return {
      borrower_count: this.borrower_count,
      borrower_groups: this.borrower_groups,
    };
  }
}
