import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CreditReportService} from '../../../../../services/credit-report.service';
import {Document} from '../../../../../models/document';
import {FormUtil} from '../../../../form-util';
import {CreditReportComponent} from "../credit-report.component";

@Component({
  selector: 'app-manage-segment-credit-report',
  templateUrl: './manage-segment-credit-report.component.html',
})
export class ManageSegmentCreditReportComponent extends CreditReportComponent implements OnInit {
  @Input() creditDoc: Document;
  public creditReportService: CreditReportService;
  public preparedFor: any = {
    key: 'borrower_1',
    value: 'Borrower'
  };
  @Input() loanAppDoc: Document;

  // form and form controls
  form: FormGroup;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.preparedFor = this.getCreditPreparedFor(this.creditDoc.meta['section']);
    this.creditReportService = this.injector.get(CreditReportService);
    this.tran = this.tranService.getCurrentTranSync();
    this.createFormControls();
    this.createForm();
    this.reloaded = true;
  }

  createFormControls(): void {
  }

  createForm(): void {
    this.form = new FormGroup({});
  }

  populate(event: any): void {
    event.preventDefault();
    let moveToSection = null;
    moveToSection = this.preparedFor.key.split(',')[0];
    const payload = {
      section: moveToSection,
      credit_doc_id: this.creditDoc.id
    };
    this.reloaded = false;
    this.loanAppService.updateApplicantLiabilities(this.loanAppDoc.id, payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Loan application liabilities successfully populated');
        this.sharedService.emitChange({type: 'ReloadLoanApp'});
        this.eventEmitted.emit({type: 'Reload'});
        this.reloaded = true;
      })
      .catch((data) => {
        console.log('FAILED', data);
        FormUtil.genErrorMessage(this.sharedService, 'Failed to populate liabilities', data);
        this.reloaded = true;
      });
  }

  generate(event: any): void {
    event.preventDefault();
    this.reloaded = false;
    this.creditReportService.generateCreditPDF(this.creditDoc.id, this.tran.id)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Credit report PDF document was successfully generated and placed in <b>Repository/Documents</b>', false);
        this.sharedService.emitChange({type: 'ReloadLoanApp'});
        this.eventEmitted.emit({type: 'Reload'});
        this.reloaded = true;
      })
      .catch((data) => {
        console.log('FAILED', data);
        FormUtil.genErrorMessage(this.sharedService, 'Failed to generate credit report PDF document', data);
        this.reloaded = true;
      });
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
