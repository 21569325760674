import {ModelBase} from './model-base';

export class ProfessionalProfile extends ModelBase {
  id: number;
  user_id: number;
  company: string;
  office: string;
  phone: string;
  phone_ex: string;
  company_url: string;
  personal_url: string;
  about_me: string;
  about_my_company: string;
  hobbies: string;
  image_path: string; // from account settings
  logo_path: string;
  full_name: string; // from account settings
  // generated properties
  image_url: string;
  logo_url: string;
  is_image: boolean;
  is_logo: boolean;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      this[prop] = object[prop];
    }
    this.image_url = this.getImageUrl();
    this.logo_url = this.getLogoUrl();
    this.is_image = this.isImage();
    this.is_logo = this.isLogo();
  }

  isImage(): boolean {
    return this.image_path && this.image_path.length > 0;
  }

  isLogo(): boolean {
    return this.logo_path && this.logo_path.length > 0;
  }

  getImageUrl(): string {
    return 'api' + this.image_path;
  }

  getLogoUrl(): string {
    return 'api' + this.logo_path;
  }
}
