import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CompBaseComponent} from '../../../../comp-base.component';
import {LoanAppStructureService} from '../../../../../services/loan-app-structure.service';
import {Document} from '../../../../../models/document';
import {globals} from '../../../../../shared/globals/globals';
import {TranMcr} from '../../../../../models/tran-mcr';
import {FormUtil} from '../../../../form-util';
import {Mcr} from '../../../../../models/mcr';
import moment from 'moment';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from "@angular/router";

@Component({
  selector: 'app-mcr',
  template: '',
})
export class McrComponent extends CompBaseComponent implements OnInit {
  @Input() mcr: Mcr;
  public tranMcr: TranMcr;
  public readonly quarters = Mcr.quarters;
  public readonly years = Mcr.years;
  public property_type_codes = TranMcr.property_type_codes;
  public loan_disposition_codes = TranMcr.loan_disposition_codes;
  public loan_purpose_codes = TranMcr.loan_purpose_codes;
  public reverse_purpose_codes = TranMcr.reverse_purpose_codes;
  public loan_type_codes = TranMcr.loan_type_codes;
  public hecm_type_codes = TranMcr.hecm_type_codes;
  public lien_status_codes = TranMcr.lien_status_codes;
  public qm_codes = TranMcr.qm_codes;
  public readonly dateFormat = 'MM/DD/YYYY';
  public isEndDateRequired: boolean;
  public isHECMRequired: boolean;
  public isParamRequired: boolean;
  public loanAppStructure: LoanAppStructureService;
  public loan_app: any;
  public minDate = globals.date.minTranStartDate;
  public maxDate = globals.date.maxTranEndDate;
  public loanSummary: any;

  // form with one single button
  public form: FormGroup;
  public name: FormControl;
  public state: FormControl;
  public latestQuarter: FormControl;
  public latestYear: FormControl;
  public isHECM: FormControl;
  public isHOEPA: FormControl;
  public isBrokered: FormControl;
  public stateChanged: FormControl;
  public isRepurchased: FormControl;
  public loanAmount: FormControl;
  public prevLoanAmount: FormControl;
  public fromBorrower: FormControl;
  public loanDispositionCode: FormControl;
  public loanPurposeCode: FormControl;
  public propertyTypeCode: FormControl;
  public qmCode: FormControl;
  public loanTypeCode: FormControl;
  public hecmTypeCode: FormControl;
  public lienStatusCode: FormControl;
  public reversePurposeCode: FormControl;
  public startDate: FormControl;
  public endDate: FormControl;
  public brokerFee: FormControl;
  public lenderFee: FormControl;
  public helpMcr: string;

  constructor(public injector: Injector) {
    super(injector);
    this.loanAppStructure = this.injector.get(LoanAppStructureService);
  }

  ngOnInit() {
    // do not load loan-app because we are also handling no-loan mcrs (for organization).
    // See Organization/Loan Mcrs
    super.ngOnInit(false);
    this.setHelp();
    this.setDefaults();
  }

  setDefaults(): void {
    if (!this.doc) {
      return;
    }
    // console.log('doc.dtype', this.doc.recursive_attributes);
    const la = this.doc.recursive_attributes;

    // loan amount
    let loanAmount: number;
    const loanAmountString = la['loan_information']['loan_amount'];
    if (loanAmountString && loanAmountString.length > 0) {
      loanAmount = +loanAmountString;
    } else {
      loanAmount = null;
    }

    // property state
    const propertyState = la['property_information']['property_state'];

    // loan purpose
    let loanPurpose: string;
    const loanPurposeCode = la['loan_purpose']['loan_purpose_code_ex'];
    const refinancePurposeCode = la['loan_data']['refinance_purpose_code_ex'];
    if (loanPurposeCode === 'Purchase') {
      loanPurpose = 'AC300';
    } else if (loanPurposeCode === 'MortgageModification') {
      loanPurpose = 'AC320';
    } else if (loanPurposeCode === 'Refinance') {
      if (refinancePurposeCode === 'HomeImprovement') {
        loanPurpose = 'AC310';
      } else {
        loanPurpose = 'AC320';
      }
    }

    // loan type
    let lienStatus: string;
    const lien_type = la['loan_characteristics']['lien_type_code_ex'];
    if (lien_type === 'FirstLien') {
      lienStatus = 'AC500';
    } else if (lien_type === 'SecondLien' || lien_type === 'ThirdLien' || lien_type === 'FourthLien') {
      lienStatus = 'AC510';
    } else if (lien_type === 'Other') {
      lienStatus = 'AC520';
    }

    // hoepa
    let heopaStatus = null;
    const hoepaStatusString = la['loan_characteristics']['hoepa_indicator'];
    if (hoepaStatusString && hoepaStatusString.length > 0) {
      heopaStatus = hoepaStatusString === 'Y';
    }

    // loan type
    const mortgageCode = la['loan_information']['mortgage_type_ex'];
    let loanType: string;
    switch (mortgageCode) {
      case 'Conventional':
        loanType = 'AC100';
        break;
      case 'FHA':
        loanType = 'AC110';
        break;
      case 'USDARuralDevelopment':
        loanType = 'AC130';
        break;
      case 'VA':
        loanType = 'AC120';
        break;
    }

    if (!loanType) {
      if (mortgageCode && mortgageCode.length > 0) {
        loanType = 'PR099';
      } else {
        loanType = null;
      }
    }

    // QM type
    const qmTypeString = la['loan_characteristics']['qm_type'];
    let qmType: string;
    switch (qmTypeString) {
      case 'Standard':
        qmType = 'AC920';
        break;
      case 'NotAQualifiedMortgage':
        qmType = 'AC930';
        break;
      case 'NotApplicable':
        qmType = 'AC940';
        break;
    }

    // property type
    const propertyTypeCode = la['property_information']['property_type'];
    const multiFamily = la['property_information']['multi_family'];
    let propertyType: string = null;
    switch (propertyTypeCode) {
      case 'Manufactured':
        propertyType = 'AC210';
        break;
      case 'MobileHome':
      case 'Modular':
      case 'OnFrameModular':
      case 'SiteBuilt':
        propertyType = 'AC200';
        break;
      case 'Other':
        propertyType = 'AC200';
        if (multiFamily === 'Y') {
          propertyType = 'AC220';
        } else if (multiFamily === 'N') {
          propertyType = 'AC200';
        }
        break;
    }

    let isReverse = null;
    const isReverseString = la['loan_characteristics']['is_reverse'];
    if (isReverseString && isReverseString.length > 0) {
      isReverse = isReverseString === 'Y';
    }

    // start and end dates
    let wfStartDate: Date;
    const receivedDateStr = la['lo_information']['lo_received_date'];
    if (receivedDateStr && receivedDateStr.length === 8) {
      wfStartDate = moment(la['lo_information']['lo_received_date'], 'YYYYMMDD').toDate();
    } else {
      wfStartDate = this.tran ? this.tran.workflow.getLoanAppReceivedDate() : null;
    }

    const wfEndDate = this.tran ? this.tran.workflow.getLoanAppEndDate() : null;
    const wfDisp = this.tran ? this.tran.workflow.loan_disposition : null;
    let wfDispositionCode = null;
    switch (wfDisp) {
      case 'approved_not_accepted':
        wfDispositionCode = 'AC030';
        break;
      case 'denied':
        wfDispositionCode = 'AC040';
        break;
      case 'withdrawn':
        wfDispositionCode = 'AC050';
        break;
      case 'incomplete':
        wfDispositionCode = 'AC060';
        break;
      case 'funded':
        wfDispositionCode = 'AC070';
        break;
    }

    this.loanSummary = {
      loanAmount: loanAmount,
      propertyState: propertyState,
      lienStatus: lienStatus,
      loanPurpose: loanPurpose,
      hoepaStatus: heopaStatus,
      loanType: loanType,
      propertyType: propertyType,
      qmType: qmType,
      isHecm: isReverse,
      startDate: wfStartDate,
      endDate: wfEndDate,
      loanDisposition: wfDispositionCode
    };
  }

  populate(): void {
    this.loanAmount.setValue(this.loanSummary.loanAmount);
    this.state.setValue(this.loanSummary.propertyState);
    this.loanPurposeCode.setValue(this.loanSummary.loanPurpose);
    if (this.loanSummary.loanDisposition) {
      this.loanDispositionCode.setValue(this.loanSummary.loanDisposition);
    }
    this.lienStatusCode.setValue(this.loanSummary.lienStatus);
    this.isHOEPA.setValue(this.loanSummary.hoepaStatus);
    this.loanTypeCode.setValue(this.loanSummary.loanType);
    if (this.loanSummary.propertyType) {
      this.propertyTypeCode.setValue(this.loanSummary.propertyType);
    }
    if (this.loanSummary.qmType) {
      this.qmCode.setValue(this.loanSummary.qmType);
    }
    if (this.loanSummary.isReverse !== null) {
      this.isHECM.setValue(this.loanSummary.isHecm);
    }
    if (this.loanSummary.startDate !== null) {
      this.startDate.setValue(this.loanSummary.startDate);
    }
    if (this.loanSummary.endDate !== null) {
      this.endDate.setValue(this.loanSummary.endDate);
    }
  }

  createFormControls(): void {
    const tranMcr = this.mcr.tranMcr;
    const quarter = this.mcr.quarter;
    const year = this.mcr.year;
    this.name = new FormControl(this.mcr.name);
    this.state = new FormControl(this.mcr.state, Validators.pattern(globals.pattern.state));
    this.latestYear = new FormControl(year);
    this.latestQuarter = new FormControl(quarter);
    this.loanAmount = new FormControl(tranMcr.loan_amount);
    this.prevLoanAmount = new FormControl(tranMcr.prev_loan_amount);
    this.fromBorrower = new FormControl(tranMcr.from_borrower);
    this.qmCode = new FormControl(tranMcr.qm_code);
    this.loanDispositionCode = new FormControl(tranMcr.loan_disposition_code);
    this.propertyTypeCode = new FormControl(tranMcr.property_type_code);
    this.loanPurposeCode = new FormControl(tranMcr.loan_purpose_code);
    this.loanTypeCode = new FormControl(tranMcr.loan_type_code);
    this.hecmTypeCode = new FormControl(tranMcr.hecm_type_code);
    this.lienStatusCode = new FormControl(tranMcr.lien_status_code);
    this.reversePurposeCode = new FormControl(tranMcr.reverse_purpose_code);
    this.isHECM = new FormControl(tranMcr.is_hecm);
    this.isHOEPA = new FormControl(tranMcr.is_hoepa);
    this.isBrokered = new FormControl(tranMcr.is_brokered);
    this.stateChanged = new FormControl(tranMcr.state_changed);
    this.isRepurchased = new FormControl(tranMcr.is_repurchased);
    if (tranMcr.start_date) {
      this.startDate = new FormControl(moment(tranMcr.start_date as string, this.dateFormat).toISOString());
    } else {
      this.startDate = new FormControl(null);
    }
    if (tranMcr.end_date) {
      this.endDate = new FormControl(moment(tranMcr.end_date as string, this.dateFormat).toISOString());
    } else {
      this.endDate = new FormControl(null);
    }
    this.brokerFee = new FormControl(tranMcr.broker_fee);
    this.lenderFee = new FormControl(tranMcr.lender_fee);
    this.tranMcr = tranMcr;
  }

  createForm(): void {
    this.form = new FormGroup({
      name: this.name,
      state: this.state,
      latestYear: this.latestYear,
      latestQuarter: this.latestQuarter,
      loanAmount: this.loanAmount,
      prevLoanAmount: this.prevLoanAmount,
      fromBorrower: this.fromBorrower,
      loanDispositionCode: this.loanDispositionCode,
      propertyTypeCode: this.propertyTypeCode,
      loanPurposeCode: this.loanPurposeCode,
      reversePurposeCode: this.reversePurposeCode,
      loanTypeCode: this.loanTypeCode,
      hecmTypeCode: this.hecmTypeCode,
      lienStatusCode: this.lienStatusCode,
      isHOEPA: this.isHOEPA,
      isBrokered: this.isBrokered,
      stateChanged: this.stateChanged,
      isHECM: this.isHECM,
      isRepurchased: this.isRepurchased,
      startDate: this.startDate,
      endDate: this.endDate,
      brokerFee: this.brokerFee,
      lenderFee: this.lenderFee,
      qmCode: this.qmCode
    });
  }

  // val is the declaration name
  onChange(event: any, val: string): void {
    let boolValue: boolean;
    if (typeof (event.value) === 'boolean') {
      boolValue = event.value;
    } else if (typeof (event.value) === 'string') {
      if (event.value === 'true') {
        boolValue = true;
      } else if (event.value === 'false') {
        boolValue = true;
      } else {
        boolValue = null;
      }
    } else {
      boolValue = null;
    }
    this[val].setValue(boolValue);
  }

  edit(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'Edit'});
  }

  update(): void {
    this.sharedService.emitChange({type: 'EmptyMessages'});
    const state = this.state.value ? this.state.value : null;
    const startDate = this.startDate.value ? moment(this.startDate.value).format(this.dateFormat) : null;
    let endDate = this.endDate.value ? moment(this.endDate.value).format(this.dateFormat) : null;
    const brokerFee = this.brokerFee.value ? +this.brokerFee.value : null;
    const lenderFee = this.lenderFee.value ? +this.lenderFee.value : null;
    const isHECM = this.isHECM.value !== null ? this.isHECM.value : null;
    const isHOEPA = this.isHOEPA.value !== null ? this.isHOEPA.value : null;
    const isBrokered = this.isBrokered.value !== null ? this.isBrokered.value : null;
    const stateChanged = this.stateChanged.value !== null ? this.stateChanged.value : null;
    const isRepurchased = this.isRepurchased.value !== null ? this.isRepurchased.value : null;
    const loanAmount = this.loanAmount.value ? +this.loanAmount.value : null;
    const prevLoanAmount = this.prevLoanAmount.value ? +this.prevLoanAmount.value : null;
    const fromBorrower = this.fromBorrower.value !== null ? this.fromBorrower.value : null;
    const loanPurposeCode = this.loanPurposeCode.value ? this.loanPurposeCode.value : null;
    let reversePurposeCode = this.reversePurposeCode.value ? this.reversePurposeCode.value : null;
    const loanDispositionCode = this.loanDispositionCode.value ? this.loanDispositionCode.value : null;
    const loanTypeCode = this.loanTypeCode.value ? this.loanTypeCode.value : null;
    const propertyTypeCode = this.propertyTypeCode.value ? this.propertyTypeCode.value : null;
    let hecmTypeCode = this.hecmTypeCode.value ? this.hecmTypeCode.value : null;
    const lienStatusCode = this.lienStatusCode.value ? this.lienStatusCode.value : null;
    const qmCode = this.qmCode.value ? this.qmCode.value : null;
    const tranMcr = this.mcr.tranMcr;
    const tranId = this.tran ? this.tran.id : null;
    tranMcr.tran_id = tranId;

    /* Prune unnecessary data that can only create more confusion */
    if (isHECM === false) {
      reversePurposeCode = null;
      hecmTypeCode = null;
    }
    if (loanDispositionCode === 'AC080') {
      endDate = null;
    }

    // verify that end date is not before start date.
    if (startDate && endDate && moment(endDate, this.dateFormat).isBefore(moment(startDate, this.dateFormat))) {
      FormUtil.genErrorMessage(this.sharedService, 'End date cannot be before start date', null);
      return;
    }

    // Verify that start date is not after the end of the quarter.
    // Comment: in moment(), month is zero-based.
    // Get the first day of the quarter and based on that, get the last day of the quarter.
    const endOfMCRQuarter = moment([this.mcr.year, (this.mcr.quarter-1)*3, 1]).endOf('quarter');
    if (startDate && (moment(startDate, this.dateFormat).isAfter(endOfMCRQuarter))) {
      FormUtil.genErrorMessage(this.sharedService, 'Start date is after the end of the MCR quarter', null);
      return;
    }

    // if end date is not required, nullify it to avoid downstream errors.
    if (!this.isEndDateRequired) {
      endDate = null;
    }

    tranMcr.loan_amount = loanAmount;
    tranMcr.prev_loan_amount = prevLoanAmount;
    tranMcr.from_borrower = fromBorrower;
    tranMcr.loan_disposition_code = loanDispositionCode;
    tranMcr.loan_purpose_code = loanPurposeCode;
    tranMcr.reverse_purpose_code = reversePurposeCode;
    tranMcr.property_type_code = propertyTypeCode;
    tranMcr.loan_type_code = loanTypeCode;
    tranMcr.hecm_type_code = hecmTypeCode;
    tranMcr.lien_status_code = lienStatusCode;
    tranMcr.qm_code = qmCode;
    tranMcr.is_hecm = isHECM;
    tranMcr.is_hoepa = isHOEPA;
    tranMcr.is_brokered = isBrokered;
    tranMcr.state_changed = stateChanged;
    tranMcr.is_repurchased = isRepurchased;
    tranMcr.start_date = startDate;
    tranMcr.end_date = endDate;
    tranMcr.broker_fee = brokerFee;
    tranMcr.lender_fee = lenderFee;
    const payload = {name: this.name.value, quarter: this.latestQuarter.value, year: this.latestYear.value, state: state, tran_id: tranId, content: {mcr: tranMcr.toServerPayload()}};
    this.mcrService.updateMcr(this.mcr.id, payload)
      .then(() => {
        // update may be called from loan/tools or from mcr. The backlink is used to go back to the MCR Wizard
        if (this.backLink && (this.backLink.length > 0)) {
          this.injector.get(Router).navigate([this.backLink]);
        } else {
          this.eventEmitted.emit({type: 'Reload'});
        }
        FormUtil.genSuccessMessage(this.sharedService, 'MCR successfully updated');
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update mcr', data);
        console.log('BAD: mcr update() failed');
      });
  }

  cancel(): void {
    // update may be called from loan/tools or from mcr. The backlink is used to go back to the MCR Wizard
    if (this.backLink && (this.backLink.length > 0)) {
      this.injector.get(Router).navigate([this.backLink]);
    } else {
      this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
    }
  }

  getSectionName(code) {
    return this.loanAppStructure.getSectionName(code);
  }

  getSegmentName(code) {
    return this.loanAppStructure.getSegmentName(code);
  }

  setHelp(): void {
    const helpMcr = `<p>
    The responses to the Loan Level MCR are necessary for the compilation of your organization's
    NMLS quarterly MCR report. Please fill out accurately all fields.
    </p>
    <p>
    <span class="font-weight-bold">Fields.</span> All fields are <span class="font-weight-bold">required</span> except for
    "Previous loan amount" and "Broker Fees" (or "lender Fees" if your company funded the loan)
    if the loan is still in-process.
    </p>
    <p>
    <span class="font-weight-bold">Reporting quarters.</span> The lifetime of a loan may span one or more quarters.
    For example, the loan application may be submitted in Q1 and funded in Q2.
    In such a case, this loan would factor in two MCR reports (for Q1 and Q2).
    For the Q2 Loan Level MCR, you only need to add an additional quarter and
    update the form as necessary. To add or delete a quarter, click
    <span class="font-weight-bold">Manage Quarters</span> in this form.
    </p>
    <p>
    <span class="font-weight-bold">Previous loan amount.</span>
    This field is only applicable if the loan amount has changed between subsequent quarters.
    If the loan amount changed, fill-in the Previous loan amount.
    POLOS populates the applicable fields in the MCR report based on the difference
    between the current and the previous loan amounts.
    </p>`;
    this.helpMcr = this.injector.get(DomSanitizer).bypassSecurityTrustHtml(helpMcr) as string;
  }
}
