<div *ngIf="reloaded&&user">
  <div class="row mt-2 mb-2">
    <div class="col-lg-4">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Profile Picture</mat-card-title>
        </mat-card-header>
        <mat-card-content class="text-center">
          <img [src]="user.getImagePath()" style="max-width: 200px;">
          <div *ngIf="!user.is_attached_image">
            No Picture
          </div>
        </mat-card-content>
        <mat-card-actions *ngIf="user.is_attached_image" class="mt-3">
          <div style="display: flex; flex-direction: row;justify-content: space-between;width:100%">
            <div style="flex: 50%">
              <button (click)="replaceImage()" class="btn btn-lg btn-primary">Replace Picture</button>
            </div>
          </div>
          <div style="flex: 50%">
            <button (click)="deleteImage()" class="btn btn-lg btn-danger">Delete Picture</button>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <app-upload-file *ngIf="mode === 'Update' || !user.is_attached_image" (cancel)="onCancel()" (uploadComplete)="onUploadComplete()" [uploadType]="'image'" [multipleFiles]="false" actionUrl="account/photo_upload" title="Upload Picture">
  </app-upload-file>
</div>
