<div *ngIf="reloaded" class="row">
  <div *ngIf="!isMobile || isOpen" class="col-lg-2 col">
    <ul class="pt-4">
      <li [ngClass]="{active:currentSeg===localSeg.newUser}">
        <span></span>
        <a (click)="setSeg($event,localSeg.newUser)" href="" [ngClass]="{'active': isActive(localSeg.newUser)}" id="new_user">Invite User</a>
      </li>

      <li *ngIf="!!currentTran && canCreateTransaction" [ngClass]="{active:currentSeg===localSeg.newEmail}">
        <span></span>
        <a (click)="setSeg($event,localSeg.newEmail)" href="" [ngClass]="{'active': isActive(localSeg.newEmail)}" id="new_email">Orientation Email</a>
      </li>
    </ul>
  </div>

  <div *ngIf="!(isMobile&&isOpen)" class="col-lg-10 col">
    <div [ngSwitch]="currentSeg">
      <app-send-invite-email (eventEmitted)="onEvent($event)" *ngSwitchCase="localSeg.newUser">
      </app-send-invite-email>
      <app-send-orientation-email (eventEmitted)="onEvent($event)" *ngSwitchCase="localSeg.newEmail">
      </app-send-orientation-email>
      <app-send-upload-email (eventEmitted)="onEvent($event)" *ngSwitchCase="localSeg.uploadPDF">
      </app-send-upload-email>
    </div>
  </div>
</div>
