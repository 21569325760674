<div *ngIf="!doneGrouping">
  <div class="dnd-container">
    <h5>Group I</h5>
    <div id="Group1"
      cdkDropList
      #groupList1="cdkDropList"
      [cdkDropListData]="group1"
      [cdkDropListConnectedTo]="[borList]"
      class="dnd-list"
      (cdkDropListDropped)="drop($event)">
      @for (item of group1; track item) {
      <div class="dnd-box" cdkDrag>{{item}}</div>
      }
    </div>
  </div>

  <div [hidden]="borrowerCount <= 1" class="dnd-container">
    <h5>Group II</h5>
    <div id="Group2"
      cdkDropList
      #groupList2="cdkDropList"
      [cdkDropListData]="group2"
      [cdkDropListConnectedTo]="[borList]"
      class="dnd-list"
      (cdkDropListDropped)="drop($event)">
      @for (item of group2; track item) {
      <div class="dnd-box" cdkDrag>{{item}}</div>
      }
    </div>
  </div>

  <div [hidden]="borrowerCount <= 2" class="dnd-container">
    <h5>Group III</h5>
    <div id="Group3"
      cdkDropList
      #groupList3="cdkDropList"
      [cdkDropListData]="group3"
      [cdkDropListConnectedTo]="[borList]"
      class="dnd-list"
      (cdkDropListDropped)="drop($event)">
      @for (item of group3; track item) {
      <div class="dnd-box" cdkDrag>{{item}}</div>
      }
    </div>
  </div>

  <div [hidden]="borrowerCount <= 3" class="dnd-container">
    <h5>Group IV</h5>
    <div id="Group4"
      cdkDropList
      #groupList4="cdkDropList"
      [cdkDropListData]="group4"
      [cdkDropListConnectedTo]="[borList]"
      class="dnd-list"
      (cdkDropListDropped)="drop($event)">
      @for (item of group4; track item) {
      <div class="dnd-box" cdkDrag>{{item}}</div>
      }
    </div>
  </div>

  <div class="dnd-container">
    <h5>Borrowers</h5>
    <div id="borrowerBucket"
      cdkDropList
      #borList="cdkDropList"
      [cdkDropListData]="borrowerList"
      [cdkDropListConnectedTo]="[groupList1, groupList2, groupList3, groupList4]"
      class="dnd-list"
      (cdkDropListDropped)="drop($event)">
      @for (item of borrowerList; track item) {
      <div class="dnd-box" cdkDrag>{{item}}</div>
      }
    </div>
  </div>
</div>

<div *ngIf="doneGrouping">
  <div class="row">
    <div *ngFor="let group of borrowerNameGroups; index as i" class="col-lg-3">
      <h5>Group {{convertToRoman(i)}}</h5>
      <div *ngFor="let borName of group; index as j" class="bg-success p-2 mb-2 text-white">
        {{j == 0 ? 'Borrower' : 'Co-Borrower'}}: {{borName}}
      </div>
    </div>
  </div>
</div>

<div>
  <button class="btn btn-lg btn-primary" *ngIf="!doneGrouping" [disabled]="!isDone()" (click)="onDone()">Done Grouping</button>
  <button class="btn btn-lg btn-secondary" [ngClass]="{'ms-2':!doneGrouping}" (click)="reset()">Reset Grouping</button>
</div>
