import {Component, EventEmitter, HostBinding, Injector, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DeviceService} from '../../services/device.service';
import {SharedService} from '../../layouts/shared-service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-segment-header',
  templateUrl: './segment-header.component.html',
})
export class SegmentHeaderComponent implements OnInit {
  @HostBinding('style.width') segmentHeaderWidth = '100%';
  @HostBinding('style.display') segmentHeaderDisplay = 'inline-block';
  @Input() enable = true;
  @Input() op: string = '';
  @Input() header: string;
  @Input() help: boolean;
  @Output() eventEmitted = new EventEmitter();
  @Input() segmentWidth: string;
  @Input() clickable = false;
  @Input() isCard = true;
  public isEdit: boolean;
  public isAdd: boolean;
  public safeHeader: string;
  public displayHelp = false;
  public isMobile: boolean;
  public sharedService: SharedService;
  public displayBars: boolean;

  constructor(private injector: Injector, private router: Router) {
    this.isMobile = DeviceService.isMobile;
  }

  onClick(what): void {
    if (what === 'edit') {
      this.eventEmitted.emit({type: 'ChangeMode', mode: 'Edit'});
      return;
    }
    if (what === 'add') {
      this.eventEmitted.emit({type: 'ChangeMode', mode: 'Add'});
      return;
    }
    if (what === 'header') {
      this.eventEmitted.emit({type: 'Header'});
      return;
    }
  }

  toggleHelp(event: any): void {
    event.preventDefault();
    this.displayHelp = !this.displayHelp;
  }

  toggleMenu(event: any): void {
    event.preventDefault();
    this.sharedService.emitChange({type: 'ToggleNestedMenu'});
  }

  ngOnInit() {
    if (this.op === 'add_edit') {
      this.isAdd = true;
      this.isEdit = true;
    } else if (this.op === 'add') {
      this.isAdd = true;
      this.isEdit = false;
    } else if (this.op === 'edit') {
      this.isAdd = false;
      this.isEdit = true;
    }
    if (this.segmentWidth && this.segmentWidth.length > 0) {
      this.segmentHeaderWidth = this.segmentWidth;
    }
    this.displayBars = this.isMobile && (this.router.url === '/loan/view-loan-app' || this.router.url === '/comm/view-messages');
    this.header = this.header || '';
    this.sharedService = this.injector.get(SharedService);
    // todo: dig into this one
    const icon = '<i class="text-warning fas fa-eye-slash"></i>';
    this.safeHeader = this.injector.get(DomSanitizer).bypassSecurityTrustHtml(this.header) as string;
  }
}
