<div *ngIf="reloaded">
  <app-box *ngIf="!isDefaultLoanApp" [type]="'warning'" class="mt-2">
    You are unable to generate documents because a <span class="fw-bold">default loan application</span> is not available.
    To proceed, create a loan application and/or set it as the default in the <span class="fw-bold">Loan Applications</span> tab.
  </app-box>
  <app-box *ngIf="isDefaultLoanApp&&(!txSummary||!txSummary['propertyState'])" [type]="'warning'" class="mt-2">
    You cannot generate documents because the property information in the <a routerLink="/loan/view-loan-app">loan application</a> is missing the property's state.
  </app-box>

  <mat-card *ngIf="isDefaultLoanApp&&txSummary&&txSummary['propertyState']" class="mb-1">
    <mat-card-title>
      <app-segment-header *ngIf="!getDocTitle(docCode.value)" (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Choose document type'" [help]="true" [op]="''">
        With Generate Documents, you can produce PDF documents for loans.
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>
            All information for the URLA documents is sourced from the loan application.
          </li>
          <li>
            Default values for several fields in the other documents are also derived from the loan application.
          </li>
          <li>
            Each non-URLA document must be configured prior to its creation.
          </li>
          <li>
            Before printing any non-URLA document, it is necessary to configure it first.
          </li>
        </ul>
      </app-segment-header>
      <app-segment-header *ngIf="getDocTitle(docCode.value)" (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="getDocTitle(docCode.value)" [help]="true" [op]="''">
        With Generate Documents, you can produce PDF documents for loans.
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>
            All information for the URLA documents is sourced from the loan application.
          </li>
          <li>
            Default values for several fields in the other documents are also derived from the loan application.
          </li>
          <li>
            Each non-URLA document must be configured prior to its creation.
          </li>
          <li>
            Before printing any non-URLA document, it is necessary to configure it first.
          </li>
        </ul>
      </app-segment-header>

    </mat-card-title>
    <div class="ms-4 mb-3">&ast;&nbsp;Property State: {{txSummary['propertyState']}}</div>
    <mat-card-content>
      <form [formGroup]="form">
        <div class="row mb-5">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>State Abbreviation</mat-label>
              <input (change)="stateChanged()" formControlName="state" matInput placeholder="State Abbreviation" minlength="2"  required>
              <mat-error *ngIf="state.hasError('required')">
                State is required
              </mat-error>
              <mat-error *ngIf="state.hasError('minlength')">
                State abbreviation is invalid
              </mat-error>
              <mat-hint>You can get access to state document(s) other than the loan property state.
                Separate state abbreviations by comma or space.
                E.g., "CO" for Colorado, "CO IL" for Colorado and Illinois.
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Document type</mat-label>
              <mat-select formControlName="docCode" placeholder="Select document type">
                <mat-option *ngFor="let doc_code of doc_codes" [value]="doc_code.key">
                  {{getDocTitle(doc_code.key)}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="docCode.hasError('required')">
                Document type is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="folders.length" class="row mb3">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Folder</mat-label>
              <mat-select formControlName="folder_id" placeholder="Select folder">
                <mat-option *ngFor="let folder of folders"
                  [value]="folder.key">{{folder.value}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="folder_id.hasError('required')">
                Folder is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <button (click)="updateParams(docCode.value)" [disabled]="!form.valid" class="btn btn-lg btn-primary">Go</button>
        <button (click)="cancelUpdate($event)" class="ms-2 btn btn-lg btn-secondary">Cancel</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
