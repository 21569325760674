import {Injectable} from '@angular/core';
import moment from 'moment';
import {DataService} from './data.service';
import {Util} from '../content/util';
import {Liability} from '../models/liability';

const dateFormat = 'YYYYMMDD';

@Injectable()
export class AnalyzerService {
  constructor() {
  }

  // if any form-top checkbox is checked then there is a co-borrower
  static borrowerCount(loanApp): number {
    return +loanApp.form_top.borrower_count;
  }

  static isCoBorrower(loanApp) {
    return +loanApp.form_top.borrower_count >= 2;
  }

  // Comment: this utility convert 10.0% to 10.0!
  static convertToDecimal(inp) {
    switch (typeof (inp)) {
      case 'number':
        return inp;
      case 'string':
        return Number(inp.replace(/[^0-9\.]/g, ''));
      default:
        return 0.0;
    }
  }

  isDefined(value) {
    if (typeof (value) === 'undefined' || value === null) {
      return false;
    }

    if ((typeof (value) === 'object') || typeof (value) === 'boolean') {
      return true;
    }

    if (typeof (value) === 'string') {
      return value.trim().length;
    }
    return true;
  }

  // verify that all proper properties are empty
  isEmpty(obj): boolean {
    if (typeof (obj) === 'undefined' || obj === null) {
      return true;
    }
    return Object.keys(obj).every((key) => key === 'ssn' ? true : !this.isDefined(obj[key]));
  }

  errorCheck(loanApp: any, section: string, segmentName: string) {
    if (!loanApp || !section || !segmentName) {
      return false;
    }

    // this.compute function name from snake case
    const segmentCamelCase = segmentName.split('_').map((word) => {
      return word[0].toUpperCase() + word.substr(1);
    }).join('');
    const functionName = 'verify' + segmentCamelCase;

    // safeguard against failures when introducing new features
    if (this[functionName]) {
      return this[functionName].call(this, loanApp, section, segmentName);
    } else {
      console.log(`FAILED: errorCheck failed for segment ${segmentName}.`);
      return [];
    }
  }

  verifyBorrower1(loanApp, section, segmentName) {
    return this.verifyApplicant(loanApp, section, segmentName);
  }

  verifyBorrower2(loanApp, section, segmentName) {
    return this.verifyApplicant(loanApp, section, segmentName);
  }

  verifyBorrower3(loanApp, section, segmentName) {
    return this.verifyApplicant(loanApp, section, segmentName);
  }

  verifyBorrower4(loanApp, section, segmentName) {
    return this.verifyApplicant(loanApp, section, segmentName);
  }

  verifyApplicant(loanApp, sect, segmentNam) {
    const segment = loanApp[sect];
    let message;
    const messages = [];

    if (this.isEmpty(segment)) {
      message = {
        section: sect,
        segment: segmentNam,
        errorLevel: 2,
        text: this.formatSectionMessage(sect, 'Applicant information is empty.')
      };
      messages.push(message);
      return messages;
    }

    if (!this.verifyRequiredFields(segment, ['ssn', 'first', 'last', 'dob'])) {
      message = {
        section: sect,
        segment: segmentNam,
        errorLevel: 2,
        text: this.formatSectionMessage(sect,
          'Applicant information segment is missing some of the required fields: SSN, first name, last name, DOB.')

      };
      messages.push(message);
    }

    // todo: do we need age?
    if (segment['dob'] && segment['age'] && segment['age'].length > 0) {
      const inputAge = segment['age'];
      const computedAge = moment().diff(moment(segment['dob'], dateFormat), 'years');
      if ((inputAge < computedAge) || inputAge > (computedAge + 1)) {
        message = {
          section: sect,
          segment: segmentNam,
          errorLevel: 2,
          text: this.formatSectionMessage(sect,
            `Computed age based on DOB: ${computedAge} is different than input age: ${inputAge}.`)
        };
        messages.push(message);
      }
    }

    return messages;
  }

  verifyEmployment(loanApp, section, segmentName) {
    const applicant = loanApp[section];
    const segment = applicant[segmentName];
    let message;
    const messages = [];

    if (this.isEmpty(segment)) {
      const errorLevel = applicant === 'borrower_1' ? 2 : 1;
      message = {
        section: section,
        segment: segmentName,
        errorLevel: errorLevel,
        text: this.formatSectionMessage(section, 'Employment is empty.')
      };
      messages.push(message);
    } else {
      if (!this.verifyRequiredFields(segment, ['address', 'city', 'state', 'zip'])) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 1,
          text: this.formatSectionMessage(section,
            'Employment segment is missing some of the address fields (address, city, state, or zip).')
        };
        messages.push(message);
      }
      if (!this.verifyRequiredFields(segment, ['employer_name'])) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 1,
          text: this.formatSectionMessage(section, 'Employment segment is missing employer name.')

        };
        messages.push(message);
      }
    }

    return messages;
  }

  verifySecondaryEmployments(loanApp, section, segmentName) {
    const applicant = loanApp[section];
    const collection = applicant[segmentName];
    let message;
    const messages = [];

    collection.forEach((segment) => {

      const requiredFields = ['from_date', 'to_date'];
      if (!this.verifyRequiredFields(segment, requiredFields)) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: this.formatSectionMessage(section,
            'Previous employment segment is missing some of the required fields: from date, to date.')
        };
        messages.push(message);
        return;
      }

      if (!this.verifyRequiredFields(segment, ['address', 'city', 'state', 'zip'])) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 1,
          text: this.formatSectionMessage(section,
            'Previous employment segment is missing some of the address fields: address, city, state, or zip.')
        };
        messages.push(message);
      }

      if (!this.verifyRequiredFields(segment, ['employer_name'])) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 1,
          text: this.formatSectionMessage(section, 'Previous employment segment is missing employer name.')
        };
        messages.push(message);
      }

      if (moment(segment['from_date'], dateFormat) > moment()) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: this.formatSectionMessage(section, 'Previous employment segment "from date" is in the future.')
        };
        messages.push(message);
      }

      if ((segment.currently_employed !== 'Y') && (moment(segment.from_date, dateFormat) > moment(segment.to_date, dateFormat))) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: this.formatSectionMessage(section, 'Previous employment "from date" is more recent than "to date".')

        };
        messages.push(message);
      }
    });

    return messages;
  }

  verifyDependents(loanApp, section, segmentName) {
    const applicant = loanApp[section];
    let message;
    const messages = [];

    const collection = applicant.dependents;
    if (collection.length === 0) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 0,
        text: this.formatSectionMessage(section, 'No dependents data.')
      };
      messages.push(message);
    }
    return messages;
  }

  // Feb 14, 2020 verified code and tested it
  verifyAddresses(loanApp, section, segmentName) {
    const applicant = loanApp[section];
    let message;
    const messages = [];

    const collection = applicant.addresses;
    if (collection.length === 0) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: this.formatSectionMessage(section, 'No address data.')
      };
      messages.push(message);
      return messages;
    }
    // check for the count of present addresses
    const count = collection.reduce((memo, segment) => {
      if (segment['residence_type_ex'] === 'Current') {
        memo += 1;
      }
      return memo;
    }, 0);
    // check count of present addresses (there should be exactly one)
    if (count === 0) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: 'No present address data segment.'
      };
      messages.push(message);
    }

    if (count > 1) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: 'More than one "present address" data segment.'
      };
      messages.push(message);
    }

    if (count >= 1) {
      const totalMonths = collection.reduce((memo, segment) => {
        if (segment['residence_type_ex'] === 'Current' || segment['residence_type_ex'] === 'Prior') {
          memo += (segment['years_no'] * 12 + segment['months_no'] * 1);
        }
        return memo;
      }, 0);
      if (totalMonths < 24) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: 'Must provide address history for the past two years (24 months). Address history provided is for ' + totalMonths + ' months.'
        };
        messages.push(message);
      }
    }

    collection.forEach((segment) => {
      // if mailing address, residence term does not apply
      if (segment['residence_type_ex'] === 'Mailing') {
        if (!this.verifyRequiredFields(segment, ['address', 'city', 'state', 'zip'])) {
          message = {
            section: section,
            segment: segmentName,
            errorLevel: 2,
            text: DataService.getResidenceTypes()[segment.residence_type_ex] + ' segment is missing some of the required fields:  address, city, state, zip.'
          };
          messages.push(message);
        }
      } else {
        // not a mailing address
        if (!segment['country'] || segment['country'] === 'USA' || segment['country'] === 'United States of America') {
          // living in the uUSA
          if (!this.verifyRequiredFields(segment, ['residence_type_ex', 'residence_term_ex', 'address', 'city', 'state', 'zip'])) {
            message = {
              section: section,
              segment: segmentName,
              errorLevel: 2,
              text: DataService.getResidenceTypes()[segment.residence_type_ex] + ' segment is missing some of the required fields: residence type, residence term, address, city, state, zip.'
            };
            messages.push(message);
          }
        } else {
          if (!this.verifyRequiredFields(segment, ['residence_type_ex', 'residence_term_ex', 'address', 'city'])) {
            message = {
              section: section,
              segment: segmentName,
              errorLevel: 2,
              text: DataService.getResidenceTypes()[segment.residence_type_ex] + ' segment is missing some of the required fields: residence type, residence term, address, city.'
            };
            messages.push(message);
          }
        }
      }
    });
    return messages;
  }

  verifyIncomes(loanApp, section, segmentName) {
    const applicant = loanApp[section];
    let message;
    const messages = [];

    const collection = applicant.incomes;
    if (collection.length === 0) {
      const errorLevel = section === 'borrower_1' ? 1 : 1;
      message = {
        section: section,
        segment: segmentName,
        errorLevel: errorLevel,
        text: this.formatSectionMessage(section, 'No income data.')
      };
      messages.push(message);
    } else {
      collection.forEach((item) => {
        if (!this.verifyRequiredFields(item, ['code_ex', 'amount'])) {
          message = {
            section: section,
            segment: segmentName,
            errorLevel: 2,
            text: this.formatSectionMessage(section, 'Income is missing some of the required fields: income type, amount.')
          };
          messages.push(message);
        }
      });
    }
    return messages;
  }

  // verify expenses only for borrower
  verifyLaExpenses(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const collection = loanApp.la_expenses.coll;

    if (collection.length === 0) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: this.formatSectionMessage(section, 'No monthly housing expenses data.')
      };
      messages.push(message);
    } else {
      collection.forEach((item) => {
        if (!this.verifyRequiredFields(item, ['expense_indicator_ex', 'code_ex', 'amount'])) {
          message = {
            section: section,
            segment: segmentName,
            errorLevel: 2,
            text: this.formatSectionMessage(section,
              'Expense segment is missing some of the required fields: expense indicator, expense type, amount.')
          };
          messages.push(message);
        }
      });
      const expenseIndicator = this.isPurchaseLoan(loanApp) ? 'Proposed' : 'Present';
      let found = collection.find((expense) => {
        return expense.expense_indicator_ex === expenseIndicator && expense.code_ex === 'RealEstateTax';
      });
      if (!found) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: this.formatSectionMessage(section, `No ${expenseIndicator} real estate tax expense entry.`)
        };
        messages.push(message);
      }
      found = collection.find((expense) => {
        return expense.expense_indicator_ex === expenseIndicator && expense.code_ex === 'HomeownersInsurance';
      });
      if (!this.isCondoLoan(loanApp) && !found) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: this.formatSectionMessage(section, `No ${expenseIndicator} homeowners insurance expense entry.`)
        };
        messages.push(message);
      }

      found = collection.find((expense) => {
        return expense.expense_indicator_ex === 'Proposed' && expense.code_ex === 'HomeownersAssociationDuesAndCondominiumFees';
      });
      if (this.isCondoLoan(loanApp) && !found) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: this.formatSectionMessage(section, 'No proposed Homeowner Association (HOA) dues entry.')
        };
        messages.push(message);
      }

    }
    return messages;
  }

  verifyLaAssets(loanApp, section, segmentName) {
    const messages = [];
    const collection = loanApp.la_assets;
    if (collection.length === 0) {
      const message = {
        section: section,
        segment: segmentName,
        errorLevel: 1,
        text: this.formatSectionMessage(section, 'No assets.')
      };
      messages.push(message);
    } else {
      collection.forEach((item, index) => {
        const partyIds = item.party_ids.split(',');
        const borrowerCount = this.borrowerCount(loanApp);
        partyIds.forEach((elt) => {
          const borrowerIndex = +elt[1];
          if (borrowerIndex > borrowerCount) {
            const who = this.getBorrowerTitle(borrowerIndex);
            const message = {
              section: section,
              segment: segmentName,
              errorLevel: 2,
              text: this.formatSectionMessage(section, `Asset #${index + 1} is owned by ${who} but there ${borrowerCount <= 1 ? 'is' : 'are'} only ${borrowerCount} ${borrowerCount <= 1 ? 'borrower' : 'borrowers'}.`)
            };
            messages.push(message);
          }
        });
        if (!this.verifyRequiredFields(item, ['asset_type_ex', 'institution_market_value'])) {
          const message = {
            section: section,
            segment: segmentName,
            errorLevel: 2,
            text: this.formatSectionMessage(section, `Asset #${index + 1} is missing some of the required fields: asset type, market value.`)
          };
          messages.push(message);
        }
      });
    }
    return messages;
  }

  verifyLaRealEstates(loanApp, section, segmentName) {
    const messages = [];
    let requiredFields = [];

    const collection = loanApp['la_real_estates'];
    if (collection.length === 0) {
      if (this.isRefinanceLoan(loanApp)) {
        const message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: this.formatSectionMessage(section,
            'No real estate owned data segment. For refinance loans, your own property must be included in Real estate owned data segment.')
        };
        messages.push(message);
      } else {
        const message = {
          section: section,
          segment: segmentName,
          errorLevel: 0,
          text: this.formatSectionMessage(section, 'No real estate owned data.')
        };
        messages.push(message);
      }
    } else {
      collection.forEach((item, index) => {
        const partyIds = item.party_ids.split(',');
        const borrowerCount = this.borrowerCount(loanApp);
        partyIds.forEach((elt) => {
          const borrowerIndex = +elt[1];
          if (borrowerIndex > borrowerCount) {
            const who = this.getBorrowerTitle(borrowerIndex);
            const message = {
              section: section,
              segment: segmentName,
              errorLevel: 2,
              text: this.formatSectionMessage(section, `Real Estate Owned (Asset ID: ${index + 1}) is owned by ${who} but there ${borrowerCount <= 1 ? 'is' : 'are'} only ${borrowerCount} ${borrowerCount <= 1 ? 'borrower' : 'borrowers'}.`)
            };
            messages.push(message);
          }
        });

        requiredFields = ['property_disposition_ex', 'occupancy', 'property_zip'];
        if (!this.verifyRequiredFields(item, requiredFields)) {
          const message = {
            section: section,
            segment: segmentName,
            errorLevel: 2,
            text: this.formatSectionMessage(section,
              'Real estate owned (asset ID: ' + item['reo_asset_id'] +
              ') segment is missing some of the required field: property disposition, intended occupancy, property zip.')
          };
          messages.push(message);
        }
      });
    }
    return messages;
  }

  verifyLiabilities(loanApp, section, segmentName) {
    const applicant = loanApp[section];
    let message;
    const messages = [];

    const collection = applicant['liabilities'];
    if (collection.length === 0) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 1,
        text: this.formatSectionMessage(section, 'No liabilities data.')
      };
      messages.push(message);
    } else {
      collection.forEach((item) => {
        const requiredFields = ['liability_type_ex', 'unpaid_balance'];
        if (!this.verifyRequiredFields(item, requiredFields)) {
          const liabilityName = Liability.getLiabilityType(item.liability_type_ex) || 'Unknown';
          message = {
            section: section,
            segment: segmentName,
            errorLevel: 2,
            text: this.formatSectionMessage(section,
              'Liability "' + liabilityName + '" segment is missing some of the required fields: liability type, unpaid balance.'
            )
          };
          messages.push(message);
        }
        if (item['liability_type_ex'] === 'HELOC' || item['liability_type_ex'] === 'MortgageLoan') {
          if (!this.verifyRequiredFields(item, ['reo_asset_id'])) {
            const liabilityName = Liability.getLiabilityType(item.liability_type_ex);
            message = {
              section: section,
              segment: segmentName,
              errorLevel: 2,
              text: this.formatSectionMessage(section,
                'Liability "' + liabilityName + '" segment is missing the Real Estate Owned Asset ID field. Edit this liability and associate it with a real estate.'
              )
            };
            messages.push(message);
          }
        }
      });
    }
    return messages;
  }

  verifySupports(loanApp, section, segmentName) {
    const applicant = loanApp[section];
    let message;
    const messages = [];

    const collection = applicant.supports;
    if (collection.length === 0) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 0,
        text: this.formatSectionMessage(section, 'No other expenses (alimony, child support, job related expenses) data.')
      };
      messages.push(message);
    } else {
      collection.forEach((item) => {
        if (!this.verifyRequiredFields(item, ['support_type_ex', 'monthly_payment_amount'])) {
          message = {
            section: section,
            segment: segmentName,
            errorLevel: 2,
            text: this.formatSectionMessage(section, 'Other expenses (alimony, child support, job related expenses) segment is missing some of the required fields: support type, monthly payment amount.')
          };
          messages.push(message);
        }
      });
    }
    return messages;
  }

  verifyUndrawns(loanApp, section, segmentName) {
    return [];
  }

  verifyAliases(loanApp, section, segmentName) {
    const applicant = loanApp[section];
    let message;
    const messages = [];

    const collection = applicant.aliases;
    if (collection.length === 0) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 0,
        text: this.formatSectionMessage(section, 'No alias data segment (other names used to receive credit).')

      };
      messages.push(message);
    } else {
      collection.forEach((item) => {
        if (!this.verifyRequiredFields(item, ['alternate_first', 'alternate_last'])) {
          message = {
            section: section,
            segment: segmentName,
            errorLevel: 2,
            text: this.formatSectionMessage(section,
              'Alias segment is missing some of the required fields (alternate first name, alternate last name).')
          };
          messages.push(message);
        }
      });
    }
    return messages;
  }

  verifyAgreement(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const applicant = loanApp[section];
    const segment = applicant.agreement;
    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 1,
        text: this.formatSectionMessage(section, 'Agreement is empty.')
      };
      messages.push(message);
    } else {
      if (segment['signature_date']) {
        if (moment(segment['signature_date'], 'YYYYMMDD').isAfter(moment())) {
          message = {
            section: section,
            segment: segmentName,
            errorLevel: 2,
            text: this.formatSectionMessage(section, 'Agreement date is in the future.')
          };
          messages.push(message);
        }
      }
    }

    return messages;
  }

  verifyRaceInformations(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const applicant = loanApp[section];
    const collection = applicant.race_informations.coll;
    if (!collection.length) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 1,
        text: this.formatSectionMessage(section, 'Race data segment(s) is missing.')
      };
      messages.push(message);
    }

    return messages;
  }

  verifyInformation(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const applicant = loanApp[section];
    const segment = applicant.information;
    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 1,
        text: this.formatSectionMessage(section, 'Ethnicity & Sex is empty.')
      };
      messages.push(message);
    } else {
      if (!this.verifyRequiredFields(segment, ['ethnicity_ex', 'gender'])) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 1,
          text: this.formatSectionMessage(section, 'Ethnicity & sex segment is missing some of the required fields: ethnicity, sex.')
        };
        messages.push(message);
      }

    }

    return messages;
  }

  verifyMilitary(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const applicant = loanApp[section];
    const segment = applicant.military;
    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: this.formatSectionMessage(section, 'Military segment is empty.')
      };
      messages.push(message);
    }

    return messages;
  }

  verifyHmda(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const applicant = loanApp[section];
    const segment = applicant.hmda;
    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: this.formatSectionMessage(section, 'HMDA segment is empty.')
      };
      messages.push(message);
    }

    return messages;
  }

  verifyDeclaration(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const applicant = loanApp[section];
    const segment = applicant.declaration;
    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: this.formatSectionMessage(section, 'Declarations are missing responses.')
      };
      messages.push(message);
    } else {
      if (!this.verifyRequiredFields(segment, [
        'primary_residence_ex',
        'ownership_interest_ex',
        'relationship',
        'down_payment_borrowed',
        'new_mortgage',
        'new_credit',
        'priority_lien',
        'endorser_loans',
        'judgments',
        'delinquent_federal_loans',
        'law_suits',
        'deed_in_lieu',
        'short_sale',
        'foreclosures',
        'bankrupt',
        'citizen_ex',
      ])) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: this.formatSectionMessage(section, 'Some declarations are missing responses.')
        };
        messages.push(message);
      } else {
        if (segment['ownership_interest_ex'] === 'Yes') {
          if (!this.verifyRequiredFields(segment, [
            'property_type_ex',
            'title_type_ex'
          ])) {
            message = {
              section: section,
              segment: segmentName,
              errorLevel: 2,
              text: this.formatSectionMessage(section, 'Some of the required responses to "ownership interest" are missing.')
            };
            messages.push(message);
          }
        }
      }
    }

    return messages;
  }

  // TODO4
  verifyDeclarationExplanations(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const applicant = loanApp[section];
    const collection = applicant.declaration_explanations;
    let count = 0;
    collection.forEach((item) => {
      if (!item.declaration_explanation) {
        count++;
      }
    });
    if (count >= 1) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 1,
        text: this.formatSectionMessage(section, count === 1 ? `One declaration is missing an explanation.` : `Several declarations are missing explanations.`)
      };
      messages.push(message);
    }

    return messages;
  }

  verifyFormTop(loanApp: any, section: string, segmentName: string) {
    const segment = loanApp.form_top;
    let message;
    const messages = [];

    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: 'Borrower Setup is empty.'
      };
      messages.push(message);
    } else {
      if (segment.borrower_count === '1') {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 0,
          text: 'This is a borrower only loan application (no co-borrower).'
        };
        messages.push(message);

        if (this.isCommunityState(loanApp)) {
          message = {
            section: section,
            segment: segmentName,
            errorLevel: 1,
            text: 'The property is situated in a state with communal property laws. The co-borrower\'s details might be required if the borrower is married or if there is another individual with community rights to the property.'
          };
          messages.push(message);
        }
      }
    }

    return messages;
  }

  verifyParties(loanApp, section, segmentName) {
    const segment = loanApp.parties;
    let message;
    const messages = [];

    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: 'Title segment is empty.'
      };
      messages.push(message);
    }

    return messages;
  }

  verifyPropertyInformation(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const segment = loanApp.property_information;
    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: 'Property information is empty.'

      };
      messages.push(message);
    } else {
      if (!this.verifyRequiredFields(segment, ['property_address', 'property_city', 'property_state', 'property_zip', 'year_built'])) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: 'Property information is missing some of the required fields: address, city, state, zip, zip four, year built.'
        };
        messages.push(message);
      }
      if (!this.verifyRequiredFields(segment, ['property_zip_four'])) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 1,
          text: 'Property information is missing zip four.'
        };
        messages.push(message);
      }
    }
    return messages;
  }

  verifyLoanPurpose(loanApp: any, section: string, segmentName: string) {
    let message: any;
    const messages = [];
    const segment = loanApp.loan_purpose;
    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: 'Loan purpose is empty.'
      };
      messages.push(message);
    } else {
      if (!this.verifyRequiredFields(segment, ['loan_purpose_code_ex', 'property_residence_type_ex', 'ownership_type_ex'])) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: 'Loan purpose segment is missing some of the required fields: loan purpose, property residence type, ownership type.'
        };
        messages.push(message);
      }
      if ((segment.loan_purpose_code === '15') && !this.isDefined(segment.loan_purpose_explain)) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: 'Loan purpose must be explained when loan purpose is "other".'
        };
        messages.push(message);
      }
    }

    return messages;
  }

  verifyDownPayments(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const downPayments = loanApp.down_payments;
    if (this.isPurchaseLoan(loanApp) && downPayments.length === 0) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 1,
        text: 'No down payment data.'
      };
      messages.push(message);
    }
    return messages;
  }

  verifyEnvelopeHeader(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const segment = loanApp.envelope_header;
    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 0,
        text: 'Fannie Mae Institution ID is empty.'
      };
      messages.push(message);
    } else {
      if (!this.verifyRequiredFields(segment, ['institution_id'])) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 0,
          text: 'Fannie Mae Institution ID segment is missing institution ID.'
        };
        messages.push(message);
      }

    }

    return messages;
  }

  verifyCredits(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const collection = loanApp.credits;
    if (collection.length === 0) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 0,
        text: 'No credits data.'

      };
      messages.push(message);
    } else {
      collection.forEach((item) => {
        if (!this.verifyRequiredFields(item, ['code_ex', 'amount'])) {
          message = {
            section: section,
            segment: segmentName,
            errorLevel: 2,
            text: 'Credit segment is missing some of the required fields: credit type, amount.'
          };
          messages.push(message);
        }

      });
    }

    return messages;
  }

  verifyLoInformation(loanApp, section, segmentName) {
    const messages = [];
    const segment = loanApp['lo_information'];
    if (this.isEmpty(segment)) {
      const message = {
        section: section,
        segment: segmentName,
        errorLevel: 1,
        text: 'Loan Originator Information is empty.'

      };
      messages.push(message);
      return messages;
    }

    const requiredFields = [
      'lo_received_date',
      'lo_orig_date',
      'lo_name',
      'lo_company_name',
      'lo_company_city',
      'lo_company_state',
      'lo_company_zip'];
    if (!this.verifyRequiredFields(segment, requiredFields)) {
      const message = {
        section: section,
        segment: segmentName,
        errorLevel: 1,
        text: 'Loan Originator Information is missing some fields.'
      };
      messages.push(message);
    }

    if (segment['lo_orig_date']) {
      if (moment(segment['lo_orig_date'], dateFormat).isAfter(moment())) {
        const message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: 'Loan Originator Information origination date is in the future.'
        };
        messages.push(message);
      }
    }

    if (segment['lo_received_date']) {
      if (moment(segment['lo_received_date'], dateFormat).isAfter(moment())) {
        const message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: 'Loan Originator Information received date is in the future.'
        };
        messages.push(message);
      }
    }

    return messages;
  }

  verifyLoanInformation(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const segment = loanApp['loan_information'];
    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: 'Loan Information is empty.'

      };
      messages.push(message);
    } else {
      if (!this.verifyRequiredFields(segment, ['mortgage_type_ex', 'amortization_type_ex', 'loan_amount', 'interest_rate', 'periods_no'])) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: 'Loan information segment is missing some of the required fields: mortgage type, amortization type, loan amount, interest rate, number of periods.'
        };
        messages.push(message);
      }
    }

    return messages;
  }

  verifyProductIdentification(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const segment = loanApp['product_identification'];
    if (!this.isEmpty(segment)) {
      const requiredFields = ['product_description'];
      if (this.isARMLoan(loanApp)) {
        requiredFields.push('product_plan_number');
      }
      if (!this.verifyRequiredFields(segment, requiredFields)) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 1,
          text: 'Product identification segment is missing some of the required fields: product description, product plan number (for ARM loan).'
        };
        messages.push(message);
      }
    }

    return messages;
  }

  // comment: the data for origination is in the product_identification segment
  verifyOrigination(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const segment = loanApp['product_identification'];
    const requiredFields = ['origination_points', 'origination_amount'];
    if (!this.verifyRequiredFields(segment, requiredFields)) {
      message = {
        section: section,
        segment: 'origination',
        errorLevel: 1,
        text: 'Origination segment is missing some of the required fields: origination points, origination amount.'
      };
      messages.push(message);
    }

    return messages;
  }

  verifyTransmittalData(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const segment = loanApp['transmittal_data'];
    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: 'Appraisal is empty.'

      };
      messages.push(message);
      return messages;
    }

    let requiredFields = [];

    if (this.isRefinanceLoan(loanApp)) {
      requiredFields = ['existing_mortgage_owner_code_ex'];
      if (!this.verifyRequiredFields(segment, requiredFields)) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: 'Appraisal segment is missing existing mortgage owner field (required for refinance loan).'
        };
        messages.push(message);
      }
    }

    if (this.isAppraisalRequired(loanApp)) {
      requiredFields = ['appraisal_type_ex', 'property_appraised_value'];
      if (!this.verifyRequiredFields(segment, requiredFields)) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: 'Appraisal segment is missing some of the required fields: appraisal type, property appraised value.'
        };
        messages.push(message);
      }
      if (this.verifyRequiredFields(segment, ['property_appraised_value']) && AnalyzerService.convertToDecimal(segment['property_appraised_value']) <= 0) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: 'Property appraised value is invalid (must be positive).'
        };
        messages.push(message);
      }
    }

    const buydownRate = parseFloat(segment['buydown_rate']);
    if (!isNaN(buydownRate) && (buydownRate >= 15)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 1,
        text: `Buydown rate: ${segment['buydown_rate']}% is high.`
      };
      messages.push(message);
    }

    return messages;
  }

  verifyProductCharacteristics(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const segment = loanApp['product_characteristics'];
    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 1,
        text: 'Product Characteristics is empty.'
      };
      messages.push(message);
    }

    return messages;
  }

  verifyLoanCharacteristics(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const segment = loanApp['loan_characteristics'];
    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: 'Loan characteristics is empty.'
      };
      messages.push(message);
      return messages;
    }
    // todo: add a special check for LTV >= 80% for 'mi_insurer_code'
    const requiredFields = ['lien_type_code_ex'];
    if (this.isNegativeAmortizationLoan(loanApp)) {
      requiredFields.push('negative_amortization_limit_percent');
    }

    if (this.isBalloonLoan(loanApp)) {
      requiredFields.push('maturity_periods_no');
      requiredFields.push('maturity_type');
      requiredFields.push('balloon_payment_amount');
    }
    if (!this.verifyRequiredFields(segment, requiredFields)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: 'Loan Characteristics segment is missing some of the required fields: lien type, negative amortization limit, and balloon payment details.'
      };
      messages.push(message);
    }

    return messages;
  }

  verifyDetails(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const segment = loanApp[segmentName];
    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 1,
        text: 'Details is empty.'
      };
      messages.push(message);
    } else {
      const requiredFields = ['prepaid_items', 'closing_costs', 'discount'];
      if (this.isPurchaseLoan(loanApp)) {
        requiredFields.push('purchase_price');
      }
      if (this.isRefinanceLoan(loanApp)) {
        requiredFields.push('refinance');
      }
      if (!this.verifyRequiredFields(segment, requiredFields)) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 1,
          text: 'Details segment is missing some of the required fields: prepaid items, closing costs, discount, purchase price (for purchase loan), and refinance (for refinance loan).'
        };
        messages.push(message);
      }
    }

    return messages;
  }

  verifyLoanData(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const segment = loanApp['loan_data'];
    if (!this.isRefinanceLoan(loanApp) && !this.isConstructionLoan(loanApp)) {
      return messages;
    }
    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: 'Loan data is empty.'
      };
      messages.push(message);
    } else {
      const requiredFields = ['lot_acquired_year'];
      if (!this.verifyRequiredFields(segment, requiredFields)) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: 'Loan data segment is missing some of the required fields: Year lot acquired, original cost.'
        };
        messages.push(message);
      }
    }

    return messages;
  }

  verifyArmData(loanApp, section, segmentName) {
    let message;
    const messages = [];
    const segment = loanApp['arm_data'];
    if (!this.isARMLoan(loanApp)) {
      return messages;
    }
    if (this.isEmpty(segment)) {
      message = {
        section: section,
        segment: segmentName,
        errorLevel: 2,
        text: 'ARM Data is empty.'
      };
      messages.push(message);
    } else {
      const requiredFields = ['index', 'index_type_new', 'index_margin', 'qualifying_rate'];
      if (!this.verifyRequiredFields(segment, requiredFields)) {
        message = {
          section: section,
          segment: segmentName,
          errorLevel: 2,
          text: 'ARM segment is missing some of the required fields: index value, index type, index margin, qualifying rate.'
        };
        messages.push(message);
      }
    }

    return messages;
  }

  // cross loan application verifications
  // Feb 13, 2020: verified this method.
  verifyPrimaryResidence(loanApp, section, segmentName) {
    let messages = this.verifyPrimaryResidenceApplicant(loanApp, 'borrower_1');
    if (this.isCoBorrower(loanApp)) {
      const moreMessages = this.verifyPrimaryResidenceApplicant(loanApp, 'borrower_2');
      if (moreMessages) {
        messages = messages.concat(moreMessages);
      }
    }

    return messages;
  }

  verifyPrimaryResidenceApplicant(loanApp, section) {
    let message;
    const messages = [];
    const applicant = loanApp[section];
    const applicantString = section === 'borrower_1' ? 'Borrower' : 'Co-Borrower';
    const declarationSegment = applicant.declaration;
    if (this.isPrimaryResidence(loanApp) && declarationSegment['primary_residence'] === 'N') {
      message = {
        section: section,
        segment: 'declaration',
        errorLevel: 2,
        text: this.formatSectionMessage(section,
          `Loan purpose data segment has the target property as primary residence but ${applicantString} responded "No" to the "Do you intend to occupy the property as your primary residence?" declaration.`)
      };
      messages.push(message);
    }
    if (!this.isPrimaryResidence(loanApp) && declarationSegment['primary_residence'] === 'Y') {
      message = {
        section: section,
        segment: 'declaration',
        errorLevel: 2,
        text: this.formatSectionMessage(section,
          `Loan purpose data segment does not have the target property as primary residence but ${applicantString} responded "Yes" to the "Do you intend to occupy the property as your primary residence?" declaration.`)
      };
      messages.push(message);
    }

    return messages;
  }

  // this is cross loan verification because we might need to check secondary employments
  // refactored and verified on Feb 13, 2020
  verifyTwoYearEmployment(loanApp, section, segmentName) {
    let messages = this.verifyTwoYearEmploymentApplicant(loanApp, 'borrower_1');

    if (this.isCoBorrower(loanApp)) {
      const moreMessages = this.verifyTwoYearEmploymentApplicant(loanApp, 'borrower_2');
      if (moreMessages) {
        messages = messages.concat(moreMessages);
      }
    }

    return messages;
  }

  verifyTwoYearEmploymentApplicant(loanApp, section) {
    let message;
    const messages = [];
    const applicant = loanApp[section];
    const segment = applicant.employment;
    const collection = applicant.secondary_employments;
    const frames = [];
    frames.push([segment['start_date'], moment().format(dateFormat)]);

    collection.forEach((secondaryEmployment) => {
      const fromDate = secondaryEmployment['from_date'];
      const to_date = secondaryEmployment['to_date'];
      const toDate = to_date && (to_date.trim().length > 0) ? to_date : moment().format('YYYYMMDD');
      frames.push([fromDate, toDate]);
    });

    const result = Util.generateEmploymentReport(frames);

    if (result.empNetLengthMonths < 24) {
      message = {
        section: section,
        segment: 'secondary_employments',
        errorLevel: section === 'borrower_1' ? 2 : 1,
        text: this.formatSectionMessage(section, `Employment net history length is ${result['empNetLengthMonths']} months (less than two years). Add "Previous employment" segment(s) as necessary.`)
      };
      messages.push(message);
    }

    if (result['gapMonths'] > 0) {
      message = {
        section: section,
        segment: 'secondary_employments',
        errorLevel: 1,
        text: this.formatSectionMessage(section, `Employment history contains gaps: ${result['gapStrings'].join(', ')}.`)
      };
      messages.push(message);
    }

    return messages;
  }

  formatSectionMessage(section, message) {
    return message;
  }

  verifyRequiredFields(segment, fields) {
    return fields.every((field) => this.isDefined(segment[field]));
  }

  isARMLoan(loanApp) {
    return DataService.getARMAmortizationTypes().includes(loanApp.loan_information.amortization_type_ex);
  }

  isFixedRateLoan(loanApp) {
    return DataService.getFixedTypes().includes(loanApp.loan_information.amortization_type_ex);
  }

  isLoanPurpose(loanApp) {
    return !!loanApp && !!loanApp.loan_purpose && !!loanApp.loan_purpose['loan_purpose_code_ex'];
  }

  // todo: change name to isDownPaymentRequired
  isDownPayment(loanApp) {
    return this.isPurchaseLoan(loanApp);
  }

  isPurchaseLoan(loanApp) {
    return !!loanApp && !!loanApp.loan_purpose && loanApp.loan_purpose['loan_purpose_code_ex'] === 'Purchase';
  }

  isRefinanceLoan(loanApp) {
    return loanApp && loanApp.loan_purpose && (loanApp.loan_purpose['loan_purpose_code_ex'] === 'Refinance' || loanApp.loan_purpose['loan_purpose_code_ex'] === 'MortgageModification');
  }

  // todo: no way of telling whether property is is a condo
  isCondoLoan(loanApp) {
    return false;
  }

  isConstructionLoan(loanApp) {
    return loanApp && loanApp.loan_purpose && (loanApp.loan_purpose['construction_loan_indicator'] === 'Y');
  }

  isAppraisalRequired(loanApp) {
    return true;
  }

  isNegativeAmortizationLoan(loanApp) {
    return !!loanApp && !!loanApp.product_characteristics &&
      (loanApp.product_characteristics['interest_only'] === 'y');
  }

  isBalloonLoan(loanApp): boolean {
    return !!loanApp && !!loanApp.loan_characteristics && (loanApp.loan_characteristics['balloon_indicator'] === 'Y');
  }

  // if any form-top checkbox is checked then there is a co-borrower
  isCoBorrower(loanApp) {
    return AnalyzerService.isCoBorrower(loanApp);
  }

  borrowerCount(loanApp: any): number {
    return AnalyzerService.borrowerCount(loanApp);
  }

  getBorrowerTable(loanApp: any): {
    key: string,
    value: string
  } [] {
    const table = [];
    const count = this.borrowerCount(loanApp);
    for (let i = 1; i <= 4 && i <= count; i++) {
      table.push({
        key: `b${i}`,
        value: this.getBorrowerTitle(i)
      });
    }
    return table;
  }

  // get borrower title using either index (number) or string abbreviation (e.g., "b1")
  getBorrowerTitle(index: number | string): string {
    let parsedIndex = -1;
    if (typeof index === 'string' && index.length >= 2 && index.startsWith('b')) {
      parsedIndex = +index[1];
    } else if (typeof index === 'number') {
      parsedIndex = index;
    }
    switch (parsedIndex) {
      case 1:
        return 'Borrower';
      case 2:
        return 'Co-Borrower';
      case 3:
        return 'Borrower 3';
      case 4:
        return 'Borrower 4';
      default:
        return 'Unknown';
    }
  }

  getBorrowerObjTable(loanApp: any): any {
    let borrowerObjTable = {};
    const borrowers = loanApp['borrowers'];
    for (const key in borrowers) {
      const bor = borrowers[key];
      const borShort = bor['role'].replace('borrower_', 'b'); // e.g., "borrower_1" => "b1"
      borrowerObjTable[borShort] = bor['full_name'];
    }
    return borrowerObjTable;
  }

  getBorrowersByKeyName(loanApp: any): any {
    let borrowersByKeyName: any[] = [];
    const borrowers = loanApp['borrowers'];
    for (const key in borrowers) {
      const bor = borrowers[key];
      borrowersByKeyName.push({key: 'b' + bor['role'].split('_')[1], value: bor['full_name']})
    }
    return borrowersByKeyName;
  }

  getBorrowerNameArray(loanApp: any): Array<string> {
    return loanApp['borrowers'].map(elt => elt['full_name']).filter((elt => !!elt));
  }

  isCommunityState(loanApp) {
    // Arizona, California, Idaho, Louisiana, Nevada, New Mexico, Texas, Washington and Wisconsin.
    const propertyState = loanApp.property_information.property_state;
    return ['CA', 'ID', 'LA', 'NV', 'NM', 'TX', 'WA', 'WI'].includes(propertyState);
  }

  //
  // COMPUTATIONS
  //

  computeLoanToValue(loanApp) {
    const loanAmount = AnalyzerService.convertToDecimal(loanApp.loan_information.loan_amount);
    const purchasePrice = AnalyzerService.convertToDecimal(loanApp.details.purchase_price);
    const appraisedValue = AnalyzerService.convertToDecimal(loanApp.transmittal_data.property_appraised_value);
    let propertyValue = null;

    if (appraisedValue && appraisedValue > 0.0 && purchasePrice && purchasePrice > 0) {
      propertyValue = Math.min(appraisedValue, purchasePrice);
    } else if (purchasePrice && purchasePrice > 0) {
      propertyValue = purchasePrice;
    } else if (appraisedValue && appraisedValue > 0) {
      propertyValue = appraisedValue;
    }

    if (propertyValue && (propertyValue > 0.0) && (loanAmount > 0.0)) {
      return loanAmount / propertyValue;
    }
    return null;
  }

  computeMonthlyRealEstateTaxes(loanApp) {
    let sum = 0.0;
    sum += this.computeMonthlyExpense(loanApp, 'RealEstateTax');
    return sum;
  }

  computeMonthlyHazardInsurance(loanApp) {
    let sum = 0.0;
    sum += this.computeMonthlyExpense(loanApp, 'HomeownersInsurance');
    return sum;
  }

  computeMonthlyMortgageInsurance(loanApp) {
    let sum = 0.0;
    sum += this.computeMonthlyExpense(loanApp, 'MIPremium');
    return sum;
  }

  computeMonthlyExpense(loanApp, what) {
    const expenses = loanApp['la_expenses']['coll'];
    const resultProposed = expenses.find((expense) => {
      return expense.expense_indicator_ex === 'Proposed' && expense.code_ex === what;
    });
    const resultPresent = expenses.find((expense) => {
      return expense.expense_indicator_ex === 'Present' && expense.code_ex === what;
    });
    const result = resultProposed || resultPresent;
    return AnalyzerService.convertToDecimal(result ? result.amount : undefined);
  }

  computeMonthlyIncome(loanApp) {
    let sum = 0.0;
    sum += this.computeApplicantMonthlyIncome(loanApp, 'borrower_1');
    if (this.isCoBorrower(loanApp)) {
      sum += this.computeApplicantMonthlyIncome(loanApp, 'borrower_2');
    }
    return sum;
  }

  computeMonthlyNetRealEstateIncome(loanApp) {
    let sum = 0.0;
    loanApp['la_real_estates'].forEach((realEstate) => {
      if (realEstate.net_rental_income * 1 > 0) {
        sum += AnalyzerService.convertToDecimal(realEstate.net_rental_income);
      }
    });
    return sum;
  }

  computeExpectedMonthlyNetRentalIncome(loanApp) {
    const expectedRental = loanApp['loan_purpose']['expected_net_rental'];
    return AnalyzerService.convertToDecimal(expectedRental);
  }

  computeMonthlySupportExpenses(loanApp) {
    let sum = 0.0;
    sum += this.computeApplicantMonthlySupportExpenses(loanApp, 'borrower_1');
    if (this.isCoBorrower(loanApp)) {
      sum += this.computeApplicantMonthlySupportExpenses(loanApp, 'borrower_2');
    }
    return sum;
  }

  computeMonthlyOtherExpenses(loanApp) {
    let sum = 0.0;
    loanApp['la_expenses']['coll'].forEach((expense) => {
      if (expense.code_ex !== 'HomeownersInsurance' && expense.code_ex !== 'MIPremium' && expense.code_ex !== 'RealEstateTax') {
        sum += this.computeMonthlyExpense(loanApp, expense.code_ex);
      }
    });
    return sum;
  }

  computeMonthlyLiabilityExpenses(loanApp) {
    let sum = 0.0;
    sum += this.computeApplicantMonthlyLiabilityExpenses(loanApp, 'borrower_1');
    if (this.isCoBorrower(loanApp)) {
      sum += this.computeApplicantMonthlyLiabilityExpenses(loanApp, 'borrower_2');
    }
    return sum;
  }

  computeAssets(loanApp) {
    let sum = 0.0;
    loanApp['la_assets'].forEach((asset) => {
      sum += AnalyzerService.convertToDecimal(asset.institution_market_value);
    });
    return sum;
  }

  computeRealEstateAssets(loanApp) {
    let sum = 0.0;
    loanApp['la_real_estates'].forEach((asset) => {
      sum += (AnalyzerService.convertToDecimal(asset.market_value) - AnalyzerService.convertToDecimal(asset.liens_amount));
    });
    return sum;
  }

  computeGifts(loanApp) {
    let sum = 0.0;
    sum += this.computeApplicantGifts(loanApp, 'borrower_1');
    if (this.isCoBorrower(loanApp)) {
      sum += this.computeApplicantGifts(loanApp, 'borrower_2');
    }
    return sum;
  }

  computeLiabilities(loanApp) {
    let sum = 0.0;
    sum += this.computeApplicantLiabilities(loanApp, 'borrower_1');
    if (this.isCoBorrower(loanApp)) {
      sum += this.computeApplicantLiabilities(loanApp, 'borrower_2');
    }
    return sum;
  }

  computeApplicantMonthlyLiabilityExpenses(loanApp, who) {
    let sum = 0.0;
    loanApp[who]['liabilities'].forEach((liability) => {
      if (liability.will_be_paid !== 'Y' && liability.omit_from_credit_report !== 'Y') {
        sum += AnalyzerService.convertToDecimal(liability.monthly_payment_amount);
      }
    });
    return sum;
  }

  // comment: NetRentalIncome should not be present in the income list
  computeApplicantMonthlyIncome(loanApp, who) {
    let sum = 0.0;
    loanApp[who]['incomes'].forEach((income) => {
      if (income.code_ex !== 'NetRentalIncome') {
        sum += AnalyzerService.convertToDecimal(income.amount);
      }
    });
    return sum;
  }

  computeApplicantMonthlySupportExpenses(loanApp, who) {
    let sum = 0.0;
    loanApp[who]['supports'].forEach((expense) => {
      sum += AnalyzerService.convertToDecimal(expense.monthly_payment_amount);
    });
    return sum;
  }

  computeApplicantGifts(loanApp, who) {
    let sum = 0.0;
    loanApp[who]['gifts'].forEach((asset) => {
      sum += AnalyzerService.convertToDecimal(asset.market_value);
    });
    return sum;
  }

  computeApplicantLiabilities(loanApp, who) {
    let sum = 0.0;
    loanApp[who]['liabilities'].forEach((liability) => {
      if (liability.omit_from_credit_report !== 'Y' && liability.will_be_paid !== 'Y') {
        sum += AnalyzerService.convertToDecimal(liability.unpaid_balance);
      }
    });
    return sum;
  }

  isPrimaryResidence(loanApp) {
    return (loanApp.loan_purpose.property_residence_type_ex === 'PrimaryResidence');
  }
}
