import {Component, Injector, Input, OnInit} from '@angular/core';
import {Document} from '../../../../../models/document';
import {FormUtil} from '../../../../form-util';
import {GenerateDocumentBaseComponent} from "../../generate-document-base.component";
import {FormTop} from "../../../../../models/form-top";
import {FormArray, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-generate-reqs',
  templateUrl: './generate-reqs.component.html',
})
export class GenerateReqsComponent extends GenerateDocumentBaseComponent implements OnInit {
  @Input() stateArr: Array<string>;
  @Input() folderId: string;
  public borrowersByKeyName: [];
  public borrowerObjTable: any = {};
  public isDefaultLoanApp: boolean;
  public borrowerCount: number;
  public allFormTypes = Document.getAllFormTypes();
  public formIDs = new Set<number>();
  public borrowerGroupsOut: Array<Array<string>>;
  public borrowerGroupsIn: Array<Array<string>>;
  public generating: boolean;

  // form and form controls
  public form: FormGroup;
  public checkboxArray: FormArray;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  createFormControls(): void {
    this.checkboxArray = new FormArray([]);
    this.borrowersByKeyName.forEach(() => this.checkboxArray.push(new FormControl(true)));
  }

  createForm(): void {
    this.form = new FormGroup({
      borrowersSelected: this.checkboxArray,
    });
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.generating = false;
    this.doc = this.tran.getDefaultLoanApp();
    this.isDefaultLoanApp = !!this.doc;
    const formTop = new FormTop(this.doc.recursive_attributes['form_top']);
    this.borrowerGroupsIn = formTop.borrower_groups;
    this.borrowerObjTable = this.analyzerService.getBorrowerObjTable(this.doc.recursive_attributes);
    this.borrowersByKeyName = this.analyzerService.getBorrowersByKeyName(this.doc.recursive_attributes);
    this.borrowerCount = this.analyzerService.borrowerCount(this.doc.recursive_attributes);
    this.createFormControls();
    this.createForm();
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  genMultipleDocs(event: any): void {
    event.preventDefault();
    let who = []
    this.form.controls['borrowersSelected'].value.forEach((elt, index) => {
      if (elt) {
        who.push(this.borrowersByKeyName[index]['key'])
      }
    })
    const ids = Array.from(this.formIDs).sort();
    if (ids.length === 0) {
      FormUtil.genErrorMessage(this.sharedService, 'No documents selected', null);
    }
    const formData: any = {
      icn_id: null,
      borrower_groups: null
    };
    formData.icn_id = this.folderId;
    const flattenedForms = [].concat.apply([], Object.values(this.allFormTypes));
    const selectedForms = flattenedForms.filter((elt: any) => {
      return ids.includes(elt.ID);
    }).map((elt: any) => elt.forms);
    const flattenedSelectedForms = [].concat.apply([], selectedForms);
    formData['borrower_groups'] = this.borrowerGroupsOut;
    formData['form_types'] = flattenedSelectedForms;
    formData['who'] = who.join(',');
    this.generating = true;

    this.docService.generateMultipleDocs(this.doc.id, formData)
      .then(() => {
        const message = this.folderId ? 'Documents successfully generated' : 'Documents successfully generated and moved to Unsorted Documents folder';
        FormUtil.genSuccessMessage(this.sharedService, message);
        this.router.navigate(['/loan/view-directory']);
        this.generating = false;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to generate document', data);
        this.eventEmitted.emit({
          type: 'ChangeMode',
          mode: 'View'
        });
        this.generating = false;
      });
  }

  isFormValid(): boolean {
    return (this.formIDs.size > 0); // && this.doneGrouping;
  }

  onFormSelectionChange(ev: any): void {
    ev.formSelected ? this.formIDs.add(ev.formID) : this.formIDs.delete(ev.formID);
  }

  getUSForms(): Array<any> {
    return this.allFormTypes.US.filter((elt) => elt.type === 'PDF');
  }

  getStateForms(): Array<any> {
    let formTypes = [];
    this.stateArr.forEach((stateAbbr: string) => {
      if (this.allFormTypes[stateAbbr]) {
        formTypes = formTypes.concat(this.allFormTypes[stateAbbr])
      }
    });
    return formTypes;
  }

  cancel() {
    this.eventEmitted.emit({
      type: 'Reload',
      force: false
    });
  }

  onOutGroups(outGroups: Array<Array<any>>): void {
    this.borrowerGroupsOut = outGroups
  }
}
