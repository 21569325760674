<div>&nbsp;</div>
<div>
  <app-segment-header (eventEmitted)="onEvent($event)" [enable]="true" [header]="'Credit Reports'" [isCard]="false" [help]="true" [op]="''">
    <div class="fw-bold">Generating Credit Report</div>
    <div>
      When you create a new credit report document, the credit service provider starts processing the request and
      The Credit Report Status is set to New. The processing should take a short period of time, typically one minute.
      Check the Credit Report Status until the status changes to Completed.
    </div>

    <div class="mt-2">
      If the credit report processing fails, the status changes to
      <b>Error</b>.
      An
      <b>Error</b> status is terminal and, in that case, you would need to resolve the issue with your
      credit service provider.
    </div>

    <div class="fw-bold mt-2">Joint Credit Report</div>
    <div>
      For a joint credit report, two scores display for each credit bureau. The first score is for
      the borrower and the other for the co-borrower.
    </div>

    <div class="fw-bold mt-2">Managing Credit Report</div>
    <div>
      Once the credit report was generated, you could:
      <ul style="margin-bottom: 0">
        <li>populate liabilities automatically from the credit report and/or</li>
        <li>generate a credit report PDF</li>
      </ul>
      <p>
        You can find the generated PDF file in
        <b>Current Loan/Repository/Documents</b>.
      </p>
    </div>
    <div class="fw-bold mt-2">Populating Liabilities</div>
    <div>
      When you populate the liabilities data segment from the credit report, liabilities are always appended to the
      existing liabilities!
    </div>
  </app-segment-header>
</div>

<div *ngIf="mode==='View'">
  <div *ngFor="let creditDoc of creditDocs">
    <app-view-credit-report (eventEmitted)="onEvent($event)" *ngIf="reloaded" [creditDoc]="creditDoc" [loanAppDoc]="loanAppDoc">
    </app-view-credit-report>
  </div>
  <app-box *ngIf="reloaded&&!isCreditDocs" [close]="false" [type]="'warning'" class="pt-2">
    <span class="fw-bold">No Credit Reports!</span>
    You can pull/upload credit report at
    <b>Pull Credit Report</b>.
  </app-box>
</div>
