<mat-card *ngIf="doc" class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header [enable]="false" [header]="'Finalize Loan'" [help]="true">
      <span class="fw-bold">Finalize Loan</span>
      <p>
        Once you have completed the Loan Application, proceed to run Finalize Loan.
        The Finalize Loan function is designed to remove any data that is not relevant to the loan.
        For example, if you initially included information for a co-borrower, but later decided to submit the loan without them,
        that co-borrower's data will be permanently deleted upon finalization.
      </p>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <button (click)="finalizeLoan()" class="btn btn-lg btn-primary" type="submit" type="submit">Finalize Loan</button>
    </form>
  </mat-card-content>
</mat-card>
