import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {globals} from '../../../shared/globals/globals';
import {Help} from '../../../models/help';

@Component({
  selector: 'app-view-tasks-tabs',
  templateUrl: './view-dashboard-tabs.component.html',
})
export class ViewDashboardTabsComponent extends CompBaseComponent implements OnInit {
  public static currentSection: string;
  public pageTitle = 'My Dashboard';
  public currentSection: string;
  public localSection = globals.section;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!ViewDashboardTabsComponent.currentSection) {
      ViewDashboardTabsComponent.currentSection = globals.section.complianceDash;
    }
    this.reload(false);
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.loansDashboard
      });
    }, 0);
  }

  reload(force: boolean): void {
    this.reloaded = false;
    this.setSection(null, ViewDashboardTabsComponent.currentSection);
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  setSection(event, section): void {
    this.currentSection = section;
    ViewDashboardTabsComponent.currentSection = section;
  }
}
