<mat-card class="mt-2 mb-1">
  <mat-card-content>
    <div *ngIf="canUpdateDocument" class="row">
      <div class="col">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'edit'" title="Edit"></app-line-header>
      </div>

      <div class="col text-end">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'delete'" title="Delete"></app-line-header>
      </div>
    </div>

    <div class="row pt-1 pb-1 hover-gray">
      <div class="col-lg-6">{{item.getRace()}}</div>
    </div>
  </mat-card-content>
</mat-card>
