import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {FormTop} from '../../../../models/form-top';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-edit-segment-form-top',
  templateUrl: './edit-segment-form-top.component.html',
})
export class EditSegmentFormTopComponent extends CompBaseComponent implements OnInit {
  @Input() public item: FormTop;
  public borrowerObjTable: any = {};
  // form and form controls
  form: FormGroup;
  borrower_count: FormControl;
  borrower_groups: Array<Array<string>>;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.borrower_groups = this.item.borrower_groups;
    this.segment = 'form_top';
    this.section = 'loan_information';
    this.borrowerObjTable = this.analyzerService.getBorrowerObjTable(this.doc.recursive_attributes);
    this.reload();
  }

  createFormControls(item): void {
    this.borrower_count = new FormControl(item.borrower_count);
  }

  createForm(): void {
    this.form = new FormGroup({
      borrower_count: this.borrower_count,
    });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    if (+this.borrower_count.value === 1) {
      this.borrower_groups = [['b1']];
    }

    const updated = new FormTop(this.form.value);
    updated.borrower_groups = this.borrower_groups;
    const data: any = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  reload(): void {
    this.reloaded = false;
    this.createFormControls(this.item);
    this.createForm();
    setTimeout(() => {
      this.reloaded = true;
    }, 0)
  }

  canGroup(): boolean {
    const borrowerNameArray: string[] = this.analyzerService.getBorrowerNameArray(this.doc.recursive_attributes);
    return borrowerNameArray.length === +this.borrower_count.value;
  }

  onBorrowerGroups(borrowerGroups: Array<Array<any>>): void {
    this.borrower_groups = borrowerGroups;
  }
}
