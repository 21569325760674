import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ProductIdentification} from '../../../../models/product-identification';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-edit-segment-origination',
  templateUrl: './edit-segment-origination.component.html',
})
export class EditSegmentOriginationComponent extends CompBaseComponent implements OnInit {
  @Input() public item: ProductIdentification;

  // form and form controls
  form: FormGroup;
  origination_points: FormControl;
  origination_amount: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'product_identification';
    this.section = 'lo';
    this.createFormControls(this.item);
    this.createForm();
    this.reload();
  }

  createFormControls(item): void {
    this.origination_points = new FormControl(item.origination_points);
    this.origination_amount = new FormControl(item.origination_amount);
  }

  createForm(): void {
    this.form = new FormGroup({
      origination_points: this.origination_points,
      origination_amount: this.origination_amount,
    });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    // const updated = new ProductIdentification(this.form.value);
    const updated = this.item;
    updated.origination_amount = this.origination_amount.value;
    updated.origination_points = !!this.origination_points.value ? this.origination_points.value : null;

    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  reload(force?: boolean): void {
  }
}
