<mat-card class="mt-1 mb-1">
  <mat-card-title>MLO Configuration
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Personal NMLS ID</mat-label>
            <input appNumberOnly formControlName="nmls" matInput placeholder="Personal NMLS ID" maxlength="{{MAX_LEN.NMLS_ID}}" appAutoFocus>
            <mat-error *ngIf="nmls.hasError('minlength')">
              Personal NMLS ID is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

     <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Phone number</mat-label>
            <input appPhoneOnly formControlName="phone_number" matInput placeholder="Phone number" maxlength="{{MAX_LEN.PHONE}}">
            <mat-hint>format: xxx-xxx-xxxx</mat-hint>
            <mat-error *ngIf="phone_number.hasError('pattern')">
              Phone number is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Company name</mat-label>
            <input formControlName="company_name" matInput placeholder="Company Name" maxlength="{{MAX_LEN.GEN_NAME}}">
            <mat-error *ngIf="company_name.hasError('minlength')">
              Company name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Company address</mat-label>
            <input formControlName="address" matInput placeholder="Company address" maxlength="{{MAX_LEN.ADDR_PART}}">
            <mat-error *ngIf="address.hasError('minlength')">
              Address is too short
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Company Apt/Unit/Suite</mat-label>
            <input formControlName="address2" matInput placeholder="Company Apt/Unit/Suite" maxlength="{{MAX_LEN.ADDR_PART}}">
            <mat-error *ngIf="address2.hasError('minlength')">
              Apt/Unit/Suite is too short
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Company city</mat-label>
            <input formControlName="city" matInput placeholder="Company city" maxlength="{{MAX_LEN.ADDR_PART}}">
            <mat-error *ngIf="city.hasError('minlength')">
              City name is too short
            </mat-error>
            <mat-error *ngIf="city.hasError('maxlength')">
              City name is too long
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Company State Abbr.</mat-label>
            <input formControlName="state" appUpperCase matInput placeholder="Company state abbr." maxlength="{{MAX_LEN.STATE_ABBR}}">
            <mat-error *ngIf="state.hasError('pattern')">
              Company state abbreviation is invalid
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Company zip</mat-label>
            <input appNumberOnly formControlName="zip" matInput placeholder="Company zip" maxlength="{{MAX_LEN.ZIP}}">
            <mat-error *ngIf="zip.hasError('pattern')">
              Zip is invalid
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Company zip four</mat-label>
            <input appNumberOnly formControlName="zip_four" matInput placeholder="Company zip four" maxlength="{{MAX_LEN.ZIP_FOUR}}">
            <mat-error *ngIf="zip_four.hasError('pattern')">
              Zip four is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Company NMLS ID</mat-label>
            <input appNumberOnly formControlName="company_nmls" matInput placeholder="Company NMLS ID" maxlength="{{MAX_LEN.NMLS_ID}}">
            <mat-error *ngIf="company_nmls.hasError('minlength')">
              Company NMLS ID is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Fannie Mae institution ID</mat-label>
            <input formControlName="fnma_institution_id" matInput placeholder="Fannie Mae institution ID" maxlength="6">
            <mat-error *ngIf="fnma_institution_id.hasError('minlength')">
              Fannie Mae institution ID is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Fannie Mae institution name</mat-label>
            <input formControlName="fnma_institution_name" matInput placeholder="Fannie Mae institution name" maxlength="25">
            <mat-error *ngIf="fnma_institution_name.hasError('minlength')">
              Fannie Mae Institution name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Compensation percent</mat-label>
            <input appDecimalThreeOnly formControlName="compensation_percent" matInput placeholder="Compensation percent">
            <mat-error *ngIf="compensation_percent.hasError('min') || compensation_percent.hasError('max')">
              Compensation percent is invalid
            </mat-error>
            <mat-error *ngIf="compensation_percent.hasError('pattern')">
              Compensation percent is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Min compensation amount</mat-label>
            <input appDecimalOnly formControlName="min_compensation_amount" matInput placeholder="Min compensation amount">
            <mat-error *ngIf="min_compensation_amount.hasError('min') || min_compensation_amount.hasError('max')">
              Minimum compensation amount is invalid
            </mat-error>
            <mat-error *ngIf="min_compensation_amount.hasError('pattern')">
              Format is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Max compensation amount</mat-label>
            <input appDecimalOnly formControlName="max_compensation_amount" matInput placeholder="Max compensation amount">
            <mat-error *ngIf="max_compensation_amount.hasError('min') || max_compensation_amount.hasError('max')">
              Maximum compensation amount is invalid
            </mat-error>
            <mat-error *ngIf="max_compensation_amount.hasError('pattern')">
              Format is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>ECOA address</mat-label>
            <input formControlName="ecoa_address" matInput placeholder="ECOA address" maxlength="{{MAX_LEN.FULL_ADDR}}">
            <mat-error *ngIf="ecoa_address.hasError('required')">
              ECOA address is required
            </mat-error>
            <mat-error *ngIf="ecoa_address.hasError('pattern')">
              ECOA address is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
