import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {UserService} from "./user.service";
import {IProfileStatusReport} from "../models/profile-status-report";
import {User} from "../models/user";
import {Organization} from "../models/organization";

@Injectable()
export class ProfileService {

  constructor(private userService: UserService,
              private httpService: HttpService
  ) {
  }

  public getProfileStatus(user: User, userOrg: Organization): any {
    const report: IProfileStatusReport = {
      accountSettings: {
        percentComplete: 0,
        messages: '',
        isComplete: false
      },
      userConfiguration: {
        percentComplete: 0,
        messages: '',
        isComplete: false
      },
      professionalProfile: {
        percentComplete: 0,
        messages: '',
        isComplete: false
      },
      userOrganization: {
        percentComplete: 0,
        messages: '',
        isComplete: false
      },
    }
    // set User Configuration's completeness
    let percentComplete = 0;
    let config: any = user;
    let messages: Array<string> = [];
    if (!!config.cell_phone) {
      percentComplete += 33;
    } else {
      messages.push('Cell phone is missing.');
    }
    if (!!config.mobile_carrier_name) {
      percentComplete += 33;
    } else {
      messages.push('Mobile carrier is missing.');
    }
    if (!!config.isImage()) {
      percentComplete += 34;
    } else {
      messages.push('Profile picture is missing.');
    }
    report.accountSettings.percentComplete = percentComplete;
    report.accountSettings.messages = messages.join(' ');
    report.accountSettings.isComplete = percentComplete === 100;

    if (!user.isLoanOfficer()) {
      return report;
    }
    // LO only
    // set User Configuration's completeness
    percentComplete = 0;
    config = user.user_configuration;
    messages = [];
    if (!!config.address && !!config.city && !!config.state && !!config.zip) {
      percentComplete += 20;
    } else {
      messages.push('Address is not complete.');
    }
    if (!!config.nmls) {
      percentComplete += 20;
    } else {
      messages.push('Personal NMLS ID is missing.');
    }
    if (!!config.company_name && !!config.company_nmls) {
      percentComplete += 20;
    } else {
      messages.push('Either company name or company NMLS ID is missing.');
    }
    if (Object.keys(config.licenses).length > 0) {
      percentComplete += 20;
    } else {
      messages.push('State license(s) is missing.');
    }
    if (!!config.phone_number) {
      percentComplete += 20;
    } else {
      messages.push('Phone is missing.');
    }
    report.userConfiguration.percentComplete = percentComplete;
    report.userConfiguration.messages = messages.join(' ');
    report.userConfiguration.isComplete = percentComplete === 100;

    // set Professional Profile's completeness
    percentComplete = 0;
    config = user.professional_profile;
    messages = [];
    if (!!config.company) {
      percentComplete += 25;
    } else {
      messages.push('Company name is missing.');
    }
    if (!!config.phone) {
      percentComplete += 25;
    } else {
      messages.push('Phone is missing.');
    }
    if (!!config.about_me) {
      percentComplete += 25;
    } else {
      messages.push('About me is missing.');
    }
    if (!!config.about_my_company) {
      percentComplete += 25;
    } else {
      messages.push('About my company is missing.');
    }
    report.professionalProfile.percentComplete = percentComplete;
    report.professionalProfile.messages = messages.join(' ');
    report.professionalProfile.isComplete = percentComplete === 100;

    // set User Organization's completeness
    percentComplete = 0;
    messages = [];
    if (!!userOrg) {
      percentComplete += 50;
    } else {
      messages.push('User organization not created.');
    }
    if (userOrg && userOrg.isLogo()) {
      percentComplete += 50;
    } else {
      messages.push('User organization\'s logo is missing');
    }
    report.userOrganization.percentComplete = percentComplete;
    report.userOrganization.messages = messages.join(' ');
    report.userOrganization.isComplete = percentComplete === 100;

    return report;
  }
}
