import {Component, ElementRef, EventEmitter, Injector, OnInit, Output, ViewChild} from '@angular/core';
import {StickyNoteComponent} from '../sticky-note/sticky-note.component';

@Component({
  selector: 'app-edit-sticky-note-segment',
  templateUrl: '../sticky-note/edit-sticky-note-segment.component.html',
})
export class EditStickyNoteSegmentComponent extends StickyNoteComponent implements OnInit {
  @Output() eventEmitted = new EventEmitter();

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.comment = this.getComment();
    this.comments = this.getComments();
    this.createFormControls();
    this.createForm();
  }
}
