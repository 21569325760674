import {Component, Injector, Input, OnInit} from '@angular/core';
import {Document} from '../../../models/document';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {CompBaseComponent} from '../../comp-base.component';

@Component({
  selector: 'app-view-borrower-document',
  templateUrl: './view-borrower-document.component.html',
})
export class ViewBorrowerDocumentComponent extends CompBaseComponent implements OnInit {
  @Input() document: Document;
  public fullImage = false;
  public urlSafe: SafeResourceUrl;
  public downloading = false;
  public mode: string;
  public canUpdateDocument: boolean;
  private url: string;

  constructor(public injector: Injector,
              public sanitizer: DomSanitizer) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.canUpdateDocument = this.permissionService.execCan('CanUpdateDocument', this.document);
    this.mode = 'View';
    this.url = 'api/documents/' + this.document.id;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  toggleImage(): void {
    this.fullImage = !this.fullImage;
  }

  downloadDocument(): void {
    this.downloading = false;
    setTimeout(() => {
      this.downloading = true;
    }, 0);
  }
}
