<div *ngIf="reloaded" class="row">
  <div *ngIf="!isMobile || isOpen" class="col-lg-2 col">
    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="true" [help]="false" [op]="'add'"></app-segment-header>
    </mat-card-title>

    <ul class="pt-4">
      <li [ngClass]="{active:currentSeg===localSeg.received}">
        <span></span>
        <a (click)="setSeg($event,localSeg.received)" href="" [ngClass]="{'active': isActive(localSeg.received)}" id="received">Received</a>
      </li>

      <li [ngClass]="{active:currentSeg===localSeg.sent}">
        <span></span>
        <a (click)="setSeg($event,localSeg.sent)" href="" [ngClass]="{'active': isActive(localSeg.sent)}" id="sent">Sent</a>
      </li>
    </ul>
  </div>

  <div *ngIf="!(isMobile&&isOpen)" class="col-lg-10 col">
    <div [ngSwitch]="currentSeg">
      <app-view-segment-messages (eventEmitted)="onEvent($event)" *ngSwitchCase="localSeg.received" [messages]="messagesReceived" [type]="localSeg.received">
      </app-view-segment-messages>
      <app-view-segment-messages (eventEmitted)="onEvent($event)" *ngSwitchCase="localSeg.sent" [messages]="messagesSent" [type]="localSeg.sent">
      </app-view-segment-messages>
      <app-send-message (eventEmitted)="onEvent($event)" *ngSwitchCase="localSeg.newMessage">
      </app-send-message>
    </div>
  </div>
</div>
