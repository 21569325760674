<div *ngIf="reloaded">
  <div *ngIf="userOrg">
    <app-view-segment-user-org (eventEmitted)="onEvent($event)" *ngIf="mode==='View'" [item]="userOrg"></app-view-segment-user-org>
    <app-edit-segment-user-org (eventEmitted)="onEvent($event)" *ngIf="mode==='Edit'" [item]="userOrg" [op]="'Edit'"></app-edit-segment-user-org>
  </div>

  <mat-card *ngIf="mode==='View' || mode==='Upload'" class="border-bottom mt-3 mb-3">
    <mat-card-title>Logo</mat-card-title>
    <mat-card-content>
      <div class="row pt-1 pb-1">
        <div class="col-lg-4">
          The logo image height is set to 70 pixels.
          Any width overflow is hidden.<br>
          If you don't upload a logo, the default PrudentCO logo will be displayed.
          <div class="m-3">
            <img [src]="userOrg.logo_url" style="height: 70px; border: 1px solid #555;">
          </div>.
        </div>
        <div class="col-lg-8 d-flex">
        </div>
      </div>

      <div class="row" *ngIf="mode !== 'Upload'">
        <div class="col">
          <button [hidden]="canDeleteLogo" (click)="uploadLogo($event)" class="btn btn-lg btn-primary" type="button" title="Upload logo">Upload</button>
          <button [hidden]="!canDeleteLogo" (click)="deleteLogo()" class="ms-2 btn btn-lg btn-danger" title="Delete logo" type="button">Delete</button>
        </div>
      </div>

      <div *ngIf="mode==='Upload'" class="row">
        <app-upload-file class="col" (cancel)="onCancel()" (uploadComplete)="onUploadComplete()" [uploadType]="'image'" [multipleFiles]="false" actionUrl="organizations/logo_upload" title="Upload Logo">
        </app-upload-file>
      </div>
    </mat-card-content>
  </mat-card>

  <div *ngIf="mode==='Add'">
    <app-edit-segment-user-org *ngIf="isCompanyNmls" (eventEmitted)="onEvent($event)" [op]="'Add'"></app-edit-segment-user-org>
    <div *ngIf="!isCompanyNmls">
      <app-box [type]="'warning'" class="pt-2" [close]="false">
        Your MLO Configuration is missing your company's NMLS ID. Please go to
        <a class="pr-blue-bold" routerLink="/account/mlo-configuration">MLO Configuration</a> and
        add your MLO Configuration with your company's NMLS ID!
      </app-box>
    </div>
  </div>

  <div *ngIf="!userOrg && mode !== 'Add'">
    <mat-card>
      <mat-card-title>User Organization</mat-card-title>
      <mat-card-content>
        <app-box [hidden]="isCompanyNmls" [type]="'warning'" [fadeOut]="false">
          You cannot create your organization because you did not configure your company NMLS ID.
          Please, configure your company NMLS ID in
          <a routerLink="/account/mlo-configuration"><span class="fw-bold"><i class="text-primary fa fa-edit pr-font-large"></i>&nbsp;MLO Configuration</span></a>.
        </app-box>
        <app-view-segment-user-org *ngIf="userOrgInfo" [item]="userOrgInfo"></app-view-segment-user-org>
      </mat-card-content>
    </mat-card>
  </div>

  <app-view-org-users *ngIf="userOrg&&user.isOrgAdmin()"></app-view-org-users>
</div>
