<mat-card class="mt-1 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [header]="'MLO Configuration'" [help]="false" [op]="'edit'"></app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Personal NMLS</div>
      <div class="col-lg-8">{{item.nmls}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">State licenses
        <a (click)="enableAddStateLicense($event)" title="Add state license" class="ms-2"><i class="text-primary fa fa-plus-circle"></i></a>
      </div>
    </div>
    <div *ngFor="let state of states" class="row border-bottom hover-gray">
      <div class="col-lg-4">
        {{state}} {{item.licenses[state]}}
      </div>
      <div class="col-lg-8 text-start">
        <a (click)="delete($event,state)" title="Delete state license" class="ms-2"><i class="text-danger fa fa-trash"></i></a>
      </div>
    </div>

    <app-add-state-license *ngIf="mode==='Add'" (eventEmitted)="onEvent($event)"></app-add-state-license>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Phone number</div>
      <div class="col-lg-8">{{item.phone_number}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Company name</div>
      <div class="col-lg-8">{{item.company_name}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Company address</div>
      <div class="col-lg-8">{{item.getFullAddress()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Company NMLS</div>
      <div class="col-lg-8">{{item.company_nmls}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">FNMA institution ID</div>
      <div class="col-lg-8">{{item.fnma_institution_id}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">FNMA institution name</div>
      <div class="col-lg-8">{{item.fnma_institution_name}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Compensation percent</div>
      <div class="col-lg-8">{{item.getCompensationPercent() | percent:'1.3-3'}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Min compensation amount</div>
      <div class="col-lg-8">{{item.min_compensation_amount | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Max compensation amount</div>
      <div class="col-lg-8">{{item.max_compensation_amount | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">ECOA address</div>
      <div class="col-lg-8">{{item.ecoa_address}}</div>
    </div>
  </mat-card-content>
</mat-card>
