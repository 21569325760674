<mat-card class="mt-2 mb-1">
  <app-view-sticky-note (eventEmitted)="onEvent($event)" [section]="section" [segment]="segment"></app-view-sticky-note>

  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'HMDA'" [help]="true" [op]="'edit'">
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>
          HMDA information is important for loan submission. Please provide responses to all fields.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Ethnicity collected on the basis of visual observation or surname?</div>
      <div class="col-lg-6">{{item.ethnicity_visual | booleanToString}}&nbsp;</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Sex collected on the basis of visual observation or surname?</div>
      <div class="col-lg-6">{{item.gender_visual | booleanToString}}&nbsp;</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Race collected on the basis of visual observation or surname?</div>
      <div class="col-lg-6">{{item.race_visual | booleanToString}}&nbsp;</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">The demographic information was provided through</div>
      <div class="col-lg-6">{{item.getTakenBy()}}&nbsp;</div>
    </div>
  </mat-card-content>
</mat-card>
