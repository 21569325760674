import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {DomSanitizer} from '@angular/platform-browser';
import {DeviceService} from "../../../services/device.service";
import {EmailService} from "../../../services/email.service";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-page-upload-pdf',
  templateUrl: './upload-pdf.component.html',
  styleUrls: ['./upload-pdf.component.scss']
})
export class PageUploadPdfComponent implements OnInit {
  public reloaded: boolean;
  public appUploadFile: boolean;
  public verified: boolean;
  public done: boolean;
  public error: string;
  public notification_id: number;
  public user_full_name = 'N/A';
  public user_email = 'N/A';
  public user_phone = 'N/A';
  public filenames: Array<string> = [];
  public actionUrl: string;
  public isMobile: boolean;
  public emailService: EmailService
  public warned: boolean;
  public warn1: FormControl;
  public warn2: FormControl;
  public warn3: FormControl;
  public form: FormGroup;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private userService: UserService,
              private injector: Injector) {
  }

  ngOnInit() {
    this.emailService = this.injector.get(EmailService);
    this.isMobile = DeviceService.isMobile;
    this.reloaded = false;
    this.warned = false;
    this.done = false;
    this.createFormControls();
    this.createForm();
    this.verifyToken();
  }

  createFormControls(): void {
    this.warn1 = new FormControl();
    this.warn2 = new FormControl();
    this.warn3 = new FormControl();
  }

  createForm(): void {
    this.form = new FormGroup({
      warn1: this.warn1,
      warn2: this.warn2,
      warn3: this.warn3,
    });
  }

  verifyToken(): void {
    this.route.queryParams.subscribe((params) => {
      this.userService.verifyUserToken(params)
        .then((data) => {
          this.notification_id = data['notification_id'];
          this.user_full_name = data['full_name'];
          this.user_email = data['email'];
          this.user_phone = data['cell_phone'];
          this.actionUrl = `home/anon_doc_upload/${this.notification_id}`;
          this.reloaded = true;
          this.verified = true;
          this.appUploadFile = true;
        })
        .catch(() => {
          this.verified = false;
          this.reloaded = true;
        });
    });
  }

  // this event fired when the user click Upload (in the File Upload component)
  onUploadSubmit(event: any): void {
    this.emailService.updateNotification(this.notification_id, event.description)
  }

  onUploadComplete(event): void {
    if (Array.isArray(event.filename)) {
      event.filename.forEach( elt => this.filenames.push(elt));
    } else {
      this.filenames.push(event.filename);
    }
    this.reloadUploadFile();
  }

  onCancel(): void {
  }

  deleteFileName(indexOfFilename: number): void {
    this.filenames.splice(indexOfFilename, 1);
  }

  onError(event): void {
    this.error = null;
    setTimeout(() => {
        this.error = this.injector.get(DomSanitizer).bypassSecurityTrustHtml(event.text) as string;
      }, 0
    );
  }

  reloadUploadFile(): void {
    this.appUploadFile = false;
    setTimeout(() => {
      this.appUploadFile = true;
    }, 0);
  }

  onDoneWarn(): void {
    this.warned = true;
  }

  onDone(): void {
    if (confirm("Are you DONE uploading? Once you've confirmed, you won't be able to upload more documents!") === false) {
      return;
    }

    setTimeout(() => {
        this.done = true;
      }, 0
    );
  }
}
