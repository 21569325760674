<div *ngIf="reloaded" class="row">
  <div *ngIf="!isMobile || isOpen" class="col-lg-2 col">
    <ul class="list-ic vertical">
      <li [ngClass]="{active:currentSeg===seg.tab4Instructions}">
        <span></span>
        <a (click)="setSeg($event,seg.tab4Instructions)" href="" id="tab4">Instructions</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.errorCheck}">
        <span></span>
        <a (click)="setSeg($event,seg.errorCheck)" href="" id="error_check">Error Checking</a>
      </li>
      <li *ngIf="canReadLoanOfficer" [ngClass]="{active:currentSeg===seg.financialRatios}">
        <span></span>
        <a (click)="setSeg($event,seg.financialRatios)" href="" id="financial_ratios">Financial Ratios</a>
      </li>
      <li *ngIf="canUpdateTransaction" [ngClass]="{active:currentSeg===seg.finalizeLoan}">
        <span></span>
        <a (click)="setSeg($event,seg.finalizeLoan)" href="" id="finalize_loan">Finalize Loan</a>
      </li>
      <li *ngIf="canUpdateTransaction" [ngClass]="{active:currentSeg===seg.getCreditReport}">
        <span></span>
        <a (click)="setSeg($event,seg.getCreditReport)" href="" id="create_credit_report">Pull Credit Report</a>
      </li>
      <li *ngIf="canUpdateTransaction" [ngClass]="{active:currentSeg===seg.creditReports}">
        <span></span>
        <a (click)="setSeg($event,seg.creditReports)" href="" id="credit_reports">Credit Reports</a>
      </li>
      <li *ngIf="canReadLoanOfficer" [ngClass]="{active:currentSeg===seg.mcr}">
        <span></span>
        <a (click)="setSeg($event,seg.mcr)" href="" id="mcr">MCR</a>
      </li>
    </ul>
  </div>

  <div *ngIf="!isMobile || !isOpen" class="col-lg-10 col">
    <div [ngSwitch]="currentSeg">
      <app-instructions  *ngSwitchCase="seg.tab4Instructions" [tab]="'tab4'">
      </app-instructions>
      <app-view-errors *ngSwitchCase="seg.errorCheck">
      </app-view-errors>
      <app-view-segment-finalize-loan *ngSwitchCase="seg.finalizeLoan">
      </app-view-segment-finalize-loan>
      <app-view-financial-ratios *ngSwitchCase="seg.financialRatios">
      </app-view-financial-ratios>
      <app-view-credit-reports *ngSwitchCase="seg.creditReports">
      </app-view-credit-reports>
      <app-get-credit-report *ngSwitchCase="seg.getCreditReport">
      </app-get-credit-report>
      <app-view-mcr *ngSwitchCase="seg.mcr">
      </app-view-mcr>
    </div>

    <app-popup-compliance-container style="width:100%;" *ngIf="false"></app-popup-compliance-container>
  </div>

  <div *ngIf="isMobile&&isOpen" class="col">
    <app-box [close]="false" [type]="'success'" class="mt-2">
      Click any menu-item to view/edit it.
    </app-box>
  </div>
</div>
