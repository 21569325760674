<mat-card *ngIf="reloaded">
  <mat-card-content>
    <div *ngIf="hasNoService">
      <h4>No Services</h4>
      <p>
        Your account has no services. If you believe this is an error, please
        <a href="" class="pr-blue-bold" routerLink="/home/contact">Contact Us</a> .
      </p>
    </div>

    <div *ngIf="hasBorrowerService">
      <h4>Borrower Service</h4>
      <p>
        PrudentCO's Online Loan Origination System (POLOS) allows you to efficiently view/complete a loan application and keep track of the loan progress.
        Key features include:
      </p>
      <ul>
        <li>
          <a href="" class="pr-blue-bold" routerLink="/account/view-contacts">Account/Contacts:</a> View your contacts.
        </li>
        <li>
          <a href=""  class="pr-blue-bold" routerLink="/comm/view-messages">Communication/Messages: </a> Send and receive messages.
        </li>
        <li>
          <a href="" class="pr-blue-bold" routerLink="/loans/list">My Loans:</a> Access and view your loan(s) information.
        </li>
      </ul>
    </div>

    <div *ngIf="hasAncillaryService">
      <h4>Ancillary Service</h4>
      <p>
        PrudentCO's Online Loan Origination System (POLOS) allows you to keep track of the loan progress and communicate with the loan stakeholders.
        Key features include:
      </p>
      <ul>
        <li>
          <a href="" class="pr-blue-bold" routerLink="/account/view-contacts">Account/Contacts:</a> View your contacts.
        </li>
        <li>
          <a href="" class="pr-blue-bold" routerLink="/comm/view-messages">Communication/Messages: </a> Send and receive messages.
        </li>
        <li>
          <a href="" class="pr-blue-bold" routerLink="/loans/list">My Loans:</a> Access and view your loan(s) information.
        </li>
      </ul>
    </div>

    <div *ngIf="hasEzUploadsService">
      <h4>ezUploads</h4>
      <p>
        ezUploads enables you to easily and securely collect documents from anybody.
        Quick links:
      </p>
      <ul>
        <li>
          <a href="" class="pr-blue-bold" routerLink="/ez-uploads/secured-uploads">ezUploads</a>.
        </li>
        <li>
          <a href="" class="pr-blue-bold" routerLink="/ez-uploads/ez-uploads-instructions">ezUploads Instructions</a> for sending upload requests and receiving uploaded files.
        </li>
      </ul>
    </div>

    <div *ngIf="hasMCRService">
      <h4>MCR Wizard</h4>
      <div>
        Our MCR Wizard, produces a precise report, and generates an XML file (a specific type of text document) that is ready for submission to NMLS. Highlights:
      </div>
      <p>
      <ul>
        <li>
          Streamlines the creation of the NMLS Mortgage Call Report (MCR)
        </li>
        <li>
          Produces a precise report
        </li>
        <li>
          Supports multiple loan officers in multiple states, and
        </li>
        <li>
          Generates an XML file that is ready for submission to NMLS
        </li>
      </ul>
    </div>

    <div *ngIf="hasPOLOSService">
      <h4>POLOS</h4>

      <p>
        Some facts about POLOS &mdash; PrudentCO Online Loan Origination System.
      </p>

      <div class="fw-bold">Creating Loans</div>

      <p>
        You can create a new loan only if your role is "Loan Officer."
      </p>

      <div class="fw-bold">The Loan Application</div>

      <p>
        POLOS offers comprehensive support for Fannie Mae's Residential Loan Application.
        It includes a robust error-checking feature that identifies mistakes and missing information.
        Additionally, you can generate essential ratios and statistics utilized by lenders to assess loan qualifications and explore various "what-if" scenarios.
      </p>

      <div class="fw-bold">The Repository</div>

      <p>
        The Repository allows you to manage loan records, create loan applications, define the structure of the loan repository, and generate and upload documents.
        If you provide your borrower with directory access, they can upload documents directly to the unsorted folder.
        Please note that borrowers can only view the files they have uploaded.
      </p>

      <div class="fw-bold">Timeline</div>

      <p>
        You begin the loan workflow and update it when as you achieve loan milestones.
        The loan workflow is presented on a timeline, featuring text and instructions for borrowers.
      </p>

      <div class="fw-bold">Messages and Emails</div>

      <p>
        All users in POLOS have the ability to send system messages to their contacts.
        You can also send emails to your contacts whenever loan milestones are achieved.
      </p>

      <div class="fw-bold">Sending and Receiving Text Messages</div>

      <p>
        You can send text messages to any of your contacts as long as their
        <a class="pr-blue-bold" routerLink="/account/settings">Account Settings</a> contain a phone number and carrier details.
      </p>
    </div>
  </mat-card-content>
</mat-card>
