import {Component, Injector, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {GenerateDocumentBaseComponent} from "../generate-document-base.component";
import {Document} from "../../../../models/document";

@Component({
  selector: 'app-ca-doc-base',
  template: '',
})
export class CADocBaseComponent extends GenerateDocumentBaseComponent implements OnInit {
  @Input() section: string;
  public router: Router;
  public readonly CA_ADDN: any = {
    "general": [
      {
        "type_ex": "General",
        "key": "date_issued",
        "note": "",
        "item_desc": "Document Date",
        "value": "",
        "type": "date",
        "stock": "true"
      }
    ]
  }

  public readonly CA_ADVC: any = {
    "general": [
      {
        "type_ex": "General",
        "key": "date_issued",
        "note": "",
        "item_desc": "Document Date",
        "value": "",
        "type": "date",
        "stock": "true"
      },
      {
        "type_ex": "Details",
        "key": "additional_services",
        "note": "",
        "item_desc": "Additional Services Provided by Broker",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Details",
        "key": "fee_payor",
        "note": "",
        "item_desc": "Fee Payer (borrower, broker, or lender)",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      {
        "type_ex": "Details",
        "key": "credit_report_fee",
        "note": "",
        "item_desc": "Fee for Running Credit Report",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Details",
        "key": "advance_fee_max",
        "note": "",
        "item_desc": "Maximum Limit for Advance Fees",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_number",
        "note": "",
        "item_desc": "Loan Identifier",
        "value": "",
        "type": "string",
        "stock": "true"
      }
    ],
  }

  public readonly CA_BROK: any = {
    "general": [
      {
        "type_ex": "General",
        "key": "date_issued",
        "note": "",
        "item_desc": "Document Date",
        "value": "",
        "type": "date",
        "stock": "true"
      },
      {
        "type_ex": "Details",
        "key": "broker_percentage",
        "note": "",
        "item_desc": "Broker compensation as percentage of loan amount",
        "value": "",
        "type": "percent",
        "stock": "true"
      },
      {
        "type_ex": "Details",
        "key": "broker_amount",
        "note": "",
        "item_desc": "Broker compensation",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Details",
        "key": "lender_amount",
        "note": "",
        "item_desc": "Lender compensation",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      {
        "type_ex": "Loan Terms",
        "key": "loan_number",
        "note": "",
        "item_desc": "Loan Identifier",
        "value": "",
        "type": "string",
        "stock": "true"
      }
    ],
  }

  public readonly CA_CRED: any = {
    "general": [
      {
        "type_ex": "General",
        "key": "date_issued",
        "note": "",
        "item_desc": "Document Date",
        "value": "",
        "type": "date",
        "stock": "true"
      }
    ]
  }

  public readonly CA_DUAL: any = {
    "general": [
      {
        "type_ex": "General",
        "key": "date_issued",
        "note": "",
        "item_desc": "Document Date",
        "value": "",
        "type": "date",
        "stock": "true"
      }
    ]
  }

  public readonly CA_FAIRL: any = {
    "general": [
      {
        "type_ex": "General",
        "key": "date_issued",
        "note": "",
        "item_desc": "Document Date",
        "value": "",
        "type": "date",
        "stock": "true"
      }
    ]
  }

  constructor(public injector: Injector) {
    super(injector);
    this.router = this.injector.get(Router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.savedDoc = this.doc.content3[this.formType] || {};
  }

  getFormTitle(): string {
    try {
      const state = this.formType.split('_')[0]
      return Document.getAllFormTypes()[state].find(elt => elt.key === this.formType).value
    } catch {
      return 'Form'
    }
  }
}
