<mat-card *ngIf="reloaded">
  <mat-card-title>
    ezUploads Instructions
  </mat-card-title>

  <mat-card-content *ngIf="hasEzUploadsService">
    <h5>
      To send an upload file request:
    </h5>

    <ul>
      <li>Go to
        <a href="" routerLink="/ez-uploads/secured-uploads">ezUploads/Secured Uploads</a>.
      </li>
      <li>Enter the uploader's contact details and the files you need.</li>
      <li>Then, click on Send Request.</li>
    </ul>

    <h5>
      To receive uploaded files:
    </h5>

    <ul>
      <li>Go to
        <a href="" routerLink="/ez-uploads/secured-uploads">ezUploads/Secured Uploads</a>.
      </li>
      <li>For each file that has been uploaded, confirm that it matches your request.
        The
        <b>"Requester"</b> and
        <b>"Uploader"</b> values contain the descriptions provided by you and the uploader, respectively.
      </li>
      <li>
        Click on the download icon to download the file. You can view image files by clicking on the image icon.
      </li>
      <li>Once you have completed the download, make sure to delete the file.</li>
    </ul>
  </mat-card-content>
</mat-card>
