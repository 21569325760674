import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {CompBaseComponent} from '../../comp-base.component';
import {Trace} from '../../../models/trace';
import moment from 'moment';
import {globals} from '../../../shared/globals/globals';
import {FormUtil} from '../../form-util';
import {ModelBase} from '../../../models/model-base';

@Component({
  selector: 'app-edit-segment-task',
  templateUrl: './edit-segment-task.component.html',
})
export class EditSegmentTaskComponent extends CompBaseComponent implements OnInit {
  @Input() public currentState: string;
  @Input() public trace: Trace;
  @Input() public title: string;
  public timeFrames = ModelBase.timeFrames;
  public minDate = globals.date.minTranStartDate;
  public maxDate = globals.date.maxTranEndDate;
  public isEndedAt: boolean;

  // form and form controls
  form: FormGroup;
  data_in: FormControl;
  data: FormControl;
  data_out: FormControl;
  started_at: FormControl;
  started_at_time: FormControl;
  ended_at: FormControl;
  ended_at_time: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.trace);
    this.createForm();
  }

  hide(event: any): void {
    event.preventDefault();
    this.traceService.hideTrace(this.trace.id)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Task successfully hidden');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to hide task', data);
        this.cancel();
      });
  }

  show(event: any): void {
    event.preventDefault();
    this.traceService.showTrace(this.trace.id)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Task successfully shown');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to show task', data);
        this.cancel();
      });
  }

  createFormControls(trace) {
    let timeString: string;
    this.data_in = new FormControl(trace.data_in);
    this.data = new FormControl(trace.data);
    this.data_out = new FormControl(trace.data_out);
    this.started_at = new FormControl(trace.started_at, AppValidators.present);
    if (trace.started_at !== null) {
      timeString = moment(trace.started_at).format('hh:mm A').replace(/^0+/, '');
      this.started_at_time = new FormControl(timeString, Validators.compose([Validators.pattern(globals.pattern.time), AppValidators.present]));
    } else {
      this.started_at_time = new FormControl(null, Validators.pattern(globals.pattern.time));
    }
    this.isEndedAt = trace.ended_at && typeof (trace.ended_at) === 'object';
    this.ended_at = new FormControl(trace.ended_at);
    if (trace.ended_at !== null) {
      timeString = moment(trace.ended_at).format('hh:mm A').replace(/^0+/, '');
      this.ended_at_time = new FormControl(timeString, Validators.pattern(globals.pattern.time));
    } else {
      this.ended_at_time = new FormControl(null, Validators.pattern(globals.pattern.time));
    }
  }

  createForm() {
    this.form = new FormGroup({
      data_in: this.data_in,
      data: this.data,
      data_out: this.data_out,
      started_at: this.started_at,
      started_at_time: this.started_at_time,
      ended_at: this.ended_at,
      ended_at_time: this.ended_at_time,
    });
  }

  update(): void {
    if (!this.form.valid) {
      return;
    }

    if (!Trace.isTimeValid(this.started_at_time.value)) {
      this.started_at_time.setErrors({'pattern': true});
      return;
    }

    const payload: any = {
      data_in: this.data_in.value,
      data: this.data.value,
      data_out: this.data_out.value,
      started_at: null,
      ended_at: null,
    };

    if (this.started_at.value) {
      if (typeof (this.started_at.value) === 'object') {
        if (this.started_at_time.value.trim().length === 0) {
          payload['started_at'] = this.getJSTime(this.started_at.value, '12:00 PM');
        } else {
          payload['started_at'] = this.getJSTime(this.started_at.value, this.started_at_time.value);
        }
      }
    }

    if (this.ended_at.value) {
      if (typeof (this.ended_at.value) === 'object') {
        if (this.ended_at_time.value.trim().length === 0) {
          payload['ended_at'] = this.getJSTime(this.ended_at.value, '12:00 PM');
        } else {
          payload['ended_at'] = this.getJSTime(this.ended_at.value, this.ended_at_time.value);
        }
      }
    }
    this.traceService.updateTrace(this.trace.id, payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Task successfully updated');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update task', data);
        this.cancel();
      });
  }

  getJSTime(date: Date, time: string) {
    if (!date) {
      return null;
    }
    const timePortion = Trace.getTimeString(time);
    const datePortion = moment(date).format('YYYY-MM-DD');
    return moment(`${datePortion} ${timePortion}`).toDate();
  }
}
